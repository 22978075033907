import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { SinglePriceCircle } from '@app/feature/tariff/component/price-circle/single-price-circle';
import classNames from 'classnames';

import css from './price-circle.module.scss';

interface DoublePriceCircleProps {
  pricePerKwh: number;
  pricePerMin: number;
  className?: string;
  onClick: () => void;
}

export const DoublePriceCircle = (
  props: DoublePriceCircleProps
): JSX.Element => {
  const { pricePerKwh, pricePerMin, className, onClick } = props;

  return (
    <div className={classNames(className, css.doubleContainer)}>
      <SinglePriceCircle
        price={pricePerKwh}
        unit={ChargePointPriceUnitEnum.PER_KWH}
        className={css.circle1}
        onClick={onClick}
      />
      <SinglePriceCircle
        price={pricePerMin}
        unit={ChargePointPriceUnitEnum.PER_MIN}
        className={css.circle2}
        onClick={onClick}
      />
    </div>
  );
};
