import { useMemo } from 'react';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ErrorCodeEnum } from '@app/config/error-config';
import { chargePointService } from './charge-point.service';
import { ChargePointModel } from '../model/charge-point.model';
import { ApiError } from '../error/api-error';
import { TariffModel } from '../model/tariff.model';
import { useUser } from '../context-providers/user-context/use-user';
import { TariffOverloadingConditionEnum } from '../model/enum/tariff-overloading-condition.enum';
import { mapOcpiTariffModelToTariffModel } from '../helper/ocpi-utils';
import { TariffResponseModel } from '../model/ocpi.model';

type ChargePointServiceHookReturn = Omit<
  UseQueryResult<ChargePointModel | null>,
  'data'
> & {
  isBeingInstalled: boolean;
  chargePoint: ChargePointModel | null;
  tariff: TariffModel | null;
};

export const useChargePointService = (
  chargePointId: string | null
): ChargePointServiceHookReturn => {
  const { user } = useUser();

  const result = useQuery({
    queryKey: ['chargePoint', chargePointId],

    queryFn: async ({ queryKey }) =>
      chargePointService.getChargePoint(queryKey[1]),

    enabled: !!chargePointId,
    staleTime: 5 * 60 * 1000,
  });

  const isBeingInstalled = useMemo(
    () =>
      (result.error as ApiError)?.code === ErrorCodeEnum.OCPI_EVSE_NOT_FOUND ||
      (result.error as ApiError)?.code ===
        ErrorCodeEnum.OCPI_LOCATION_NOT_FOUND,
    [result.error]
  );

  const tariffResponse: TariffResponseModel | null = useMemo(() => {
    const tariffs = result.data?.pricing?.tariffs;
    if (!tariffs || tariffs.length === 0) {
      return null;
    }

    const sortedTariffs = [...tariffs].sort(
      (tariffLeft, tariffRight) =>
        (tariffRight.details?.weight ?? -1) - (tariffLeft.details?.weight ?? -1)
    );

    for (const tariffItem of sortedTariffs) {
      if (
        tariffItem.details?.type === TariffOverloadingConditionEnum.OVERWRITE
      ) {
        return tariffItem;
      }

      if (
        tariffItem.details?.type ===
          TariffOverloadingConditionEnum.EMSP_SUBSCRIPTION &&
        user?.emspSubscriptionIds &&
        tariffItem.details?.parameters?.emspSubscriptionId &&
        user.emspSubscriptionIds.includes(
          tariffItem.details?.parameters?.emspSubscriptionId
        )
      ) {
        return tariffItem;
      }
    }

    return sortedTariffs.at(0) ?? null;
  }, [result.data?.pricing?.tariffs, user?.emspSubscriptionIds]);

  return {
    ...result,
    chargePoint: result.data ?? null,
    isBeingInstalled,
    tariff: tariffResponse
      ? mapOcpiTariffModelToTariffModel(tariffResponse)
      : null,
  };
};
