import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Dialog } from '@app/shared/dialog/dialog';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { PseudoTag } from '@app/shared/pseudo-tag/pseudo-tag';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

import css from './first-login.module.scss';

interface FirstLoginViewProps {
  authenticationMethod: AuthenticationMethodEnum | null;
  onContinue: () => void;
}

export const FirstLoginView = (props: FirstLoginViewProps): JSX.Element => {
  const { authenticationMethod, onContinue } = props;
  const { t } = useTranslation();

  return (
    <PageLayout colored>
      <Dialog open onClose={onContinue}>
        <DialogLayout.Body>
          <Icon name="paper-clip" className={css.icon} />
          <H1>{t('first-login.title')}</H1>

          <PseudoTag />

          <Text>
            {/* The space after email-info is crucial. */}
            {authenticationMethod === AuthenticationMethodEnum.EMAIL
              ? t('first-login.email-text')
              : t('first-login.webauthn-text')}
          </Text>

          <Text className={css.keepIt}>{t('first-login.keep-it')}</Text>
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button
            label={t('first-login.button-continue')}
            onClick={onContinue}
          />
        </DialogLayout.Footer>
      </Dialog>
    </PageLayout>
  );
};
