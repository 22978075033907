import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import Station from '@app/asset/station.svg';
import { useChargePointConnectorType } from '@app/shared/charge-point/connector-type/use-charge-point-connector-type';
import { useChargePointPowerType } from '@app/shared/charge-point/power-type/use-charge-point-power-type';
import { ConnectorTypeIcon } from '@app/shared/charge-point/connector-type/connector-type.icon';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { Dialog } from '@app/shared/dialog/dialog';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { ChargePointSpecificationModel } from '@app/core/model/charge-point.model';
import { PowerTypeIcon } from '@app/shared/charge-point/power-type/power-type.icon';

import css from './charge-point-specification.module.scss';

interface ChargePointSpecificationProps
  extends Partial<ChargePointSpecificationModel> {
  className?: string;
}

export const ChargePointSpecification = (
  props: ChargePointSpecificationProps
): JSX.Element => {
  const { className, connectorType, power, powerType } = props;

  const connectorTypeText = useChargePointConnectorType(connectorType);
  const powerTypeText = useChargePointPowerType(powerType);

  const { t } = useTranslation();

  const { openDialog, closeDialog, isDialogOpen } = useDialog();

  return (
    <>
      <button onClick={openDialog}>
        <div className={classNames(css.container, className)}>
          <Station className={css.station} />
          <ConnectorTypeIcon
            connectorType={connectorType}
            className={css.connector}
          />
          <PowerTypeIcon powerType={powerType} className={css.power} />
          <div className={css.details}>
            <div className={css.detailsItem}>
              <Text color="secondary">
                {power
                  ? t('charge-point.charge-point-specification.power', {
                      power,
                    })
                  : '-'}
              </Text>
              <div className={css.bar} />
            </div>

            <div className={css.detailsItem}>
              <Text color="secondary">{connectorTypeText}</Text>
              <div className={css.bar} />
            </div>

            <div className={css.detailsItem}>
              <Text color="secondary">{powerTypeText}</Text>
              <div className={css.bar} />
            </div>
          </div>
        </div>
      </button>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogLayout>
          <DialogLayout.Body className={css.dialogBody}>
            <Text>
              {t(
                'charge-point.charge-point-specification.dialog.that-charge-point'
              )}
              <ul>
                <li // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'charge-point.charge-point-specification.dialog.power',
                      {
                        power,
                      }
                    ),
                  }}
                />
                <li // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'charge-point.charge-point-specification.dialog.connector',
                      {
                        connector: connectorTypeText,
                      }
                    ),
                  }}
                />
                <li // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'charge-point.charge-point-specification.dialog.power-type',
                      {
                        powerType: powerTypeText,
                      }
                    ),
                  }}
                />
              </ul>
            </Text>
          </DialogLayout.Body>
        </DialogLayout>
      </Dialog>
    </>
  );
};
