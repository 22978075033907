import { useTranslation } from 'react-i18next';

import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { IconItemMenu } from '@app/shared/item-menu/icon-item-menu';

import css from './invoice-list-item.module.scss';

interface InvoiceListItemProps {
  startedAt: Date;
  proforma?: boolean;
  totalCost?: number;
  onClick?: () => void;
}

export const InvoiceListItem = ({
  startedAt,
  proforma = false,
  totalCost = 0,
  onClick = () => {},
}: InvoiceListItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <IconItemMenu
      onClick={onClick}
      className={css.itemMenu}
      icon="calendar"
      iconColor="inherit"
      label={t(
        proforma
          ? 'invoicing.dashboard.item.label-proforma'
          : 'invoicing.dashboard.item.label',
        {
          startedAt,
        }
      )}
      right={
        <div className={css.right}>
          {proforma ? (
            <Icon name="credit-card-refresh" className={css.proformaIcon} />
          ) : (
            <Icon name="credit-card-check" />
          )}

          {totalCost > 0 && (
            <Text className={css.totalCost}>
              {t('invoicing.dashboard.item.total-cost', {
                totalCost,
              })}
            </Text>
          )}

          <Icon name="chevron-right" color="primary" />
        </div>
      }
    />
  );
};
