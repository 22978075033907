import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import {
  InfiniteScroll,
  InfiniteScrollProps,
} from '@app/shared/infinite-scroll/infinite-scroll';
import { InvoiceModel } from '@app/core/model/invoice.model';
import PlaceHolderInvoice from '@app/asset/placeholder-invoice.svg';
import { Divider } from '@app/shared/divider/divider';
import { Link } from '@app/shared/typography/link/link';
import { SCAN_PATH } from '@app/config/app-paths.const';
import { Icon } from '@app/shared/icon/icon';

import { InvoiceListItem } from './component/invoice-list-item/invoice-list-item';
import css from './invoice-dashboard.module.scss';
import { InvoiceSectionTitle } from './component/invoice-section-title/invoice-section-title';

interface InvoiceDashboardProps extends Omit<InfiniteScrollProps, 'children'> {
  pending?: boolean;
  proformaList?: InvoiceModel[];
  invoiceList?: InvoiceModel[];
  onSelect: (invoice: InvoiceModel) => void;
}

export const InvoiceDashboardView = (
  props: InvoiceDashboardProps
): JSX.Element => {
  const {
    pending = false,
    proformaList = [],
    invoiceList = [],
    onSelect,
    ...infinitieScrollProps
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const numberOfItems = useMemo(
    () => proformaList.length + invoiceList.length,
    [proformaList.length, invoiceList.length]
  );

  return (
    <PageLayout>
      <PageLayout.Header>
        <H1>{t('invoicing.dashboard.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <div className={css.section}>
          <InvoiceSectionTitle numberOfItems={proformaList.length}>
            {t('invoicing.dashboard.proforma-section')}
          </InvoiceSectionTitle>

          {proformaList.map((order) => (
            <InvoiceListItem
              proforma
              key={order.invoiceId}
              startedAt={order.startedAt ?? order.createdAt}
              totalCost={order.cost.totalTTC}
              onClick={() => onSelect(order)}
            />
          ))}
        </div>

        <Divider />

        <div className={css.section}>
          <InvoiceSectionTitle numberOfItems={invoiceList.length}>
            {t('invoicing.dashboard.history-section')}
          </InvoiceSectionTitle>

          <InfiniteScroll {...infinitieScrollProps}>
            {invoiceList.map((invoice) => (
              <InvoiceListItem
                key={invoice.invoiceId}
                startedAt={invoice.startedAt}
                totalCost={invoice.cost.totalTTC}
                onClick={() => onSelect(invoice)}
              />
            ))}
          </InfiniteScroll>
        </div>

        {numberOfItems === 0 && (
          <>
            <div className={classNames(css.section, css.placeholder)}>
              <PlaceHolderInvoice />
            </div>

            {!pending && (
              <div className={classNames(css.section, css.sectionNoInvoice)}>
                <Text color="secondary">
                  {t('invoicing.dashboard.empty.start-charging')}
                </Text>
                <Link
                  onClick={() => navigate(SCAN_PATH)}
                  endIcon={<Icon name="arrow-right" />}>
                  {t('invoicing.dashboard.empty.scan-link')}
                </Link>
              </div>
            )}
          </>
        )}
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
