import { useTranslation } from 'react-i18next';

import { Counter } from '@app/shared/counter/counter';
import { H3 } from '@app/shared/typography/heading/heading';
import { ChargePointSpecificationModel } from '@app/core/model/charge-point.model';
import { useChargePointConnectorType } from '@app/shared/charge-point/connector-type/use-charge-point-connector-type';
import { useChargePointPowerType } from '@app/shared/charge-point/power-type/use-charge-point-power-type';
import { ConnectorTypeIcon } from '@app/shared/charge-point/connector-type/connector-type.icon';
import { ChargePointName } from '@app/shared/charge-point/charge-point-name/charge-point-name';
import { PowerTypeIcon } from '@app/shared/charge-point/power-type/power-type.icon';

import css from './charge-point-specification.module.scss';

export interface ChargePointSpecificationProps
  extends ChargePointSpecificationModel {
  chargePointName?: string;
}

export function ChargePointSpecification(
  props: ChargePointSpecificationProps
): JSX.Element {
  const { t } = useTranslation();
  const { chargePointName, power, connectorType, powerType } = props;

  const standardText = useChargePointConnectorType(connectorType);
  const powerTypeText = useChargePointPowerType(powerType);

  return (
    <div>
      <H3>{t('quote.charge-point-specification.title')}</H3>

      <ChargePointName
        className={css.chargePointName}
        name={chargePointName}
        connectorType={connectorType}
      />

      <div className={css.counterContainer}>
        <Counter
          icon="clock-fast-forward"
          value={t('quote.charge-point-specification.power', { power })}
          outline
          className={css.counter}
        />

        <Counter value={standardText} outline className={css.counter}>
          <ConnectorTypeIcon connectorType={connectorType} />
        </Counter>

        <Counter value={powerTypeText} outline className={css.counter}>
          <PowerTypeIcon powerType={powerType} />
        </Counter>
      </div>
    </div>
  );
}
