import { Icon, IconName, IconProps } from '@app/shared/icon/icon';
import { ItemMenu, ItemMenuProps } from '@app/shared/item-menu/item-menu';

import css from './item-menu.module.scss';

interface IconItemMenuProps extends Omit<ItemMenuProps, 'left'> {
  icon: IconName;
  iconColor?: IconProps['color'];
}

export const IconItemMenu = ({
  icon,
  iconColor = 'primary',
  ...itemMenuProps
}: IconItemMenuProps): JSX.Element => (
  <ItemMenu
    left={<Icon name={icon} color={iconColor} className={css.icon} />}
    {...itemMenuProps}
  />
);
