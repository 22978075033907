import { useTranslation } from 'react-i18next';

import { Card } from '@app/shared/card/card';
import { CircleContainer } from '@app/shared/circle-container/circle-container';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { ChargeEstimationForOneHourModel } from '@app/core/model/charge-point.model';

import css from './charge-estimation-card.module.scss';

export function ChargeEstimationCard(
  props: ChargeEstimationForOneHourModel
): JSX.Element {
  const { power, estimatedPriceTTC, estimatedRecoveredDistance } = props;
  const { t } = useTranslation();

  return (
    <Card background="paper" className={css.card}>
      <Text className={css.title} fontSize="large">
        {t('charge-point.charge-estimation-card.title')}
      </Text>
      <CircleContainer className={css.avatar}>
        <>
          <Icon name="light" />
          <Text bold>
            {t('charge-point.charge-estimation-card.converter-power', {
              power,
            })}
          </Text>
        </>
      </CircleContainer>
      <div className={css.estimations}>
        <Text className={css.title} fontSize="large">
          {t('charge-point.charge-estimation-card.sub-title')}
        </Text>
        <div className={css.item}>
          <Icon name="distance" color="primary" />
          <Text bold>
            {t('charge-point.charge-estimation-card.distance-recovered', {
              count: estimatedRecoveredDistance,
            })}
          </Text>
        </div>
        <div className={css.item}>
          <Icon name="euro" color="primary" />
          <Text bold>
            {t('charge-point.charge-estimation-card.price-estimated', {
              count: estimatedPriceTTC,
            })}
          </Text>
        </div>
      </div>
    </Card>
  );
}
