import { TariffRestrictionModel } from '@app/core/model/tariff.model';
import { Text } from '@app/shared/typography/text/text';
import { Icon } from '@app/shared/icon/icon';

import { useRestrictionText } from './use-restriction-text';
import css from './restrictions.module.scss';

export function TimeRestrictions(
  props: TariffRestrictionModel
): JSX.Element | null {
  const { startTime, endTime } = props;
  const getRestrictionText = useRestrictionText();

  if (!startTime && !endTime) {
    return null;
  }

  return (
    <Text className={css.time} bold>
      <Icon name="clock" />
      {getRestrictionText({ startTime, endTime })}
    </Text>
  );
}

export function EnergyRestrictions(
  props: TariffRestrictionModel
): JSX.Element | null {
  const { maxKwh, minKwh } = props;
  const getRestrictionText = useRestrictionText();

  if (!minKwh && !maxKwh) {
    return null;
  }

  return (
    <Text fontSize="large">
      <Icon name="light" />
      {getRestrictionText({ minKwh, maxKwh })}
    </Text>
  );
}
