import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { CHARGE_POINT_PATH } from '@app/config/app-paths.const';
import { LOCALE_KEYS } from '@app/core/locale/locale';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { Logger } from '@app/core/logger/logger';
import { ChargePointModel } from '@app/core/model/charge-point.model';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import i18n from '@app/core/locale/i18n';
import { ConnectorTypeIcon } from '@app/shared/charge-point/connector-type/connector-type.icon';
import { useChargePointPowerType } from '@app/shared/charge-point/power-type/use-charge-point-power-type';
import { Icon } from '@app/shared/icon/icon';
import { H1 } from '@app/shared/typography/heading/heading';

function fetchDemoChargePoints(): Promise<ChargePointModel[]> {
  const MOCK_API = process.env.DELMONICOS_MOCK_API;

  if (!MOCK_API) {
    throw new Error(
      `process.env.DELMONICOS_MOCK_API is not defined. Obtained: ${process.env.DELMONICOS_MOCK_API}`
    );
  }

  const url = new URL('charge-point', MOCK_API);

  return fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response: Response) => {
    return response.json() as unknown as ChargePointModel[];
  });
}

const fr = {
  title: 'Bornes disponibles pour la démonstration',
  price: '{{pricePerKWh, currency(EUR)}}/kWh',
};

const en = {
  title: 'Charge points available for demonstration',
  price: '{{pricePerKWh, currency(EUR)}}/kWh',
};

const LIST_CHARGE_POINT_NAMESPACE = 'mock-list-charge-point';

i18n.addResourceBundle(LOCALE_KEYS.fr, LIST_CHARGE_POINT_NAMESPACE, fr, true);
i18n.addResourceBundle(LOCALE_KEYS.en, LIST_CHARGE_POINT_NAMESPACE, en, true);

const ChargePointItem = (props: ChargePointModel): JSX.Element => {
  const {
    chargePointId: delmoChargePointId,
    name,
    pricing,
    specifications,
  } = props;
  const { connectorType, powerType } = specifications;

  const powerTypeText = useChargePointPowerType(powerType);

  const { t } = useTranslation(LIST_CHARGE_POINT_NAMESPACE);
  const navigate = useNavigate();

  const handleNavigateToChargePoint = () => {
    navigate(generatePath(CHARGE_POINT_PATH, { delmoChargePointId }));
  };

  const caption = useMemo(
    () =>
      `${t('price', {
        pricePerKWh: pricing.perKwhTTC,
      })} - ${powerTypeText}`,
    [powerTypeText, t, pricing.perKwhTTC]
  );

  return (
    <ItemMenu
      caption={caption}
      left={<ConnectorTypeIcon color="primary" connectorType={connectorType} />}
      label={name}
      right={<Icon color="primary" name="chevron-right" />}
      onClick={handleNavigateToChargePoint}
    />
  );
};

export const ListChargePoint = (): JSX.Element => {
  const [chargePointList, setChargePointList] = useState<ChargePointModel[]>(
    []
  );
  const { t } = useTranslation(LIST_CHARGE_POINT_NAMESPACE);

  const handleFetchChargePoint = useCallback(async () => {
    try {
      const chargePoints = await fetchDemoChargePoints();
      setChargePointList(chargePoints);
    } catch (error) {
      Logger.error(error);
    }
  }, []);

  useEffect(() => {
    handleFetchChargePoint();
  }, [handleFetchChargePoint]);

  if (!chargePointList.length) {
    return <div />;
  }

  return (
    <>
      <H1>{t('title')}</H1>
      <ListItemMenu>
        {chargePointList.map((item) => (
          <ChargePointItem key={item.chargePointId} {...item} />
        ))}
      </ListItemMenu>
    </>
  );
};
