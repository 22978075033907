import { useTranslation } from 'react-i18next';

import { Link } from '@app/shared/typography/link/link';

interface ClearStorageLinkProps {
  children?: string;
  onClick: () => void;
}

export const ClearStorageLink = (props: ClearStorageLinkProps): JSX.Element => {
  const { t } = useTranslation();

  const { children = t('legal-text.clear-storage.link'), onClick } = props;

  return (
    <Link color="p1" onClick={onClick}>
      {children}
    </Link>
  );
};
