import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_CODE_LENGTH } from '@app/config/form-validation-rules.const';
import { Button } from '@app/shared/button/button';
import { CodeInput } from '@app/shared/code-input/code-input';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { BackLink } from '@app/shared/back-link/back-link';
import { Link } from '@app/shared/typography/link/link';
import { TranslationPaths } from '@app/core/helper/use-typed-translation';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { EmailOtpProcessVerificationRequestModel } from '@app/core/model/email-otp-process.model';

import { VerifyEmailOtpFormValidationRules } from '../email-otp-form-validation-rules.const';
import { buildVerifyEmailOTPTranslations } from './build-verify-email-otp-translation';

import css from './verify-email-otp-process.module.scss';

const DEFAULT_TRANSLATION_KEY: TranslationPaths = 'email-otp';
const DEFAULT_RESEND_LINK_LOCK_TIME_IN_SECONDS = 10;

interface VerifyEmailOTPViewProcessProps {
  remainingLockTimeInMin?: number;
  isCodeInvalid: boolean;
  pending: boolean;
  onSubmit: (
    challenge: EmailOtpProcessVerificationRequestModel
  ) => Promise<void>;
  onBack: () => void;
  onRequestAgainOTP: () => Promise<void>;
  translationKey?: TranslationPaths;
}

export const VerifyEmailOTPProcessView = ({
  remainingLockTimeInMin = 0,
  isCodeInvalid,
  pending,
  onSubmit,
  onBack,
  onRequestAgainOTP,
  translationKey,
}: VerifyEmailOTPViewProcessProps): JSX.Element => {
  const { t } = useTranslation();
  const [showResendLink, setShowResendLink] = useState(false);
  const [resendLinkInSeconds, setResendLinkInSeconds] = useState(
    DEFAULT_RESEND_LINK_LOCK_TIME_IN_SECONDS
  );

  const translations = useMemo(
    () =>
      buildVerifyEmailOTPTranslations(
        translationKey ?? DEFAULT_TRANSLATION_KEY
      ),
    [translationKey]
  );

  const defaultTranslations = useMemo(
    () => buildVerifyEmailOTPTranslations(DEFAULT_TRANSLATION_KEY),
    []
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<EmailOtpProcessVerificationRequestModel>({
    mode: 'onChange',
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!showResendLink) {
        // allows to re-execute every time we hide the button

        setShowResendLink(true); // does not display it too soon.
      }
    }, DEFAULT_RESEND_LINK_LOCK_TIME_IN_SECONDS * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showResendLink]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!showResendLink) {
        // allows to re-execute every time we hide the button

        setResendLinkInSeconds((current) => Math.max(current - 1, 0));
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [showResendLink]);

  const handleResend = useCallback(() => {
    reset();
    setShowResendLink(false);
    setResendLinkInSeconds(DEFAULT_RESEND_LINK_LOCK_TIME_IN_SECONDS);
    onRequestAgainOTP();
  }, [onRequestAgainOTP, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.formContainer}>
      <DrawerLayout>
        <BackLink onClick={onBack} />

        <DrawerLayout.Header>
          <H1>{t([translations.title, defaultTranslations.title])}</H1>
          <Text>{t([translations.caption, defaultTranslations.caption])}</Text>
        </DrawerLayout.Header>

        <DrawerLayout.Body className={css.body}>
          {/* Use Controller component from react-hook-form to bind CodeInput.onChange props to form. CodeInput.onChange signature pass string value instead of classic event */}
          <Controller
            name="challenge"
            control={control}
            rules={VerifyEmailOtpFormValidationRules.challenge}
            render={({ field }) => (
              <CodeInput
                {...field}
                className={css.codeInput}
                autoFocus
                length={EMAIL_CODE_LENGTH}
                errorMessage={
                  isCodeInvalid
                    ? t([
                        translations.invalidCode,
                        defaultTranslations.invalidCode,
                      ])
                    : ''
                }
                onChange={(value) => field.onChange(value)}
              />
            )}
          />

          <Text>
            {t([
              translations.codeNotReceived,
              defaultTranslations.codeNotReceived,
            ])}
          </Text>

          <div className={css.resend}>
            {resendLinkInSeconds > 0 && !isSubmitting && (
              <Text>
                {t(
                  [
                    translations.warningRequestingAnotherCode,
                    defaultTranslations.warningRequestingAnotherCode,
                  ],
                  {
                    seconds: resendLinkInSeconds,
                  }
                )}
              </Text>
            )}

            <Link
              disabled={resendLinkInSeconds > 0 || isSubmitting}
              color="p1"
              onClick={handleResend}>
              {t([translations.resendCode, defaultTranslations.resendCode])}
            </Link>
          </div>
        </DrawerLayout.Body>

        <DrawerLayout.Footer>
          <Button
            loading={pending}
            type="submit"
            visuallyDisabled={!isValid || remainingLockTimeInMin > 0}
            label={t(
              [translations.submitButton, defaultTranslations.submitButton],
              { count: remainingLockTimeInMin }
            )}
          />
        </DrawerLayout.Footer>
      </DrawerLayout>
    </form>
  );
};
