import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Overlay } from '@app/shared/overlay/overlay';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Spinner } from '@app/shared/spinner/spinner';
import { useDialog } from '@app/shared/dialog/use-dialog';
import {
  GUEST_LEGAL_TEXT_PATH,
  TARIFICATION_PATH,
} from '@app/config/app-paths.const';
import { ChargePointModel } from '@app/core/model/charge-point.model';
import { Link } from '@app/shared/typography/link/link';
import { PowerTypeEnum } from '@app/core/model/enum/power-type.enum';
import { TariffModel } from '@app/core/model/tariff.model';

import { InChargeFooter } from './component/in-charge-footer/in-charge-footer';
import { ChargePointFooter } from './component/charge-point-footer/charge-point-footer';
import { ChargePointDetail } from './component/charge-point-detail/charge-point-detail';
import { ChargeEstimationSection } from './component/charge-estimation-section/charge-estimation-section';
import { ChargePointTarificationDialog } from './component/charge-point-tarification-dialog/charge-point-tarification-dialog';
import { ChargePointHeader } from './component/charge-point-header/charge-point-header';

import css from './charge-point.module.scss';
import { ChargePointMoreTariff } from './component/charge-point-more-tariff/charge-point-more-tariff';

interface ChargePointProps {
  pending: boolean;
  fullTariff?: TariffModel | null;
  currentTariff?: TariffModel | null;
  isTariffComplex?: boolean;
  hasHourlyRestrictions?: boolean;
  chargePoint?: ChargePointModel | null;
  isChargeStarted?: boolean;
  onStart?: () => void;
  onGoToCurrentCharge?: () => void;
}

export const ChargePointView = (props: ChargePointProps): JSX.Element => {
  const navigate = useNavigate();
  const { openDialog, isDialogOpen, closeDialog } = useDialog();

  const { t } = useTranslation();

  const {
    pending,
    currentTariff,
    fullTariff,
    isTariffComplex = false,
    hasHourlyRestrictions = false,
    chargePoint,
    isChargeStarted = false,
    onStart = () => {},
    onGoToCurrentCharge = () => {},
  } = props;

  const showEstimation =
    !isTariffComplex &&
    chargePoint?.chargeEstimationForOneHour &&
    chargePoint?.specifications?.powerType !== PowerTypeEnum.DC;

  return (
    <>
      <PageLayout>
        <PageLayout.UserAvatar />

        <PageLayout.Body withTexture>
          <div className={css.main}>
            <ChargePointHeader
              chargePointName={chargePoint?.name}
              connectorType={chargePoint?.specifications?.connectorType}
            />

            <ChargePointDetail
              tariff={currentTariff}
              isTariffComplex={isTariffComplex}
              onClick={openDialog}
              specification={chargePoint?.specifications}
            />

            {hasHourlyRestrictions && fullTariff && (
              <ChargePointMoreTariff {...fullTariff} />
            )}

            {showEstimation && (
              <ChargeEstimationSection
                maxPower={chargePoint.specifications.power}
                estimations={chargePoint.chargeEstimationForOneHour}
              />
            )}

            <ChargePointTarificationDialog
              open={isDialogOpen}
              onClose={closeDialog}
              onClick={() => navigate(TARIFICATION_PATH)}
            />
          </div>

          <Link
            color="tertiary"
            onClick={() => navigate(GUEST_LEGAL_TEXT_PATH)}
            className={css.legalText}>
            {t('home.legal-text')}
          </Link>
        </PageLayout.Body>

        {!pending && (
          <PageLayout.Footer>
            {isChargeStarted ? (
              <InChargeFooter onClick={onGoToCurrentCharge} />
            ) : (
              <ChargePointFooter
                isAvailable={Boolean(chargePoint?.isAvailable)}
                onClick={onStart}
              />
            )}
          </PageLayout.Footer>
        )}
      </PageLayout>

      {pending && (
        <Overlay>
          <Spinner size="md" translationKey="shared.spinner.charge-point" />
        </Overlay>
      )}
    </>
  );
};
