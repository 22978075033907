import { useTranslation } from 'react-i18next';

import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { ChargePointName } from '@app/shared/charge-point/charge-point-name/charge-point-name';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { useOrder } from '@app/core/context-providers/order-context/use-order';

import css from './charging-layout.module.scss';
import { StartChargeButton } from '../start-charge-button/start-charge-button';
import { StopChargeButton } from '../stop-charge-button/stop-charge-button';

interface ChargingLayoutProps {
  children?: JSX.Element;
}

export const ChargingLayout = (props: ChargingLayoutProps): JSX.Element => {
  const { t } = useTranslation();
  const { children } = props;

  const { chargeState } = useCharge();
  const { order } = useOrder();

  return (
    <PageLayout>
      <PageLayout.Header>
        <H1>
          {t(
            chargeState < ChargeStateEnum.CHARGING
              ? 'charging.title.starting'
              : 'charging.title.default'
          )}
        </H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <ChargePointName
          color="secondary"
          className={css.chargePointName}
          name={order?.chargePointName}
        />

        {children}
      </PageLayout.Body>

      <PageLayout.Footer>
        {chargeState < ChargeStateEnum.CHARGING ? (
          <StartChargeButton />
        ) : (
          <StopChargeButton />
        )}
      </PageLayout.Footer>
    </PageLayout>
  );
};
