import { useTranslation } from 'react-i18next';

import QrCodeScannerFrame from '@app/asset/qr-code-scanner-frame.svg';
import { Text } from '@app/shared/typography/text/text';

import { Scanner, ScannerProps } from './scanner';
import css from './qr-code-scanner.module.scss';

export const QrCodeScanner = (props: ScannerProps): JSX.Element => {
  const { onScan, onCameraError } = props;
  const { t } = useTranslation();

  return (
    <div className={css.scannerContainer}>
      <Scanner
        className={css.scanner}
        onScan={onScan}
        onCameraError={onCameraError}
      />
      <QrCodeScannerFrame className={css.scannerFrame} />
      <div className={css.tips}>
        <div className={css.glassContainer}>
          <Text fontSize="large" className={css.tipsText}>
            {t('scan.tips')}
          </Text>
        </div>
      </div>
    </div>
  );
};
