import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@app/shared/button/button';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import ChargePointBeingInstalledSvg from '@app/asset/charge-point-being-installed.svg';
import { SCAN_PATH } from '@app/config/app-paths.const';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { CallSupportLink } from '@app/shared/call-support/call-support-link';
import { configurationService } from '@app/core/configuration/configuration.service';

import css from './charge-point-not-found.module.scss';

interface ChargePointNotFoundViewProps {
  isBeingInstalled: boolean;
}

export const ChargePointNotFoundView = (
  props: ChargePointNotFoundViewProps
): JSX.Element => {
  const { isBeingInstalled } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageLayout>
      <PageLayout.Header>
        <H1>{t('scan.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <div className={css.main}>
          <ChargePointBeingInstalledSvg />
          <Text>
            {t(
              `charge-point.${
                isBeingInstalled ? 'being-installed' : 'not-found'
              }.p1`
            )}
          </Text>
          <Text>
            {t(
              `charge-point.${
                isBeingInstalled ? 'being-installed' : 'not-found'
              }.p2`
            )}
          </Text>
        </div>

        <div className={css.help}>
          <Text color="secondary">{t('charge-point.not-found.read-faq')}</Text>
          <LearnMoreLink questionId="charge-point-not-found">
            {t('charge-point.not-found.question')}
          </LearnMoreLink>
        </div>

        <CallSupportLink>
          {t('charge-point.not-found.contact-support', {
            phoneNumber: configurationService.getSupportPhoneNumber(),
          })}
        </CallSupportLink>
      </PageLayout.Body>

      <PageLayout.Footer>
        <Button
          label={t('charge-point.not-found.scan-button')}
          onClick={() => navigate(SCAN_PATH)}
        />
      </PageLayout.Footer>
    </PageLayout>
  );
};
