import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Card, CardProps } from '@app/shared/card/card';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { InvoiceModel } from '@app/core/model/invoice.model';

import css from './last-invoice-card.module.scss';

interface LastInvoiceCardProps extends CardProps {
  invoice: InvoiceModel | null;
}

export const LastInvoiceCard = ({
  invoice,
  className,
  ...props
}: LastInvoiceCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Card className={classNames(css.card, className)} {...props}>
      {invoice ? (
        <>
          <Text fontSize="large" color="secondary" className={css.title}>
            {t('dashboard.last-invoice-card.title')}
          </Text>
          <div className={css.list}>
            <div className={css.item}>
              <Icon name="calendar" color="primary" />
              <Text bold className={css.value} fontSize="large">
                {t('dashboard.last-invoice-card.created-at', {
                  createdAt: invoice.createdAt,
                })}
              </Text>
            </div>

            <div className={css.item}>
              <Icon name="marker-pin" color="primary" />
              <Text bold className={css.value} fontSize="large">
                {invoice.chargePointName}
              </Text>
            </div>

            <div className={css.item}>
              <Icon name="light" color="primary" />
              <Text bold className={css.value} fontSize="large">
                {t('dashboard.last-invoice-card.energy-delivered', {
                  energy: invoice.energyDelivered,
                })}
              </Text>
            </div>

            <div className={css.item}>
              <Icon name="euro" color="primary" />
              <Text bold className={css.value} fontSize="large">
                {t('dashboard.last-invoice-card.total-price', {
                  totalPrice: invoice.cost.totalTTC,
                })}
              </Text>
            </div>
          </div>
        </>
      ) : (
        <Text className={css.title}>
          {t('dashboard.last-invoice-card.fallback')}
        </Text>
      )}
    </Card>
  );
};
