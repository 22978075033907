import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { HomeController } from '@app/feature/home/home.controller';
import { ChargePointController } from '@app/feature/charge-point/charge-point.controller';
import { DashboardController } from '@app/feature/dashboard/dashboard.controller';

import {
  ACCOUNT_PATH,
  CHARGE_POINT_PATH,
  DASHBOARD_PATH,
  MANAGE_PERSONAL_INFORMATION_PATH,
  LEGAL_TEXT_PATH,
  HELP_PATH,
  HOME_PATH,
  QUOTE_PATH,
  LANGUAGE_PATH,
  USER_PAYMENT_METHOD_PATH,
  MANAGE_AUTHENTICATION_PATH,
  USER_PREFERENCE_PATH,
  INVOICE_SUMMARY_PATH,
  INVOICE_DASHBOARD_PATH,
  SCAN_PATH,
  ACCOUNT_FAQ_PATH,
  TARIFICATION_PATH,
  CHARGE_SUMMARY_PATH,
  CREATE_QUOTE_PATH,
  FIRST_LOGIN_PATH,
  GUEST_FAQ_PATH,
  CHARGING_PATH,
  CHARGE_OVERVIEW_PATH,
  GUEST_LEGAL_TEXT_PATH,
  TINY_CHARGE_POINT_PATH,
  SECURITY_PATH,
  FAQ_PATH,
  USER_SUBSCRIPTION_PATH,
} from '@app/config/app-paths.const';
import { configurationService } from '@app/core/configuration/configuration.service';
import { QuoteController } from '@app/feature/quote/quote.controller';
import { LegalTextController } from '@app/feature/legal-text/legal-text.controller';
import { FaqController } from '@app/feature/faq/faq.controller';
import { ScanController } from '@app/feature/scan/scan.controller';
import { ChargingController } from '@app/feature/charging/charging.controller';
import { ChargeOverviewController } from '@app/feature/charge-overview/charge-overview.controller';
import { InvoiceSummaryController } from '@app/feature/invoicing/invoice-summary/invoice-summary.controller';
import { InvoiceDashboardController } from '@app/feature/invoicing/invoice-dashboard/invoice-dashboard.controller';
import { Logger } from '@app/core/logger/logger';
import { ChargeSummaryController } from '@app/feature/charge-summary/charge-summary.controller';
import { ContextProviders } from '@app/core/context-providers/context-providers';
import { CreateQuoteControllerWithPayload } from '@app/feature/quote/create-quote/create-quote.controller';
import { FirstLoginController } from '@app/feature/first-login/first-login.controller';
import { ListenForChargingEventController } from '@app/feature/charging/listen-for-charging-event.controller';
import { TinyChargePointController } from '@app/feature/charge-point/tiny-charge-point.controller';
import { HelpController } from '@app/feature/account/view/help/help.controller';
import { ManageLanguageController } from '@app/feature/account/view/user-security/view/manage-language/manage-language.controller';
import { UserPaymentMethodController } from '@app/feature/account/view/user-payment-method/user-payment-method.controller';
import { UserPreferenceController } from '@app/feature/account/view/user-preference/user-preference.controller';
import { ManageAuthenticationMethodController } from '@app/feature/account/view/user-security/view/manage-authentication-method/manage-authentication-method.controller';
import { UserSecurityController } from '@app/feature/account/view/user-security/user-security.controller';
import { AccountController } from '@app/feature/account/account.controller';
import { ManagePersonalInformationController } from '@app/feature/account/view/user-security/view/manage-personal-information/manage-personal-information.controller';
import { UserSubscriptionController } from '@app/feature/account/view/user-subscription/user-subscription.controller';

import { AuthenticatedController } from './authenticated.controller';
import { PageNotFoundController } from '../feature/404-page-not-found/page-not-found.controller';
import { ChargeNotStartedGuard } from './guard/charge-not-started.guard';
import { RequireQuoteValidatedGuard } from './guard/require-quote-validated.guard';
import { withUserGuard } from './guard/with-user.guard';
import { ChargeNotTerminatedGuard } from './guard/charge-not-terminated.guard';
import { withOrderGuard } from './guard/with-order.guard';
import { WithChargePointIdGuard } from './guard/with-charge-point-id.guard';
import { ChargeIsNotEndedGuard } from './guard/charge-is-not-ended.guard';
import { ChargeIsEndedGuard } from './guard/charge-is-ended.guard';

const QuoteControllerWithGuards = WithChargePointIdGuard(
  withOrderGuard(QuoteController)
);

const AccountControllerWithUserGuard = withUserGuard(AccountController);
const ManagePersonalInformationControllerWithUserGuard = withUserGuard(
  ManagePersonalInformationController
);
const SecurityControllerWithUserGuard = withUserGuard(UserSecurityController);

const ChargeSummaryControllerWithUserGuard = withUserGuard(
  ChargeSummaryController
);
const UserPreferenceControllerWithUserGuard = withUserGuard(
  UserPreferenceController
);
const ManageAuthenticationMethodControllerWithUserGuard = withUserGuard(
  ManageAuthenticationMethodController
);
const InvoiceSummaryControllerWithUserGuard = withUserGuard(
  InvoiceSummaryController
);

const ChargingControllerWithGuards = WithChargePointIdGuard(ChargingController);

export const Router = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    if (!configurationService.isProduction()) {
      Logger.debug(location);
    }
  }, [location]);

  return (
    <Routes>
      <Route path={HOME_PATH} element={<HomeController />} />
      <Route path={GUEST_FAQ_PATH} element={<FaqController />} />
      <Route path={GUEST_LEGAL_TEXT_PATH} element={<LegalTextController />} />

      <Route element={<ContextProviders />}>
        <Route path={SCAN_PATH} element={<ScanController />} />
        <Route path={CHARGE_POINT_PATH} element={<ChargePointController />} />
        <Route
          path={TINY_CHARGE_POINT_PATH}
          element={<TinyChargePointController />}
        />

        {/* Authenticated area */}
        <Route element={<AuthenticatedController />}>
          <Route element={<ListenForChargingEventController />}>
            <Route path={DASHBOARD_PATH} element={<DashboardController />} />
            <Route path={FAQ_PATH} element={<FaqController hideBackButton />} />
            <Route path={FIRST_LOGIN_PATH} element={<FirstLoginController />} />
            <Route
              path={INVOICE_DASHBOARD_PATH}
              element={<InvoiceDashboardController />}
            />
            <Route
              path={INVOICE_SUMMARY_PATH}
              element={<InvoiceSummaryControllerWithUserGuard />}
            />
            <Route
              path={CREATE_QUOTE_PATH}
              element={
                <ChargeNotStartedGuard>
                  <CreateQuoteControllerWithPayload />
                </ChargeNotStartedGuard>
              }
            />
            <Route
              path={QUOTE_PATH}
              element={
                <ChargeNotStartedGuard>
                  <ChargeNotTerminatedGuard>
                    <QuoteControllerWithGuards />
                  </ChargeNotTerminatedGuard>
                </ChargeNotStartedGuard>
              }
            />
            <Route
              path={CHARGING_PATH}
              element={
                <RequireQuoteValidatedGuard>
                  <ChargeNotTerminatedGuard>
                    <ChargeIsNotEndedGuard>
                      <ChargingControllerWithGuards />
                    </ChargeIsNotEndedGuard>
                  </ChargeNotTerminatedGuard>
                </RequireQuoteValidatedGuard>
              }
            />
            <Route
              path={CHARGE_OVERVIEW_PATH}
              element={
                <RequireQuoteValidatedGuard>
                  <ChargeNotTerminatedGuard>
                    <ChargeIsEndedGuard>
                      <ChargeOverviewController />
                    </ChargeIsEndedGuard>
                  </ChargeNotTerminatedGuard>
                </RequireQuoteValidatedGuard>
              }
            />
            <Route
              path={CHARGE_SUMMARY_PATH}
              element={<ChargeSummaryControllerWithUserGuard />}
            />
            <Route
              path={ACCOUNT_PATH}
              element={<AccountControllerWithUserGuard />}
            />
            <Route
              path={USER_SUBSCRIPTION_PATH}
              element={<UserSubscriptionController />}
            />
            <Route
              path={USER_PAYMENT_METHOD_PATH}
              element={
                <ChargeNotStartedGuard redirectTo={ACCOUNT_PATH}>
                  <UserPaymentMethodController />
                </ChargeNotStartedGuard>
              }
            />
            <Route
              path={MANAGE_AUTHENTICATION_PATH}
              element={
                <ChargeNotStartedGuard redirectTo={SECURITY_PATH}>
                  <ManageAuthenticationMethodControllerWithUserGuard />
                </ChargeNotStartedGuard>
              }
            />
            <Route
              path={SECURITY_PATH}
              element={<SecurityControllerWithUserGuard />}
            />
            <Route
              path={USER_PREFERENCE_PATH}
              element={<UserPreferenceControllerWithUserGuard />}
            />
            <Route
              path={LANGUAGE_PATH}
              element={<ManageLanguageController />}
            />

            <Route path={HELP_PATH} element={<HelpController />} />
            <Route path={ACCOUNT_FAQ_PATH} element={<FaqController />} />
            <Route path={TARIFICATION_PATH} element={<HelpController />} />

            <Route
              path={MANAGE_PERSONAL_INFORMATION_PATH}
              element={
                <ChargeNotStartedGuard redirectTo={SECURITY_PATH}>
                  <ManagePersonalInformationControllerWithUserGuard />
                </ChargeNotStartedGuard>
              }
            />
            <Route path={LEGAL_TEXT_PATH} element={<LegalTextController />} />
          </Route>
        </Route>
      </Route>

      {/* Default route  */}
      <Route path="*" element={<PageNotFoundController />} />
    </Routes>
  );
};
