import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1, H3 } from '@app/shared/typography/heading/heading';
import { Spinner } from '@app/shared/spinner/spinner';
import { Link } from '@app/shared/typography/link/link';
import { CreditCardInformation } from '@app/shared/credit-card-information/credit-card-information';
import { OrderModel } from '@app/core/model/order.model';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Text } from '@app/shared/typography/text/text';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { ChargePointSpecificationModel } from '@app/core/model/charge-point.model';
import { Icon } from '@app/shared/icon/icon';
import { TariffModel } from '@app/core/model/tariff.model';

import { ChargePointSpecification } from './component/charge-point-specification/charge-point-specification';
import { TariffSection } from './component/tariff-section/tariff-section';
import css from './quote.module.scss';

export interface QuoteViewProps {
  tariff?: TariffModel;
  isTariffComplex?: boolean;
  chargePointName?: string;
  isChargePointAvailable?: boolean;
  isPending: boolean;
  isPrecaptureAccepted: boolean;
  order: OrderModel;
  chargePointSpecification?: ChargePointSpecificationModel;
  onValidate: () => void;
  onOpenPaymentSelection: () => void;
  onBack: () => void;
}

export function QuoteView({
  tariff,
  isTariffComplex = false,
  chargePointName,
  isChargePointAvailable = false,
  isPending,
  order,
  chargePointSpecification,
  isPrecaptureAccepted,
  onValidate,
  onOpenPaymentSelection,
  onBack,
}: QuoteViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />

      <PageLayout.Header>
        <H1>{t('quote.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        {chargePointSpecification ? (
          <ChargePointSpecification
            chargePointName={chargePointName}
            {...chargePointSpecification}
          />
        ) : (
          <Spinner translationKey="shared.spinner.charge-point" />
        )}

        {tariff ? (
          <TariffSection
            tariff={tariff}
            isTariffComplex={isTariffComplex}
            user={order.customer}
            vatRate={order.cost.vatRate}
            isPrecaptureAccepted={isPrecaptureAccepted}
          />
        ) : (
          <Spinner translationKey="shared.spinner.order" />
        )}

        <LearnMoreLink questionId="tariff">
          {t('quote.learn-more-tariff')}
          <Icon name="arrow-right" />
        </LearnMoreLink>

        {order?.paymentInfos && (
          <div>
            <H3>{t('quote.credit-card-section.title')}</H3>
            <div className={css.editButtonContainer}>
              <Link
                onClick={onOpenPaymentSelection}
                startIcon={<Icon name="edit" />}>
                {t('quote.credit-card-section.edit-payment-method')}
              </Link>
            </div>

            <CreditCardInformation {...order.paymentInfos} />
          </div>
        )}
      </PageLayout.Body>

      {chargePointSpecification && order && (
        <PageLayout.Footer>
          {!isChargePointAvailable && (
            <Text color="error">
              {t('quote.charge-point-unavailable')}{' '}
              <LearnMoreLink questionId="charge-point-unavailable" />
            </Text>
          )}
          <Button
            disabled={!isChargePointAvailable}
            label={
              isPrecaptureAccepted
                ? t('quote.validate-button')
                : t('quote.select-payment-method')
            }
            loading={isPending}
            onClick={isPrecaptureAccepted ? onValidate : onOpenPaymentSelection}
          />
        </PageLayout.Footer>
      )}
    </PageLayout>
  );
}
