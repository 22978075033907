import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { userService } from '@app/core/service/user.service';
import { downloadFile } from '@app/core/helper/download-file';
import { fromJsonToBlob } from '@app/core/helper/from-json-to-blob';
import { catchApiError } from '@app/core/error/catch-api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { Spinner } from '@app/shared/spinner/spinner';
import { Link } from '@app/shared/typography/link/link';

interface DownloadPersonalInformationControllerProps {
  pseudo: string;
}

export const DownloadPersonalInformationController = (
  props: DownloadPersonalInformationControllerProps
): JSX.Element => {
  const { pseudo } = props;

  const { t } = useTranslation();
  const [pending, setPending] = useState(false);

  const handleDownload = useCallback(async () => {
    if (pending) {
      return;
    }

    setPending(true);
    try {
      const [promise] = userService.getGPDR();
      const data = await promise;
      const today = new Date();

      downloadFile(
        fromJsonToBlob(data),
        `${pseudo}-${today.toISOString()}.json`
      );
    } catch (error) {
      catchApiError(error, (apiError: ApiError) => {
        showErrorMessage('download-personal-data', apiError.code);
      });
    } finally {
      setPending(false);
    }
  }, [pseudo, pending]);

  return (
    <>
      {pending && <Spinner />}
      <Link color="p1" onClick={handleDownload}>
        {t('legal-text.download-personal-data-link')}
      </Link>
    </>
  );
};
