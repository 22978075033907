import classNames from 'classnames';
import { HTMLProps } from 'react';
import css from './divider.module.scss';

interface DividerProps extends HTMLProps<HTMLHRElement> {
  borderColor?: 'blue' | 'gray';
  type?: 'dashed' | 'solid';
}

export const Divider = ({
  borderColor = 'gray',
  type = 'dashed',
  className,
  ...props
}: DividerProps): JSX.Element => (
  <hr
    className={classNames(
      className,
      css.hr,
      type === 'solid' && css.solid,
      borderColor === 'blue' && css.blue
    )}
    {...props}
  />
);
