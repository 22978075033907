import { Navigate, generatePath, useParams } from 'react-router-dom';
import { CHARGE_POINT_PATH } from '@app/config/app-paths.const';
import { useEffect, useState } from 'react';
import { chargePointService } from '@app/core/service/charge-point.service';
import { ChargePointModel } from '@app/core/model/charge-point.model';
import { ChargePointView } from '@app/feature/charge-point/charge-point.view';
import { catchApiError } from '@app/core/error/catch-api-error';
import { ChargePointNotFoundView } from '@app/feature/charge-point/charge-point-not-found.view';
import { ApiError } from '@app/core/error/api-error';
import { ErrorCodeEnum } from '@app/config/error-config';

export const TinyChargePointController = (): JSX.Element => {
  const { tinyChargePointId } = useParams() as {
    tinyChargePointId: string;
  };

  const [chargePoint, setChargePoint] = useState<ChargePointModel>();
  const [isError, setIsError] = useState(false);
  const [isBeingInstalled, setIsBeingInstalled] = useState(false);

  useEffect(() => {
    const [promise, abortPromise] =
      chargePointService.getChargePointList(tinyChargePointId);

    promise
      .then((response) => {
        // TODO: 27/09/2023 - https://delmonicos.atlassian.net/browse/BFP-371 - Use this to display the connector selection
        if (response.length > 0 && response[0] !== null) {
          return setChargePoint(response[0]);
        }

        return setIsError(true);
      })
      .catch((err) => {
        catchApiError(err, () => {
          setIsError(true);

          catchApiError(err, (error: ApiError) => {
            setIsBeingInstalled(
              error.code === ErrorCodeEnum.OCPI_EVSE_NOT_FOUND ||
                error.code === ErrorCodeEnum.OCPI_LOCATION_NOT_FOUND
            );
          });
        });
      });

    return () => {
      abortPromise.abort();
    };
  }, [tinyChargePointId]);

  if (chargePoint?.chargePointId) {
    return (
      <Navigate
        to={generatePath(CHARGE_POINT_PATH, {
          delmoChargePointId: chargePoint.chargePointId,
        })}
      />
    );
  }

  return isError ? (
    <ChargePointNotFoundView isBeingInstalled={isBeingInstalled} />
  ) : (
    <ChargePointView pending />
  );
};
