import classNames from 'classnames';
import { useMemo } from 'react';

import { DoublePriceCircle } from '@app/feature/tariff/component/price-circle/double-price-circle';
import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { SinglePriceCircle } from '@app/feature/tariff/component/price-circle/single-price-circle';

import css from './price-circle.module.scss';

interface PriceCircleProps {
  pricePerKwh?: number;
  pricePerMin?: number;
  flatTTC?: number;
  className?: string;
  onClick: () => void;
}

export const PriceCircle = (props: PriceCircleProps): JSX.Element => {
  const {
    pricePerKwh = 0,
    pricePerMin = 0,
    flatTTC = 0,
    className,
    onClick,
  } = props;

  const unit = useMemo(() => {
    if (pricePerKwh) {
      return ChargePointPriceUnitEnum.PER_KWH;
    }

    if (pricePerMin) {
      return ChargePointPriceUnitEnum.PER_MIN;
    }

    return null;
  }, [pricePerKwh, pricePerMin]);

  const price = useMemo(() => {
    if (pricePerKwh > 0) {
      return pricePerKwh;
    }

    if (pricePerMin > 0) {
      return pricePerMin;
    }

    return flatTTC;
  }, [pricePerKwh, pricePerMin, flatTTC]);

  if (pricePerKwh && pricePerMin) {
    return (
      <div className={classNames(css.container, className)}>
        <DoublePriceCircle
          pricePerKwh={pricePerKwh}
          pricePerMin={pricePerMin}
          onClick={onClick}
        />
      </div>
    );
  }

  return (
    <div className={classNames(css.container, className)}>
      <div className={css.singleContainer}>
        <SinglePriceCircle price={price} unit={unit} onClick={onClick} />
      </div>
    </div>
  );
};
