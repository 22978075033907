import { useMemo } from 'react';
import i18n from '@app/core/locale/i18n';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_NAMESPACE, LOCALE_KEYS } from '@app/core/locale/locale';
import { FaqModel } from '@app/core/model/faq.model';
import {
  getFaqItem,
  localeFaqQuestionsRoot,
} from '@app/feature/faq/get-faq-item';
import { FaqDrawerController } from '@app/feature/faq/component/faq-drawer/faq-drawer.controller';
import { useFaqDrawer } from '@app/feature/faq/use-faq-drawer';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { HELP_PATH, HOME_PATH } from '@app/config/app-paths.const';
import { groupBy } from '@app/core/helper/group-by';

import { FaqView } from './faq.view';
import en from './asset/faq-en.json';
import fr from './asset/faq-fr.json';

i18n
  .addResourceBundle(
    LOCALE_KEYS.en,
    DEFAULT_NAMESPACE,
    { [localeFaqQuestionsRoot]: en },
    true
  )
  .addResourceBundle(
    LOCALE_KEYS.fr,
    DEFAULT_NAMESPACE,
    { [localeFaqQuestionsRoot]: fr },
    true
  );

type FaqControllerProps = {
  hideBackButton?: boolean;
};

export const FaqController = (props: FaqControllerProps): JSX.Element => {
  const { hideBackButton = false } = props;

  const { isAuthenticated } = useAuthentication();
  const faqItemList: FaqModel[] = useMemo(getFaqItem, []);
  const { drawerState, selectedItem, closeDrawer, openDrawer } = useFaqDrawer();

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(isAuthenticated ? HELP_PATH : HOME_PATH);
  };

  return (
    <>
      <FaqView
        isAuthenticated={isAuthenticated}
        faqItemList={groupBy(faqItemList, (faq) => faq.section)}
        onSelect={openDrawer}
        onBack={hideBackButton ? null : handleBack}
      />

      <FaqDrawerController
        id={selectedItem}
        onClose={closeDrawer}
        state={drawerState}
      />
    </>
  );
};
