import { useTranslation } from 'react-i18next';

import Biometric from '@app/asset/biometric.svg';
import { Button } from '@app/shared/button/button';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';
import { BackLink } from '@app/shared/back-link/back-link';
import { Link } from '@app/shared/typography/link/link';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';

import css from './webauthn-authentication.module.scss';

interface WebauthnAuthenticationViewProps {
  remainingLockTimeInMin?: number;
  pending: boolean;
  onStart: () => Promise<void>;
  onUseEmail: () => void;
  onBack?: () => void;
}

export const WebauthnAuthenticationView = ({
  remainingLockTimeInMin = 0,
  pending,
  onStart,
  onUseEmail,
  onBack = () => {},
}: WebauthnAuthenticationViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <BackLink onClick={onBack} />

      <DrawerLayout.Header>
        <H1>{t('authentication.webauthn.title')}</H1>
        <Text>{t('authentication.webauthn.information')}</Text>
      </DrawerLayout.Header>

      <DrawerLayout.Body className={css.body}>
        <Biometric onClick={onStart} />
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <Button
          onClick={onStart}
          loading={pending}
          timeout="webauthn"
          visuallyDisabled={remainingLockTimeInMin > 0}
          label={t('authentication.webauthn.submit-button', {
            count: remainingLockTimeInMin,
          })}
        />

        <Link onClick={onUseEmail}>
          {t('authentication.webauthn.use-email')}
        </Link>
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
