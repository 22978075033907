import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { CallSupportButton } from '@app/shared/call-support/call-support-button';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { FaqModel } from '@app/core/model/faq.model';

import css from './faq-drawer.module.scss';

interface FaqDrawerViewProps {
  faqItem: FaqModel;
  onBack?: () => void;
}

export const FaqDrawerView = ({
  faqItem,
  onBack = () => {},
}: FaqDrawerViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <BackLink onClick={onBack} />

      <DrawerLayout.Header>
        <H1>{faqItem.question}</H1>
      </DrawerLayout.Header>

      <DrawerLayout.Body className={css.body}>
        {faqItem.answers.map((response) => (
          <Text key={response}>{response}</Text>
        ))}
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <Text>{t('faq.button-link')}</Text>
        <CallSupportButton />
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
