import { Drawer, DrawerStateEnum } from '@app/shared/drawer/drawer';
import classNames from 'classnames';

import { SuggestCheckingView } from '@app/feature/check-pseudo/suggest-checking/suggest-checking.view';
import { CheckPseudoDrawer } from '@app/feature/check-pseudo/check-pseudo/check-pseudo.drawer';

import css from './check-pseudo.module.scss';

interface CheckPseudoViewProps {
  drawerState: DrawerStateEnum;
  onCheckPseudo: () => void;
  onClose: () => void;
}

export const CheckPseudoView = (props: CheckPseudoViewProps): JSX.Element => {
  const { drawerState, onCheckPseudo, onClose } = props;

  return (
    <Drawer
      containerClassName={classNames(
        drawerState === DrawerStateEnum.SMALL && css.smallContainer
      )}
      onClose={onClose}
      state={drawerState}
      icon={drawerState === DrawerStateEnum.OPEN && 'user-circle'}>
      {drawerState === DrawerStateEnum.SMALL ? (
        <SuggestCheckingView onCancel={onClose} onCheck={onCheckPseudo} />
      ) : (
        <CheckPseudoDrawer onClose={onClose} />
      )}
    </Drawer>
  );
};
