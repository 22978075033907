import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';

import css from './hourly-tariff-title.module.scss';
import {
  TimeRestrictionProps,
  useRestrictionText,
} from '../restriction/use-restriction-text';

export function HourlyTariffTitle(
  props: TimeRestrictionProps
): JSX.Element | null {
  const restrictionText = useRestrictionText()(props);
  const { t } = useTranslation();

  if (!restrictionText) {
    return null;
  }

  return (
    <div className={css.container}>
      <Text className={css.title}>
        <Icon name="clock-fast-forward" />
        {t('tariff.hour-title')}
      </Text>

      <Text className={css.caption}>{restrictionText}</Text>
    </div>
  );
}
