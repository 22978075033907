import { useTranslation } from 'react-i18next';

import { Icon } from '@app/shared/icon/icon';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { ItemMenu } from '@app/shared/item-menu/item-menu';

import { ValidateOrUpdateEmailDrawer } from './component/validate-or-update-email.drawer';
import css from './manage-email-authentication.module.scss';

interface ManageEmailAuthenticationViewProps {
  emailAddress: string;
  isEmailVerified: boolean;
  onRefresh: () => void;
}

export const ManageEmailAuthenticationView = (
  props: ManageEmailAuthenticationViewProps
): JSX.Element => {
  const { t } = useTranslation();
  const { emailAddress, isEmailVerified, onRefresh } = props;

  const {
    openDrawer: openValidateOrUpdateEmailDrawer,
    closeDrawer,
    drawerState,
  } = useDrawer();

  const handleSuccess = () => {
    onRefresh();
    closeDrawer();
  };

  return (
    <>
      <ItemMenu
        onClick={openValidateOrUpdateEmailDrawer}
        left={
          <Icon
            className={css.icon}
            name={isEmailVerified ? 'check' : 'warning'}
            color={isEmailVerified ? 'primary' : 'secondary'}
          />
        }
        label={emailAddress}
        caption={
          isEmailVerified
            ? null
            : t('manage-authentication-method.email.not-yet-verified')
        }
        right={
          <Icon
            className={css.icon}
            name="settings"
            color="primary"
            badge={!isEmailVerified}
          />
        }
      />
      <ValidateOrUpdateEmailDrawer
        isUpdating={isEmailVerified}
        state={drawerState}
        emailAddress={emailAddress}
        onClose={closeDrawer}
        className={css.verifyLink}
        onSuccess={handleSuccess}
      />
    </>
  );
};
