import { useTranslation } from 'react-i18next';

import { configurationService } from '@app/core/configuration/configuration.service';
import { Button, ButtonProps } from '../button/button';

const DELMONICOS_SUPPORT_PHONE_NUMBER =
  configurationService.getSupportPhoneNumber();

interface CallSupportButtonProps
  extends Omit<ButtonProps, 'onClick' | 'label'> {
  label?: string;
}

export const CallSupportButton = ({
  label,
  ...linkProps
}: CallSupportButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <a href={`tel:${DELMONICOS_SUPPORT_PHONE_NUMBER}`}>
      <Button
        {...linkProps}
        label={
          label ??
          t('shared.call-support.label', {
            phoneNumber: DELMONICOS_SUPPORT_PHONE_NUMBER,
          })
        }
      />
    </a>
  );
};
