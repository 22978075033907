import { useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { CHARGING_PATH, CREATE_QUOTE_PATH } from '@app/config/app-paths.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { ChargePointNotFoundView } from '@app/feature/charge-point/charge-point-not-found.view';
import { CreateQuoteRouterStateModel } from '@app/core/model/create-quote-router-state.model';
import { useChargePointService } from '@app/core/service/use-charge-point.service';
import {
  filterActiveNowTariffUsingStartAndEndTime,
  hasTariffElementHourlyRestrictions,
  isTariffElementComplex,
} from '@app/core/helper/ocpi-utils';
import { TariffElementModel } from '@app/core/model/tariff.model';

import { ChargePointView } from './charge-point.view';

export const ChargePointController = (): JSX.Element => {
  const { delmoChargePointId } = useParams() as { delmoChargePointId: string };
  const { isChargeStarted } = useCharge();

  const navigate = useNavigate();

  const scannedAt = useMemo(() => new Date(), []);

  const { isLoading, chargePoint, isBeingInstalled, tariff } =
    useChargePointService(delmoChargePointId);

  const currentTariffElements = filterActiveNowTariffUsingStartAndEndTime(
    tariff?.elements ?? []
  );
  const tariffElements: TariffElementModel[] = useMemo(() => {
    if (currentTariffElements?.length > 0) {
      return currentTariffElements;
    }

    if (chargePoint?.pricing) {
      return [{ priceComponents: chargePoint?.pricing ?? {} }]; // backwards compatibility with chargepoint pricing key
    }

    return [];
  }, [currentTariffElements, chargePoint?.pricing]);

  const isTariffComplex = isTariffElementComplex(tariffElements);
  const hasHourlyRestrictions = hasTariffElementHourlyRestrictions(
    tariff?.elements ?? []
  );

  const handleGoToQuote = useCallback(() => {
    const state: CreateQuoteRouterStateModel = {
      delmoChargePointId,
      scannedAt,
    };

    navigate(CREATE_QUOTE_PATH, { state });
  }, [navigate, delmoChargePointId, scannedAt]);

  const handleGoToCurrentCharge = useCallback(() => {
    navigate(CHARGING_PATH);
  }, [navigate]);

  if (!isLoading && !chargePoint) {
    return <ChargePointNotFoundView isBeingInstalled={isBeingInstalled} />;
  }

  return (
    <ChargePointView
      currentTariff={{
        tariffAltText: tariff?.tariffAltText,
        elements: tariffElements,
      }}
      fullTariff={tariff}
      isTariffComplex={isTariffComplex}
      hasHourlyRestrictions={hasHourlyRestrictions}
      pending={isLoading}
      chargePoint={chargePoint}
      onStart={handleGoToQuote}
      onGoToCurrentCharge={handleGoToCurrentCharge}
      isChargeStarted={isChargeStarted}
    />
  );
};
