import { useCallback, useState } from 'react';

import { setInLocalStorage } from '@app/core/storage/local-storage';
import { setInSessionStorage } from '@app/core/storage/session-storage';
import {
  EMAIL_LSK,
  LAST_AUTHENTICATION_METHOD_LSK,
  PSEUDO_LSK,
} from '@app/config/localstorage-keys.const';
import { ENABLE_BACK_UP_AUTHENTICATION_SSK } from '@app/config/sessionstorage-keys.const';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { useRedirectToInitialDestination } from '@app/router/redirect/use-redirect-to-initial-destination';
import { RecoverAccountWithEmailController } from '@app/feature/recover-account/recover-account-with-email/recover-account-with-email.controller';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

import { RecoverAccountWithWebauthnController } from './recover-account-with-webauthn/recover-account-with-webauthn.controller';

interface RecoverAccountControllerProps {
  emailAddress?: string;
  onNavigateToRegistration: () => void;
  onBack: () => void;
}

export const RecoverAccountController = (
  props: RecoverAccountControllerProps
): JSX.Element => {
  const { emailAddress, onNavigateToRegistration, onBack } = props;
  const [recoveringMethod, setRecoveringMethod] =
    useState<AuthenticationMethodEnum>(AuthenticationMethodEnum.EMAIL);

  const { authenticate } = useAuthentication();

  const handleRecoverWithWebauthn = useCallback(
    () => setRecoveringMethod(AuthenticationMethodEnum.WEBAUTHN),
    []
  );

  const handleRecoverWithEmail = useCallback(() => {
    setRecoveringMethod(AuthenticationMethodEnum.EMAIL);
  }, []);

  const redirectToInitialDestination = useRedirectToInitialDestination();

  const handleAuthentication = (
    jwt: string,
    authenticationMethod: AuthenticationMethodEnum,
    identifier: string
  ) => {
    setInLocalStorage(
      authenticationMethod === AuthenticationMethodEnum.EMAIL
        ? EMAIL_LSK
        : PSEUDO_LSK,
      identifier
    );

    setInLocalStorage(
      LAST_AUTHENTICATION_METHOD_LSK,
      authenticationMethod.toString()
    );

    setInSessionStorage(ENABLE_BACK_UP_AUTHENTICATION_SSK, true.toString());

    authenticate(jwt);
    redirectToInitialDestination();
  };

  switch (recoveringMethod) {
    case AuthenticationMethodEnum.WEBAUTHN:
      return (
        <RecoverAccountWithWebauthnController
          onNavigateToRegistration={onNavigateToRegistration}
          onAuthenticate={handleAuthentication}
          onBack={handleRecoverWithEmail}
        />
      );

    case AuthenticationMethodEnum.EMAIL:
    default:
      return (
        <RecoverAccountWithEmailController
          emailAddress={emailAddress}
          onSkip={handleRecoverWithWebauthn}
          onAuthenticate={handleAuthentication}
          onBack={onBack}
        />
      );
  }
};
