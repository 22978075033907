import i18n from '@app/core/locale/i18n';

import { DEFAULT_NAMESPACE, LOCALE_KEYS } from '@app/core/locale/locale';
import { FaqModel } from '@app/core/model/faq.model';

export const localeFaqQuestionsRoot = `faq-questions`;

export function getFaqItem(): FaqModel[] {
  return i18n.getResource(
    i18n.resolvedLanguage ?? LOCALE_KEYS.fr,
    DEFAULT_NAMESPACE,
    localeFaqQuestionsRoot
  ) as FaqModel[];
}

const localFaqFallback = `faq.fallback`;

export function getFallbackFaqItem(): FaqModel {
  return i18n.getResource(
    i18n.resolvedLanguage ?? LOCALE_KEYS.fr,
    DEFAULT_NAMESPACE,
    localFaqFallback
  ) as FaqModel;
}
