import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Spinner, SpinnerProps } from '../spinner/spinner';
import css from './infinite-scroll.module.scss';

export interface InfiniteScrollProps extends SpinnerProps {
  hasMore: boolean;
  onLoad: () => Promise<void>;
  children: JSX.Element[];
  className?: string;
}

export const InfiniteScroll = (props: InfiniteScrollProps): JSX.Element => {
  const observerTarget = useRef(null);
  const [pending, setPending] = useState(false);

  const { children, hasMore, onLoad, className, ...spinnerProps } = props;

  useEffect(() => {
    if (!observerTarget.current) {
      return () => {};
    }

    const target = observerTarget.current;

    // internal variable used to prevent duplicate request
    let isLoading = false;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !isLoading) {
        isLoading = true;
        setPending(true);

        onLoad().finally(() => {
          setPending(false);
          isLoading = false;
        });
      }
    });

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [observerTarget, onLoad, hasMore]);

  return (
    <>
      {children}

      <div className={classNames(css.more, className)}>
        <div className={css.virtual} ref={observerTarget} />
        {pending && <Spinner {...spinnerProps} />}
      </div>
    </>
  );
};
