import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@app/shared/button/button';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { downloadFile } from '@app/core/helper/download-file';
import { ErrorCodeEnum } from '@app/config/error-config';
import { ApiError } from '@app/core/error/api-error';
import { invoicingService } from '@app/core/service/invoicing.service';
import { useGenerateInvoiceFilename } from './use-generate-invoice-filename';

interface DownloadInvoiceButtonProps
  extends Omit<ButtonProps, 'label' | 'onClick'> {
  invoiceId: string;
  pseudo: string;
  createdAt: Date;
}

export const DownloadInvoiceButton = (
  props: DownloadInvoiceButtonProps
): JSX.Element => {
  const { invoiceId, pseudo, createdAt, ...buttonProps } = props;

  const generateInvoiceFilename = useGenerateInvoiceFilename();

  const [pending, setPending] = useState(false);
  const { t } = useTranslation();

  const handleDownload = useCallback(async () => {
    setPending(true);

    try {
      const downloadPromise = invoicingService.download(invoiceId);
      const invoiceAsBlob: Blob = await downloadPromise;

      if (invoiceAsBlob.size === 0) {
        throw new Error('Download empty');
      }

      downloadFile(invoiceAsBlob, generateInvoiceFilename(createdAt, pseudo));
    } catch (error) {
      if (!(error instanceof ApiError)) {
        showErrorMessage(
          'download-invoice',
          ErrorCodeEnum.INVOICE_PAYLOAD_EMPTY
        );
        return;
      }

      if (error.isIntercepted) {
        return;
      }

      showErrorMessage('download-invoice', error.code);
    } finally {
      setPending(false);
    }
  }, [invoiceId, createdAt, pseudo, generateInvoiceFilename]);

  return (
    <Button
      loading={pending}
      label={t('shared.invoice.download-button.label')}
      onClick={handleDownload}
      {...buttonProps}
    />
  );
};
