import { useTranslation } from 'react-i18next';

import { Card } from '@app/shared/card/card';
import { Divider } from '@app/shared/divider/divider';
import { Text } from '@app/shared/typography/text/text';
import { PricingModel } from '@app/core/model/charge-point.model';
import { CustomerInformationsModel } from '@app/core/model/customer-information.model';

import css from './price-card.module.scss';

type Props = {
  pricing: PricingModel;
  user: CustomerInformationsModel;
  vatRate: number;
};

export function PriceCard(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { pricing, user, vatRate } = props;
  const { perKwhTTC = 0, flatTTC = 0, perTimeChargingHourTTC = 0 } = pricing;
  const { firstName, lastName, email } = user;

  return (
    <Card className={css.card}>
      <div className={css.cardSection}>
        {perKwhTTC > 0 && (
          <div className={css.priceItem}>
            <Text>{t('quote.price-section.price-per-kwh')}</Text>
            <Text bold>
              {t('quote.price-section.amount', {
                amount: perKwhTTC,
              })}
            </Text>
          </div>
        )}

        {perTimeChargingHourTTC > 0 && (
          <div className={css.priceItem}>
            <Text>{t('quote.price-section.price-per-min')}</Text>
            <Text bold>
              {t('quote.price-section.amount', {
                amount: perTimeChargingHourTTC / 60,
              })}
            </Text>
          </div>
        )}

        {flatTTC > 0 && (
          <div className={css.priceItem}>
            <Text>{t('quote.price-section.flat-fee')}</Text>
            <Text bold>
              {t('quote.price-section.amount', {
                amount: flatTTC,
              })}
            </Text>
          </div>
        )}

        <div className={css.priceItem}>
          <div />
          <Text className={css.vat}>
            {t('quote.price-section.vat', { vat: vatRate })}
          </Text>
        </div>
      </div>

      <Divider />

      <div className={css.cardSection}>
        <div>
          <Text>
            {firstName} {lastName}
          </Text>
        </div>

        <div>
          <Text>{email}</Text>
        </div>
      </div>
    </Card>
  );
}
