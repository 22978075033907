import { useTranslation } from 'react-i18next';

import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { FaqModel } from '@app/core/model/faq.model';

import { FaqSection } from './component/faq-section/faq-section';

interface FaqViewProps {
  isAuthenticated?: boolean;
  faqItemList: Record<string, FaqModel[]>;
  onSelect: (itemId: string) => void;
  onBack: (() => void) | null;
}

export const FaqView = (props: FaqViewProps): JSX.Element => {
  const { isAuthenticated = false, faqItemList, onSelect, onBack } = props;
  const { t } = useTranslation();

  return (
    <PageLayout>
      {onBack && <BackLink onClick={onBack} />}

      <PageLayout.Header>
        <H1>{t('faq.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        {Object.entries(faqItemList).map(([section, items]) => (
          <FaqSection
            key={section}
            onSelect={onSelect}
            items={items}
            title={t(`faq.section.${section}`)}
          />
        ))}
      </PageLayout.Body>

      {isAuthenticated && <PageLayout.AppbarFooter />}
    </PageLayout>
  );
};
