import { useTranslation } from 'react-i18next';

import { BackLink } from '@app/shared/back-link/back-link';
import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';

interface RegisterOrRecoverProps {
  onRegister: () => void;
  onRecover: () => void;
  onCancel: () => void;
}

export const RegisterOrRecover = (
  props: RegisterOrRecoverProps
): JSX.Element => {
  const { onRecover, onRegister, onCancel } = props;
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <BackLink onClick={onCancel} />

      <DrawerLayout.Header>
        <H1>{t('home.register-or-recover.title')}</H1>
        <Text>{t('home.register-or-recover.caption')}</Text>
      </DrawerLayout.Header>

      <DrawerLayout.Body />

      <DrawerLayout.Footer>
        <Button
          label={t('home.register-or-recover.register-button')}
          onClick={() => onRegister()}
        />
        <Button
          variant="outlined"
          label={t('home.register-or-recover.recover-button')}
          onClick={() => onRecover()}
        />
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
