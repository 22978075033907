import { HOURS_BEFORE_CHECKING_PSEUDO } from '@app/config/check-pseudo.const';
import { LAST_PSEUDO_CHECKING_LSK } from '@app/config/localstorage-keys.const';
import {
  getFromLocalStorage,
  setInLocalStorage,
} from '@app/core/storage/local-storage';

export function getLastPseudoChecking(): Date | null {
  const lastPseudoChecking = getFromLocalStorage(LAST_PSEUDO_CHECKING_LSK);

  if (!lastPseudoChecking) {
    return null;
  }

  return new Date(lastPseudoChecking);
}

export function setLastValidationToNow(): void {
  setInLocalStorage(LAST_PSEUDO_CHECKING_LSK, new Date().toISOString());
}

export function postponeCheckToTomorrow(): void {
  const checkExpirationMinusOneDay = new Date();

  checkExpirationMinusOneDay.setHours(
    checkExpirationMinusOneDay.getHours() - HOURS_BEFORE_CHECKING_PSEUDO + 24
  );

  setInLocalStorage(
    LAST_PSEUDO_CHECKING_LSK,
    checkExpirationMinusOneDay.toISOString()
  );
}

export function getNumberOfHours(date1: Date, date2: Date): number {
  return Math.abs(date1.getTime() - date2.getTime()) / 3600000;
}

export function isSuggestPseudoCheckingExpired(): boolean {
  const lastPseudoChecking = getLastPseudoChecking();

  if (!lastPseudoChecking) {
    return true;
  }

  const now = new Date();

  return (
    getNumberOfHours(now, lastPseudoChecking) > HOURS_BEFORE_CHECKING_PSEUDO
  );
}
