import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getDuration } from '@app/core/helper/get-duration';
import { Card, CardProps } from '@app/shared/card/card';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { CHARGING_PATH } from '@app/config/app-paths.const';

import css from './charge-in-progress-card.module.scss';

interface ChargeInProgressCardProps extends CardProps {
  startedAt: Date;
  location?: string;
  estimatedPrice?: number;
}
export const ChargeInProgressCard = ({
  startedAt,
  location,
  estimatedPrice,
  className,
  ...props
}: ChargeInProgressCardProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [duration, setDuration] = useState(getDuration(startedAt));

  const handleGoToCharge = useCallback(() => {
    navigate(CHARGING_PATH);
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(getDuration(startedAt));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [startedAt]);

  return (
    <button onClick={handleGoToCharge} className={className}>
      <Card className={css.card} {...props}>
        <Text fontSize="large" color="secondary" className={css.title}>
          {t('dashboard.charge-in-progress-card.title')}
        </Text>

        <div className={css.item}>
          <Icon name="clock-refresh" color="primary" />
          <Text bold fontSize="large">
            {t('dashboard.charge-in-progress-card.duration', {
              duration,
            })}
          </Text>
        </div>

        <div className={css.item}>
          <Icon name="marker-pin" color="primary" />
          <Text bold fontSize="large">
            {t('dashboard.charge-in-progress-card.location', {
              location,
            })}
          </Text>
        </div>

        <div className={css.item}>
          <Icon name="euro" color="primary" />
          <Text bold fontSize="large">
            {t('dashboard.charge-in-progress-card.estimated-price', {
              estimatedPrice,
            })}
          </Text>
        </div>
      </Card>
    </button>
  );
};
