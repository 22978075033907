import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { ConnectorTypeEnum } from '@app/core/model/enum/connector-type.enum';
import { ChargePointName } from '@app/shared/charge-point/charge-point-name/charge-point-name';

import css from './charge-point-header.module.scss';

interface ChargePointHeaderProps {
  chargePointName?: string;
  connectorType?: ConnectorTypeEnum;
}

export const ChargePointHeader = (
  props: ChargePointHeaderProps
): JSX.Element => {
  const { chargePointName, connectorType } = props;
  const { t } = useTranslation();

  return (
    <div className={css.header}>
      <Text fontSize="large">{t('charge-point.header.title')}</Text>

      <ChargePointName
        name={chargePointName}
        connectorType={connectorType}
        fontSize="large"
        bold
      />
    </div>
  );
};
