import { PricingModel } from '@app/core/model/charge-point.model';
import { Text } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';
import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import classNames from 'classnames';
import { Icon } from '@app/shared/icon/icon';

import css from './price-component.module.scss';
import { usePriceUnit } from '../price-circle/use-price-unit';

type Props = PricingModel & {
  className?: string;
};

export function PriceComponent(props: Props): JSX.Element {
  const {
    perKwhTTC = 0,
    perTimeChargingHourTTC = 0,
    flatTTC = 0,
    className,
  } = props;
  const { t } = useTranslation();
  const kWhPriceUnit = usePriceUnit(ChargePointPriceUnitEnum.PER_KWH, false);
  const timePriceUnit = usePriceUnit(ChargePointPriceUnitEnum.PER_MIN, false);
  const flatPriceUnit = usePriceUnit(null, false);

  return (
    <div className={classNames(css.container, className)}>
      {perKwhTTC > 0 && (
        <div>
          <Icon name="price" />
          <Text className={css.price}>
            {t('tariff.currency', { value: perKwhTTC })}
          </Text>
          <Text>{kWhPriceUnit}</Text>
        </div>
      )}

      {perTimeChargingHourTTC > 0 && (
        <div>
          <Icon name="price" />
          <Text className={css.price}>
            {t('tariff.currency', { value: perTimeChargingHourTTC })}
          </Text>
          <Text>{timePriceUnit}</Text>
        </div>
      )}

      {flatTTC > 0 && (
        <div>
          <Icon name="price" />
          <Text className={css.price}>
            {t('tariff.currency', { value: flatTTC })}
          </Text>
          <Text>{flatPriceUnit}</Text>
        </div>
      )}
    </div>
  );
}
