import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { useTimeout } from '@app/core/helper/use-timeout';
import { SpinnerTimeout, TIMEOUT } from '@app/config/timeout.const';
import {
  useTypedTranslation,
  TranslationLeaves,
} from '@app/core/helper/use-typed-translation';

import { Text } from '../typography/text/text';
import './spinner.scss';
import css from './spinner.module.scss';

export interface SpinnerProps {
  timeout?: SpinnerTimeout;
  translationKey?: TranslationLeaves;
  className?: string;
  size?: 'sm' | 'md';
}
export const Spinner = ({
  timeout = 'default',
  translationKey,
  className,
  size = 'sm',
}: SpinnerProps): JSX.Element => {
  const [showCustomMessage, setShowCustomMessage] = useState(false);
  const [showGenericMessage, setShowGenericMessage] = useState(false);
  const { t } = useTypedTranslation();

  const genericMessage = useMemo(() => t('shared.spinner.generic'), [t]);

  // Show custom message after TIMEOUT.SPINNER.custom seconds
  useTimeout(() => {
    setShowCustomMessage(true);
  }, TIMEOUT.SPINNER.customMessage * 1000);

  // Show generic message after TIMEOUT.SPINNER.generic seconds = TIMEOUT.SPINNER.custom + 10s
  useTimeout(() => {
    setShowGenericMessage(true);
  }, TIMEOUT.SPINNER[timeout] * 1000);

  useEffect(() => {
    // Spinner can be used in a button (without a translationKey as custom message). If so, show a warning toast to provide feedback to the user.
    if (showGenericMessage && !translationKey) {
      toast.warning(genericMessage);
    }
  }, [showGenericMessage, translationKey, genericMessage]);

  return (
    <Text className={css.container}>
      <span
        className={classNames(
          'spinner',
          size === 'sm' && 'spinner-sm',
          size === 'md' && 'spinner-md',
          className
        )}
      />

      {translationKey && (showCustomMessage || showGenericMessage) && (
        <div className={css.message}>
          {showCustomMessage && t(translationKey)}
          {showGenericMessage && ` ${genericMessage}`}
        </div>
      )}
    </Text>
  );
};
