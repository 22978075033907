import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { FaqModel } from '@app/core/model/faq.model';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';

import css from './faq-list-item.module.scss';

interface FaqListItemProps {
  items: FaqModel[];
  onSelect: (itemId: string) => void;
}

export const FaqListItem = ({
  items,
  onSelect,
}: FaqListItemProps): JSX.Element => {
  return (
    <ListItemMenu>
      {items.map((item) => (
        <ItemMenu
          key={item.id}
          className={css.itemMenu}
          label={item.question}
          onClick={() => onSelect(item.id)}
        />
      ))}
    </ListItemMenu>
  );
};
