import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

interface DeletePaymentMethodConfirmViewProps {
  pending: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export const DeletePaymentMethodConfirmView = ({
  pending,
  onCancel,
  onDelete,
}: DeletePaymentMethodConfirmViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <Icon name="trash" />
        <H1>{t('user-payment-method.delete.request.title')}</H1>
        <Text>{t('user-payment-method.delete.request.sub-title')}</Text>
        <Text>{t('user-payment-method.delete.request.info')}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer>
        <Button
          variant="outlined"
          label={t('user-payment-method.delete.request.cancel-button')}
          onClick={onCancel}
        />
        <Button
          loading={pending}
          label={t('user-payment-method.delete.request.delete-button')}
          onClick={onDelete}
        />
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
