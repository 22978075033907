import classNames from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { Icon, IconName } from '../icon/icon';
import { Spinner, SpinnerProps } from '../spinner/spinner';

import css from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  variant?: 'contained' | 'outlined';
  icon?: IconName | null;
  disabled?: boolean;
  visuallyDisabled?: boolean;
  loading?: boolean;
  timeout?: SpinnerProps['timeout'];
  onClick?: () => void;
}

export const Button = ({
  label,
  type = 'button',
  variant = 'contained',
  icon = null,
  disabled = false,
  visuallyDisabled = false,
  loading = false,
  timeout,
  onClick = () => {},
  className,
  ...props
}: ButtonProps): JSX.Element => (
  <button
    className={classNames(
      css.button,
      variant === 'contained' && css.contained,
      variant === 'outlined' && css.outlined,
      visuallyDisabled && css.disabled,
      className
    )}
    onClick={onClick}
    disabled={disabled || loading}
    type={type}
    {...props}>
    {loading && <Spinner size="sm" timeout={timeout} />}
    {!loading && icon && <Icon name={icon} />}
    {label}
  </button>
);
