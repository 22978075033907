import { useTranslation } from 'react-i18next';

import { Card, CardProps } from '@app/shared/card/card';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import css from './charge-in-progress-invoice-card.module.scss';

interface ChargeInProgressInvoiceCardProps extends CardProps {}

export const ChargeInProgressInvoiceCard = ({
  className,
  ...props
}: ChargeInProgressInvoiceCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Card className={css.card} {...props}>
        <Text fontSize="large" color="secondary" className={css.text}>
          {t('dashboard.charge-in-progress-invoice-card.title')}
        </Text>

        <Icon name="information" color="primary" className={css.icon} />
        <Text bold fontSize="large">
          {t('dashboard.charge-in-progress-invoice-card.sub-title')}
        </Text>
      </Card>
    </div>
  );
};
