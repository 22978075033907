import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from '@app/shared/icon/icon';
import { SCAN_PATH } from '@app/config/app-paths.const';

import { AppbarLink } from './appbar-link/appbar.link';
import css from './scan-link.module.scss';

interface ScanLinkProps {
  on?: boolean;
}

export const ScanLink = ({ on = false }: ScanLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AppbarLink
      icon="scan"
      to={SCAN_PATH}
      label={t('shared.app-bar.scan')}
      on={on}
      className={classNames(css.scan, on && css.scanActive)}>
      {!on && (
        <div className={css.scanAvatar}>
          <Icon name="scan" />
        </div>
      )}
    </AppbarLink>
  );
};
