import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { WithChargePointIdProps } from '@app/router/guard/with-charge-point-id.guard';
import { PowerTypeEnum } from '@app/core/model/enum/power-type.enum';
import { useChargePointService } from '@app/core/service/use-charge-point.service';

import { ChargingLayout } from './component/charging-layout/charging-layout';
import { StartChargingCarousel } from './component/start-charging/start-charging-carousel/start-charging-carousel';
import { Charging } from './component/charging/charging';
import { StartChargingCarouselTypeEnum } from './component/start-charging/start-charging-carousel/start-charging-carousel.config';

export const ChargingController = (
  props: WithChargePointIdProps
): JSX.Element => {
  const { delmoChargePointId } = props;

  const { chargePoint } = useChargePointService(delmoChargePointId);

  const carouselConfiguration: StartChargingCarouselTypeEnum = useMemo(
    () =>
      chargePoint?.specifications.powerType === PowerTypeEnum.DC
        ? StartChargingCarouselTypeEnum.dc
        : StartChargingCarouselTypeEnum.ac,
    [chargePoint?.specifications.powerType]
  );
  const { chargeState } = useCharge();

  const { t } = useTranslation();

  useEffect(() => {
    switch (chargeState) {
      case ChargeStateEnum.RETRY_START_CHARGE:
        toast.warn(t('charging.toast.warning-start'));
        break;
      case ChargeStateEnum.RETRY_STOP_CHARGE:
        toast.warn(t('charging.toast.warning-stop'));
        break;
      default:
        toast.dismiss();
    }
  }, [chargeState, t]);

  return (
    <ChargingLayout>
      {chargeState < ChargeStateEnum.CHARGING ? (
        <StartChargingCarousel configuration={carouselConfiguration} />
      ) : (
        <Charging />
      )}
    </ChargingLayout>
  );
};
