import { HTMLProps } from 'react';
import classNames from 'classnames';

import { Icon, IconName } from '../icon/icon';
import { Text } from '../typography/text/text';
import css from './counter.module.scss';

interface CounterProps extends HTMLProps<HTMLDivElement> {
  icon?: IconName;
  value: string;
  outline?: boolean;
}
export const Counter = ({
  outline = false,
  icon,
  value,
  className = '',
  children,
}: CounterProps): JSX.Element => (
  <div className={classNames(css.counter, outline && css.border, className)}>
    {children}
    {icon && !children && <Icon className={css.text} name={icon} />}
    <Text className={css.text}>{value}</Text>
  </div>
);
