import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';
import ChargeError from '@app/asset/charge-error.svg';

import css from './charge-overview-error.module.scss';

interface ChargeOverviewErrorProps {
  onGoToDashboard: () => void;
}

export const ChargeOverviewError = ({
  onGoToDashboard,
}: ChargeOverviewErrorProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout colored>
      <PageLayout.Body className={css.body}>
        <ChargeError />
        <H1 color="light">{t('charge-overview.error.title')}</H1>
        <div className={css.caption}>
          <Text color="light">{t('charge-overview.error.caption-1')}</Text>
          <Text color="light">{t('charge-overview.error.caption-2')}</Text>
        </div>
      </PageLayout.Body>

      <PageLayout.Footer>
        <Button
          label={t('charge-overview.go-to-dashboard-button')}
          onClick={onGoToDashboard}
        />
      </PageLayout.Footer>
    </PageLayout>
  );
};
