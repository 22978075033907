import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

import { useCreateAuthenticator } from './use-create-authenticator';
import css from './create-authenticator.module.scss';

interface CreateAuthenticatorProps {
  onCreate: (newAuthenticator: WebauthnAuthenticatorModel) => void;
}

export const CreateAuthenticator = (
  props: CreateAuthenticatorProps
): JSX.Element => {
  const { t } = useTranslation();
  const { onCreate } = props;

  const { pending, onStart } = useCreateAuthenticator({
    onSuccess: onCreate,
  });

  return (
    <Button
      className={css.badge}
      label={t(
        'manage-authentication-method.webauthn.create-authenticator.label'
      )}
      variant="outlined"
      onClick={onStart}
      loading={pending}
      timeout="webauthn"
    />
  );
};
