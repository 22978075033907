import { useTranslation } from 'react-i18next';
import { LineChartOptions } from 'chartist';

import { H3 } from '@app/shared/typography/heading/heading';

import { ChartProps } from './model/chart-props';
import { LineChart } from './line-chart/line-chart';
import css from './charge-chart.module.scss';

function getChartOptions(numberOfPoints: number): Partial<LineChartOptions> {
  return {
    showPoint: numberOfPoints <= 16,
    showArea: false,
  };
}

export const SpeedChart = (props: ChartProps): JSX.Element => {
  const { points } = props;
  const { t } = useTranslation();

  return (
    <div>
      <H3 className={css.chartTitle}>{t('charging.chart.speed')}</H3>
      <LineChart points={points} options={getChartOptions(points.length)}>
        {({ chartRef }) => <div className="speed-chart" ref={chartRef} />}
      </LineChart>
    </div>
  );
};
