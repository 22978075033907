import { Drawer, DrawerStateEnum } from '@app/shared/drawer/drawer';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { RedirectToInitialDestination } from '@app/router/redirect/redirect-to-initial-destination';

import { HomeView } from './home.view';
import { HomeDrawer } from './home-drawer';

export const HomeController = (): JSX.Element => {
  const { drawerState, openDrawer, closeDrawer } = useDrawer();
  const { isAuthenticated } = useAuthentication();

  // This checks if no authentication workflow is on-going
  if (isAuthenticated && drawerState === DrawerStateEnum.CLOSE) {
    return <RedirectToInitialDestination />;
  }

  return (
    <>
      <Drawer state={drawerState} onClose={closeDrawer} icon="user-circle">
        <HomeDrawer onCancel={closeDrawer} />
      </Drawer>

      <HomeView onStart={openDrawer} />
    </>
  );
};
