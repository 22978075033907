import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Text } from '@app/shared/typography/text/text';
import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import PriceCircleSvg from '@app/asset/price-circle.svg';

import css from './price-circle.module.scss';
import { usePriceUnit } from './use-price-unit';

interface SinglePriceCircleProps {
  price?: number;
  unit?: ChargePointPriceUnitEnum | null;
  className?: string;
  onClick?: () => void;
}

export const SinglePriceCircle = ({
  price,
  unit,
  className,
  onClick = () => {},
}: SinglePriceCircleProps): JSX.Element => {
  const { t } = useTranslation();
  const unitLabel = usePriceUnit(unit);

  return (
    <button onClick={onClick}>
      <div className={classNames(css.priceCircle, className)}>
        <PriceCircleSvg className={css.priceCircleSvg} />

        <Text bold className={css.title}>
          {t('tariff.price', { value: price })}
        </Text>

        <Text bold color="tertiary">
          {t(unitLabel)}
        </Text>
      </div>
    </button>
  );
};
