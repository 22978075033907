import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

interface ConfirmDeleteAccountDialogProps {
  pending: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const ConfirmDeleteAccountDialog = (
  props: ConfirmDeleteAccountDialogProps
): JSX.Element => {
  const { pending, onContinue, onCancel } = props;
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <H1>{t('manage-data.delete-account.are-you-sure')}</H1>
        <Text>{t('manage-data.delete-account.cannot-undo')}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer>
        <Button
          variant="outlined"
          label={t('manage-data.delete-account.cancel-button')}
          onClick={onCancel}
        />
        <Button
          label={t('manage-data.delete-account.continue-button')}
          loading={pending}
          onClick={onContinue}
        />
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
