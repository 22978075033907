import { useCallback, useState } from 'react';

import { showDefaultErrorMessage } from '@app/core/error/show-error-message';
import { ApiError } from '@app/core/error/api-error';
import { ErrorCodeEnum } from '@app/config/error-config';
import { catchApiError } from '@app/core/error/catch-api-error';
import { registrationService } from '@app/core/service/registration.service';

import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';
import { EmailOTPProcessController } from '@app/feature/email-otp-process/email-otp-process.controller';
import { receiveResponseFromHttpOrWebsocketPromise } from '@app/core/client/receive-response-from-http-or-websocket.promise';
import { JWT_TOKEN_WST } from '@app/config/api-routes.const';

import { RegistrationMethodProps } from '../../registration-method.props';

interface EmailRegistrationControllerProps extends RegistrationMethodProps {
  onBack?: () => void;
}

export const EmailRegistrationController = ({
  body,
  onRegister,
  onEmailAlreadyTaken,
  onBack = () => {},
}: EmailRegistrationControllerProps): JSX.Element => {
  const [pseudo, setPseudo] = useState('');
  const [emailAddress, setEmailAddress] = useState(body.emailAddress);

  const handleStart = useCallback(
    async (payload: EmailOtpProcessStartRequestModel) => {
      setEmailAddress(payload.emailAddress);

      try {
        const [startPromise] = registrationService.startEmail({
          emailAddress: payload.emailAddress,
          firstName: body.firstName,
          lastName: body.lastName,
        });

        const startRegistrationResponse = await startPromise;
        setPseudo(startRegistrationResponse.pseudo);
      } catch (error) {
        catchApiError(error, (apiError: ApiError) => {
          switch (apiError.code) {
            case ErrorCodeEnum.EMAIL_CONFLICT:
            case ErrorCodeEnum.EMAIL_ALREADY_REGISTERED:
              onEmailAlreadyTaken(payload.emailAddress);
              break;

            default:
              showDefaultErrorMessage();
          }

          throw apiError;
        });
      }
    },
    [body.firstName, body.lastName, onEmailAlreadyTaken]
  );

  const handleVerify = useCallback(
    async (payload: EmailOtpProcessVerificationRequestModel) => {
      const [verifyPromise] = registrationService.verifyEmail({
        pseudo,
        challenge: payload.challenge,
        ...body,
        emailAddress,
      });

      const { jwt } = await receiveResponseFromHttpOrWebsocketPromise(
        verifyPromise,
        JWT_TOKEN_WST
      );
      await onRegister(jwt, pseudo, emailAddress);
    },
    [body, emailAddress, pseudo, onRegister]
  );

  return (
    <EmailOTPProcessController
      emailAddress={emailAddress}
      translationKey="registration.email"
      onRequestOTP={handleStart}
      onRequestAgainOTP={handleStart}
      onValidateOTP={handleVerify}
      onCancel={onBack}
    />
  );
};
