import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Logger } from '@app/core/logger/logger';
import {
  getFromLocalStorage,
  removeInLocalStorage,
  setInLocalStorage,
} from '@app/core/storage/local-storage';
import { Overlay } from '@app/shared/overlay/overlay';
import { Spinner } from '@app/shared/spinner/spinner';
import { LOCALE_KEYS } from '@app/core/locale/locale';
import i18n from '@app/core/locale/i18n';
import { configurationService } from '@app/core/configuration/configuration.service';

import { PrivateAccessView } from './private-access.view';
import { PrivateAccessLoginForm } from './model/private-access-login-form';
import { privateAccessService } from './service/private-access.service';

import en from './locale/en.json';
import fr from './locale/fr.json';

i18n
  .addResourceBundle(LOCALE_KEYS.en, 'translation', en, true)
  .addResourceBundle(LOCALE_KEYS.fr, 'translation', fr, true);

export const PRIVATE_ACCESS_TOKEN_LSK = 'private-access-token';

interface PrivateAccessControllerProps {
  children: JSX.Element;
}

export const PrivateAccessController = (
  props: PrivateAccessControllerProps
): JSX.Element => {
  const { children } = props;

  const [pending, setPending] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const { t } = useTranslation();

  const authenticate = useCallback((jwt: string) => {
    setIsAuthenticated(true);
    setInLocalStorage(PRIVATE_ACCESS_TOKEN_LSK, jwt);
  }, []);

  const disconnect = useCallback(() => {
    setIsAuthenticated(false);
    removeInLocalStorage(PRIVATE_ACCESS_TOKEN_LSK);
  }, []);

  useEffect(() => {
    const privateAccessToken = getFromLocalStorage(PRIVATE_ACCESS_TOKEN_LSK);

    if (!privateAccessToken) {
      disconnect();

      setIsReady(true);
      return () => {};
    }

    const [checkPromise, checkAbort] =
      privateAccessService.checkJwt(privateAccessToken);

    checkPromise
      .then(() => {
        authenticate(privateAccessToken);
      })
      .catch(() => {
        disconnect();
      })
      .finally(() => {
        setIsReady(true);
      });

    return () => {
      checkAbort.abort();
    };
  }, [disconnect, authenticate]);

  const handleAuthenticate = useCallback(
    async (payload: PrivateAccessLoginForm) => {
      setPending(true);

      try {
        const [authenticatePromise] =
          privateAccessService.authenticate(payload);
        const demoJwtResponse = await authenticatePromise;

        authenticate(demoJwtResponse.jwt);
      } catch (error) {
        Logger.debug('Private access authentication failed', error);
        toast.warning(t('private-access.authentication-failed'));

        disconnect();
      } finally {
        setPending(false);
        setIsReady(true);
      }
    },
    [t, authenticate, disconnect]
  );

  if (!configurationService.isPrivateAccessEnabled() || isAuthenticated) {
    return children;
  }

  return isReady ? (
    <PrivateAccessView pending={pending} onSubmit={handleAuthenticate} />
  ) : (
    <Overlay>
      <Spinner translationKey="shared.spinner.private-access-authentication" />
    </Overlay>
  );
};
