import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ACCOUNT_PATH } from '@app/config/app-paths.const';
import { Spinner } from '@app/shared/spinner/spinner';
import { Text } from '@app/shared/typography/text/text';
import { ErrorFragment } from '@app/shared/error-fragment/error-fragment';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { CreditCardManager } from '@app/feature/account/view/user-payment-method/credit-card-manager/credit-card-manager';
import { Button } from '@app/shared/button/button';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';

import css from './user-payment-method.module.scss';

interface UserPaymentMethodViewProps {
  listPaymentMethod: PaymentMethodModel[];
  loading: boolean;
  isError: boolean;
  onRefresh: () => void;
  onCreate: () => void;
}

export const UserPaymentMethodView = ({
  listPaymentMethod = [],
  loading,
  isError,
  onRefresh,
  onCreate,
}: UserPaymentMethodViewProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageLayout>
      <BackLink onClick={() => navigate(ACCOUNT_PATH)} />

      <PageLayout.Header>
        <H1>{t('user-payment-method.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        {loading ? (
          <Spinner translationKey="shared.spinner.user-payment-method" />
        ) : isError ? (
          <ErrorFragment onRetry={onRefresh} />
        ) : listPaymentMethod.length === 0 ? (
          <Text>{t('user-payment-method.no-payment-method')}</Text>
        ) : (
          listPaymentMethod.map((paymentMethod) => (
            <CreditCardManager
              key={paymentMethod.iPaymentSourceId}
              {...paymentMethod}
              onChange={onRefresh}
              onDelete={onRefresh}
            />
          ))
        )}
      </PageLayout.Body>

      <PageLayout.Footer>
        <Button
          label={t('user-payment-method.create-button')}
          onClick={onCreate}
        />
      </PageLayout.Footer>
    </PageLayout>
  );
};
