import { useTranslation } from 'react-i18next';

import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';
import { Link } from '@app/shared/typography/link/link';
import { TariffModel } from '@app/core/model/tariff.model';
import { useDrawer } from '@app/shared/drawer/use-drawer';
import { TariffDetailDrawer } from '@app/feature/tariff/component/tariff-detail-drawer/tariff-detail-drawer';

import css from './charge-point-more-tariff.module.scss';

export function ChargePointMoreTariff(props: TariffModel): JSX.Element {
  const { t } = useTranslation();
  const { openDrawer, closeDrawer, drawerState } = useDrawer();

  return (
    <>
      <div className={css.container}>
        <Icon name="information" />
        <Text>{t('charge-point.more-tariff.label')}</Text>
        <Link onClick={openDrawer}>{t('charge-point.more-tariff.link')}</Link>
      </div>

      <TariffDetailDrawer
        state={drawerState}
        onClose={closeDrawer}
        icon="information"
        tariff={props}
      />
    </>
  );
}
