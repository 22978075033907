import { useCallback, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { httpClientService } from '@app/core/client/http/http-client.service';
import { useRedirectToHomeWithState } from '@app/router/redirect/use-redirect-to-home-with-state';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { ApiErrorController } from '@app/feature/api-error/api-error.controller';
import { HttpStatusEnum } from '@app/config/error-config';
import {
  VERIFY_EMAIL_UPDATE_API,
  VERIFY_EMAIL_VALIDATION_API,
} from '@app/config/api-routes.const';

import { ApiError } from './api-error';

interface HttpErrorBoundaryProps {
  children: JSX.Element;
}

export const HttpErrorBoundary = ({
  children,
}: HttpErrorBoundaryProps): JSX.Element => {
  const [isError, setIsError] = useState(false);
  const { isAuthenticated, disconnect } = useAuthentication();
  const { t } = useTranslation();
  const redirectToHomeWithState = useRedirectToHomeWithState();

  const errorHandler = useCallback(
    (error: AxiosError) => {
      const apiError = new ApiError(error);

      if (apiError.isAborted) {
        apiError.intercepts();
        return Promise.reject(apiError);
      }

      switch (apiError.status) {
        case HttpStatusEnum.INTERNAL_SERVER_ERROR:
        case HttpStatusEnum.TOO_MANY_REQUEST:
          setIsError(true);
          apiError.intercepts();
          break;

        case HttpStatusEnum.UNAUTHORIZED:
          if (
            !isAuthenticated ||
            // Specific request that can respond with 401
            error.config?.url === VERIFY_EMAIL_VALIDATION_API ||
            error.config?.url === VERIFY_EMAIL_UPDATE_API
          ) {
            return Promise.reject(apiError);
          }

          apiError.intercepts();
          toast.warning(t('api-error.unauthorized'));
          redirectToHomeWithState();
          disconnect();
          break;

        default:
      }

      return Promise.reject(apiError);
    },
    [isAuthenticated, redirectToHomeWithState, disconnect, t]
  );

  useLayoutEffect(() => {
    const errorInterceptor = httpClientService.axios.interceptors.response.use(
      (response) => response,
      errorHandler
    );

    return () => {
      httpClientService.axios.interceptors.response.eject(errorInterceptor);
    };
  }, [errorHandler]);

  return isError ? <ApiErrorController /> : children;
};
