import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Dialog, DialogProps } from '@app/shared/dialog/dialog';
import { Icon } from '@app/shared/icon/icon';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';

import css from './clear-storage-dialog.module.scss';

interface ClearStorageDialogProps extends DialogProps {
  isAuthenticated: boolean;
  pseudo?: string;
  emailAddress?: string;
  onCopyPseudo: () => void;
  onClear: () => void;
}

export const ClearStorageDialog = (
  props: ClearStorageDialogProps
): JSX.Element => {
  const {
    isAuthenticated,
    pseudo,
    emailAddress,
    onCopyPseudo,
    onClear,
    onClose,
    ...dialogProps
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog {...dialogProps} onClose={onClose}>
      <DialogLayout>
        <DialogLayout.Body className={css.body}>
          <Icon name="trash" />

          <H1>{t('legal-text.clear-storage.title')}</H1>

          <Text>
            {t('legal-text.clear-storage.consequence-label')}

            <ul>
              <li>{t('legal-text.clear-storage.user-experience')}</li>
              <li>{t('legal-text.clear-storage.clear-auth-credentials')}</li>

              {isAuthenticated && (
                <li>{t('legal-text.clear-storage.remember-credentials')}</li>
              )}
            </ul>
          </Text>

          {pseudo && (
            <button className={css.customerId} onClick={onCopyPseudo}>
              <Text bold color="p1">
                {t('legal-text.clear-storage.pseudo')}
              </Text>
              <Text color="p1">{pseudo}</Text>
              <Icon name="copy" color="primary" />
            </button>
          )}

          {emailAddress && (
            <div className={css.customerId}>
              <Text bold color="p1">
                {t('legal-text.clear-storage.email-address')}
              </Text>
              <Text color="p1">{emailAddress}</Text>
            </div>
          )}
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button
            variant="outlined"
            label={t('legal-text.clear-storage.cancel')}
            onClick={onClose}
          />
          <Button
            label={t('legal-text.clear-storage.confirm')}
            onClick={onClear}
          />
        </DialogLayout.Footer>
      </DialogLayout>
    </Dialog>
  );
};
