import { useTranslation } from 'react-i18next';
import { TariffModel } from '@app/core/model/tariff.model';
import { Text } from '@app/shared/typography/text/text';

import css from './tariff-list.module.scss';
import { TariffListItem } from './tariff-list-item';

type Props = {
  tariff: TariffModel;
  vatRate?: number;
};

export function TariffList(props: Props): JSX.Element {
  const { tariff, vatRate } = props;
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      {tariff.elements.map((tariffElement) => (
        <TariffListItem
          key={`${tariffElement.priceComponents.perKwhTTC}-${
            tariffElement.priceComponents.perTimeChargingHourTTC ?? 0
          }-${tariffElement.priceComponents.flatTTC ?? 0}`}
          tariffElement={tariffElement}
        />
      ))}

      {vatRate && (
        <Text className={css.vat}>
          {t('quote.price-section.vat', { vat: vatRate })}
        </Text>
      )}
    </div>
  );
}
