import { useTranslation } from 'react-i18next';

import { Dialog, DialogProps } from '@app/shared/dialog/dialog';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { Link } from '@app/shared/typography/link/link';
import { Text } from '@app/shared/typography/text/text';

import css from './charge-point-tarification-dialog.module.scss';

interface ChargePointTarificationDialogProps extends DialogProps {
  onClick: () => void;
}

export const ChargePointTarificationDialog = (
  props: ChargePointTarificationDialogProps
): JSX.Element => {
  const { onClick, ...dialogProps } = props;
  const { t } = useTranslation();

  return (
    <Dialog {...dialogProps}>
      <DialogLayout>
        <DialogLayout.Body className={css.body}>
          <Text>
            {t('charge-point.tarification-dialog.p1')}{' '}
            <Link onClick={onClick} color="p1">
              {t('charge-point.tarification-dialog.learn-more-link')}
            </Link>
          </Text>
        </DialogLayout.Body>
      </DialogLayout>
    </Dialog>
  );
};
