import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';

import { setInLocalStorage } from '@app/core/storage/local-storage';
import {
  EMAIL_LSK,
  LAST_AUTHENTICATION_METHOD_LSK,
  PSEUDO_LSK,
} from '@app/config/localstorage-keys.const';
import { RegistrationRequestModel } from '@app/core/model/registration.model';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { FIRST_LOGIN_PATH } from '@app/config/app-paths.const';
import { AuthenticationMethodEnum } from '@app/core/model/enum/authentication-method.enum';

import { WebauthnRegistrationController } from './view/webauthn-registration/webauthn-registration.controller';
import { EmailRegistrationController } from './view/email-registration/email-registration.controller';
import { RegistrationFormController } from './view/registration-form/registration-form.controller';
import { EmailAlreadyTakenView } from './view/email-already-taken/email-already-taken.view';

enum RegistrationStateEnum {
  REGISTRATION_FORM,
  WEBAUTHN,
  EMAIL,
  EMAIL_ALREADY_TAKEN,
}

interface RegistrationControllerProps {
  onOpenAuthentication?: (emailAddress?: string) => void;
  onBack?: () => void;
}

export const RegistrationController = (
  props: RegistrationControllerProps
): JSX.Element => {
  const { onOpenAuthentication = () => {}, onBack = () => {} } = props;

  const [registrationFormData, setRegistrationFormData] =
    useState<RegistrationRequestModel>();

  const [state, setState] = useState(RegistrationStateEnum.REGISTRATION_FORM);
  const { authenticate } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();

  const handleRedirectToFirstLogin = useCallback(() => {
    const redirectionState = location.state;
    navigate(FIRST_LOGIN_PATH, { replace: true, state: redirectionState });
  }, [navigate, location.state]);

  const handleSwitchToEmail = useCallback(() => {
    setState(RegistrationStateEnum.EMAIL);
  }, []);

  const handleSwitchToWebauthn = useCallback(() => {
    setState(RegistrationStateEnum.WEBAUTHN);
  }, []);

  const handleRegister = useCallback(
    async (
      jwt: string,
      pseudo: string,
      emailAddress: string,
      authenticationMethod: AuthenticationMethodEnum
    ) => {
      authenticate(jwt);

      setInLocalStorage(PSEUDO_LSK, pseudo);
      setInLocalStorage(EMAIL_LSK, emailAddress);
      setInLocalStorage(
        LAST_AUTHENTICATION_METHOD_LSK,
        authenticationMethod.toString()
      );
      handleRedirectToFirstLogin();
    },
    [authenticate, handleRedirectToFirstLogin]
  );

  const handleEmailRegistration = useCallback(
    (jwt: string, pseudo: string, emailAddress: string): Promise<void> => {
      return handleRegister(
        jwt,
        pseudo,
        emailAddress,
        AuthenticationMethodEnum.EMAIL
      );
    },
    [handleRegister]
  );

  const handleWebauthnRegistration = useCallback(
    (jwt: string, pseudo: string, emailAddress: string): Promise<void> => {
      return handleRegister(
        jwt,
        pseudo,
        emailAddress,
        AuthenticationMethodEnum.WEBAUTHN
      );
    },
    [handleRegister]
  );

  const handleSubmitFormView = useCallback(
    (payload: RegistrationRequestModel) => {
      setRegistrationFormData(payload);
      setState(
        browserSupportsWebAuthn()
          ? RegistrationStateEnum.WEBAUTHN
          : RegistrationStateEnum.EMAIL
      );
    },
    []
  );

  const handleShowForm = useCallback(() => {
    setState(RegistrationStateEnum.REGISTRATION_FORM);
  }, []);

  const handleEmailAlreadyTaken = useCallback(
    (emailAddress?: string) => {
      if (registrationFormData && emailAddress) {
        setRegistrationFormData({
          ...registrationFormData,
          emailAddress,
        });
      }
      setState(RegistrationStateEnum.EMAIL_ALREADY_TAKEN);
    },
    [registrationFormData]
  );

  const handleOpenAuthentication = useCallback(() => {
    onOpenAuthentication(registrationFormData?.emailAddress);
  }, [registrationFormData?.emailAddress, onOpenAuthentication]);

  if (!registrationFormData) {
    return (
      <RegistrationFormController
        payload={registrationFormData}
        onSubmit={handleSubmitFormView}
        onBack={onBack}
      />
    );
  }

  switch (state) {
    case RegistrationStateEnum.EMAIL:
      return (
        <EmailRegistrationController
          body={registrationFormData}
          onRegister={handleEmailRegistration}
          onBack={handleSwitchToWebauthn}
          onEmailAlreadyTaken={handleEmailAlreadyTaken}
        />
      );

    case RegistrationStateEnum.WEBAUTHN:
      return (
        <WebauthnRegistrationController
          body={registrationFormData}
          onSwitchToEmailRegistration={handleSwitchToEmail}
          onRegister={handleWebauthnRegistration}
          onBack={handleShowForm}
          onEmailAlreadyTaken={handleEmailAlreadyTaken}
        />
      );

    case RegistrationStateEnum.EMAIL_ALREADY_TAKEN:
      return (
        <EmailAlreadyTakenView
          emailAddress={registrationFormData.emailAddress}
          onGoToAuthentication={handleOpenAuthentication}
          onGoToRegistration={handleShowForm}
        />
      );

    default:
      return (
        <RegistrationFormController
          payload={registrationFormData}
          onSubmit={handleSubmitFormView}
          onBack={onBack}
        />
      );
  }
};
