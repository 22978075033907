import { useCallback, useState } from 'react';

import { useDrawer } from '@app/shared/drawer/use-drawer';
import { DrawerStateEnum } from '@app/shared/drawer/drawer';

interface FaqDrawerHookReturn {
  selectedItem?: string;
  drawerState: DrawerStateEnum;
  closeDrawer: () => void;
  openDrawer: (id: string) => void;
}

export const useFaqDrawer = (): FaqDrawerHookReturn => {
  const [selectedItem, setSelectedItem] = useState<string>();

  const { drawerState, openDrawer, closeDrawer } = useDrawer();

  const handleOpen = useCallback(
    (id: string) => {
      setSelectedItem(id);
      openDrawer();
    },
    [openDrawer]
  );

  return {
    selectedItem,
    drawerState,
    closeDrawer,
    openDrawer: handleOpen,
  };
};
