import { useTranslation } from 'react-i18next';

import { Counter } from '@app/shared/counter/counter';
import { Text } from '@app/shared/typography/text/text';

import css from './last-data-received.module.scss';

interface LastDataReceivedViewProps {
  lastDataReceived?: Date;
  estimatedPrice?: number | null;
  energyDelivered?: number;
}

export const LastDataReceivedView = ({
  lastDataReceived,
  estimatedPrice,
  energyDelivered,
}: LastDataReceivedViewProps): JSX.Element | null => {
  const { t } = useTranslation();

  if ((!energyDelivered && !estimatedPrice) || !lastDataReceived) {
    return null;
  }

  return (
    <div className={css.container}>
      <Text color="tertiary">
        {t('charging.last-data-received.title', { lastDataReceived })}
      </Text>
      <div className={css.counterContainer}>
        {Boolean(estimatedPrice) && (
          <Counter
            icon="euro"
            className={css.tag}
            value={t('charging.last-data-received.estimated-cost', {
              estimation: estimatedPrice,
            })}
          />
        )}
        {Boolean(energyDelivered) && (
          <Counter
            className={css.tag}
            icon="light"
            value={t('charging.last-data-received.energy-delivered', {
              energyDelivered,
            })}
          />
        )}
      </div>
    </div>
  );
};
