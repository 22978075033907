import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@app/shared/card/card';
import { Text } from '@app/shared/typography/text/text';
import { PaymentSchemeLogo } from '@app/shared/payment-scheme-logo/payment-scheme-logo';
import { PaymentSchemeEnum } from '@app/core/model/enum/payment-scheme.enum';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';

import css from './credit-card-information.module.scss';

export interface CreditCardInformationProps
  extends Pick<
    PaymentMethodModel,
    'productType' | 'scheme' | 'last4' | 'expiryMonth' | 'expiryYear'
  > {}

export function CreditCardInformation(
  props: CreditCardInformationProps
): JSX.Element {
  const { t } = useTranslation();
  const { productType, scheme, last4, expiryMonth, expiryYear } = props;

  const securedBy = useMemo(() => {
    if (productType) {
      return productType;
    }

    switch (scheme) {
      case PaymentSchemeEnum.APPLE_PAY:
        return t('shared.credit-card-information.secured-by.apple');
      case PaymentSchemeEnum.GOOGLE_PAY:
        return t('shared.credit-card-information.secured-by.google');
      default:
        return t('shared.credit-card-information.secured-by.checkout');
    }
  }, [t, scheme, productType]);

  return (
    <Card background="colored" className={css.creditCard}>
      <div className={css.top}>
        {last4 && (
          <Text>{t('shared.credit-card-information.last4', { last4 })}</Text>
        )}
        <Text className={css.names}>{securedBy}</Text>
      </div>
      <div className={css.bottom}>
        <div className={css.bottomLeft}>
          <div className={css.bottomLeftItem}>
            {expiryMonth && expiryYear && (
              <>
                <Text>{t('shared.credit-card-information.expiration')}</Text>
                <Text bold>
                  {t('shared.credit-card-information.expiration-date', {
                    month: expiryMonth,
                    year: expiryYear,
                  })}
                </Text>
              </>
            )}
          </div>
          {last4 && (
            <div className={css.bottomLeftItem}>
              <Text>{t('shared.credit-card-information.secret-code')}</Text>
              <Text bold>
                {t('shared.credit-card-information.secret-code-hidden')}
              </Text>
            </div>
          )}
        </div>
        <PaymentSchemeLogo
          className={css.scheme}
          paymentScheme={scheme}
          variant="light"
        />
      </div>
    </Card>
  );
}
