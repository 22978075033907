import { useTranslation } from 'react-i18next';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';

interface WelcomeProps {
  name?: string;
}

export const Welcome = ({ name = '' }: WelcomeProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Text fontSize="large">{t('dashboard.welcome.label')},</Text>
      {name && <H1>{name}</H1>}
    </div>
  );
};
