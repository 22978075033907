import { useEffect, useMemo, useState } from 'react';

import { DrawerStateEnum } from '@app/shared/drawer/drawer';
import {
  isSuggestPseudoCheckingExpired,
  postponeCheckToTomorrow,
} from '@app/feature/check-pseudo/check-pseudo-utils';
import { CheckPseudoView } from '@app/feature/check-pseudo/check-pseudo.view';
import { useUser } from '@app/core/context-providers/user-context/use-user';

enum CheckPseudoStateEnum {
  NO_NEED_TO_CHECK,
  SUGGEST_CHECKING,
  CHECK_PSEUDO,
}

export const CheckPseudoController = (): JSX.Element => {
  const [checkPseudoState, setCheckPseudoState] =
    useState<CheckPseudoStateEnum>(CheckPseudoStateEnum.NO_NEED_TO_CHECK);

  const { user } = useUser();

  const drawerState: DrawerStateEnum = useMemo(() => {
    switch (checkPseudoState) {
      case CheckPseudoStateEnum.SUGGEST_CHECKING:
        return DrawerStateEnum.SMALL;

      case CheckPseudoStateEnum.CHECK_PSEUDO:
        return DrawerStateEnum.OPEN;

      default:
        return DrawerStateEnum.CLOSE;
    }
  }, [checkPseudoState]);

  const handleClose = () => {
    postponeCheckToTomorrow();
    setCheckPseudoState(CheckPseudoStateEnum.NO_NEED_TO_CHECK);
  };

  const handleCheck = () => {
    setCheckPseudoState(CheckPseudoStateEnum.CHECK_PSEUDO);
  };

  useEffect(() => {
    // Delay appearance to catch the eye
    const timeoutId = setTimeout(() => {
      if (
        isSuggestPseudoCheckingExpired() &&
        user?.preferences?.enablePseudoCheck
      ) {
        setCheckPseudoState(CheckPseudoStateEnum.SUGGEST_CHECKING);
      }
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [user?.preferences?.enablePseudoCheck]);

  return (
    <CheckPseudoView
      drawerState={drawerState}
      onCheckPseudo={handleCheck}
      onClose={handleClose}
    />
  );
};
