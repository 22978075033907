import { useCallback, useState } from 'react';

import { Spinner } from '@app/shared/spinner/spinner';
import { CreditCardList } from '@app/shared/credit-card-list/credit-card-list';
import { Divider } from '@app/shared/divider/divider';
import { PaymentMethodModel } from '@app/core/model/payment-method.model';

interface ListUserPaymentMethodViewProps {
  loading?: boolean;
  paymentMethods?: PaymentMethodModel[];
  onSelect?: (paymentMethod: PaymentMethodModel) => Promise<void>;
}

export const ListUserPaymentMethodView = ({
  loading = false,
  paymentMethods = [],
  onSelect = async () => {},
}: ListUserPaymentMethodViewProps): JSX.Element => {
  const [itemLoading, setItemLoading] = useState<string | null>(null);

  const isPaymentMethodLoading = useCallback(
    (paymentMethod: PaymentMethodModel): boolean =>
      itemLoading === paymentMethod.iPaymentSourceId,
    [itemLoading]
  );

  const handleSelect = useCallback(
    async (paymentMethod: PaymentMethodModel): Promise<void> => {
      setItemLoading(paymentMethod.iPaymentSourceId);
      await onSelect(paymentMethod);
      setItemLoading(null);
    },
    [onSelect]
  );

  return (
    <>
      {loading && (
        <Spinner translationKey="shared.spinner.user-payment-method" />
      )}

      {paymentMethods.length > 0 && (
        <>
          <CreditCardList
            paymentMethods={paymentMethods}
            onSelect={handleSelect}
            isLoading={isPaymentMethodLoading}
          />
          <Divider />
        </>
      )}
    </>
  );
};
