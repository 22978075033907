import classNames from 'classnames';
import { HTMLProps, useMemo } from 'react';

import { Logger } from '@app/core/logger/logger';

import css from './scroll-indicator.module.scss';

const DEFAULT_NUMBER_OF_INDICATORS = 3;

interface IndicatorProps extends HTMLProps<HTMLDivElement> {
  active: boolean;
}

export const Indicator = (props: IndicatorProps): JSX.Element => {
  const { active, className, ...divProps } = props;

  return (
    <div
      className={classNames(css.step, active && css.active, className)}
      {...divProps}
    />
  );
};

interface ScrollIndicatorProps {
  scrollPosition: number;
  scrollMax: number;
  numberOfIndicators?: number;
}

export const ScrollIndicator = (props: ScrollIndicatorProps): JSX.Element => {
  const {
    numberOfIndicators = DEFAULT_NUMBER_OF_INDICATORS,
    scrollMax,
    scrollPosition,
  } = props;

  const safeNumberOfIndicators = useMemo(() => {
    if (Number.isInteger(numberOfIndicators) && numberOfIndicators <= 0) {
      Logger.error(
        'numberOfIndicators props must be a positive integer. Received: ',
        numberOfIndicators
      );
      return DEFAULT_NUMBER_OF_INDICATORS;
    }
    return numberOfIndicators;
  }, [numberOfIndicators]);

  const indicatorIterator = useMemo(
    () => Array.from(Array(safeNumberOfIndicators)),
    [safeNumberOfIndicators]
  );

  const boundaries: number[] = useMemo(() => {
    return Array.from(Array(safeNumberOfIndicators))
      .map((_, index) => {
        return Math.round((scrollMax * index) / safeNumberOfIndicators);
      })
      .reverse();
  }, [scrollMax, safeNumberOfIndicators]);

  const currentStep: number = useMemo(() => {
    let index = 0;
    for (
      ;
      index < boundaries.length && scrollPosition < boundaries[index];
      index += 1 // eslint-disable-next-line no-empty
    ) {}

    return safeNumberOfIndicators - index - 1;
  }, [safeNumberOfIndicators, scrollPosition, boundaries]);

  return (
    <>
      {indicatorIterator.map((_, index) => (
        <Indicator key={index} active={currentStep === index} />
      ))}
    </>
  );
};
