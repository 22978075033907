import { useTranslation } from 'react-i18next';

import { Text } from '@app/shared/typography/text/text';
import { H3 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Dialog } from '@app/shared/dialog/dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { CustomerInformationsModel } from '@app/core/model/customer-information.model';
import { TariffModel } from '@app/core/model/tariff.model';

import { PriceCard } from '../price-card/price-card';
import { TariffList } from '../../../tariff/component/tariff-list/tariff-list';
import css from './tariff-section.module.scss';

type Props = {
  tariff?: TariffModel;
  isTariffComplex?: boolean;
  user: CustomerInformationsModel;
  vatRate: number;
  isPrecaptureAccepted: boolean;
};

export function TariffSection(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    tariff,
    isTariffComplex = false,
    user,
    vatRate,
    isPrecaptureAccepted,
  } = props;
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const pricing = tariff?.elements?.at(0)?.priceComponents;

  return (
    <>
      <div>
        <H3>{t('quote.price-section.title')}</H3>
        {tariff && isTariffComplex && (
          <TariffList tariff={tariff} vatRate={vatRate} />
        )}

        {pricing && !isTariffComplex && (
          <PriceCard pricing={pricing} user={user} vatRate={vatRate} />
        )}
        {!isPrecaptureAccepted && (
          <Text
            fontSize="xs"
            color="secondary"
            className={css.warning}
            onClick={openDialog}>
            <Icon name="information" />
            {t('quote.preauthorization-warning')}
          </Text>
        )}
      </div>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogLayout>
          <DialogLayout.Body>
            <Icon
              color="primary"
              name="information"
              className={css.dialogIcon}
            />

            <Text className={css.paragraph}>
              {t('quote.price-section.dialog.p1')}
            </Text>
            <Text className={css.paragraph}>
              {t('quote.price-section.dialog.p2')}
            </Text>
            <Text className={css.paragraph}>
              {t('quote.price-section.dialog.p3')}
            </Text>
          </DialogLayout.Body>
        </DialogLayout>
      </Dialog>
    </>
  );
}
