import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ACCOUNT_PATH } from '@app/config/app-paths.const';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { ItemMenuInterface } from '@app/shared/item-menu/item-menu.interface';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { CallSupportButton } from '@app/shared/call-support/call-support-button';

import css from './help.module.scss';

interface HelpViewProps {
  items: ItemMenuInterface[];
}

export const HelpView = ({ items }: HelpViewProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout>
      <BackLink onClick={() => navigate(ACCOUNT_PATH)} />

      <PageLayout.Header>
        <H1>{t('help.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <ListItemMenu className={css.list}>
          {items.map((item) => (
            <ItemMenu
              key={item.label}
              bold
              label={t(item.label)}
              onClick={item.onClick}
              disabled={item.disabled}
            />
          ))}
        </ListItemMenu>

        <CallSupportButton />
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
