import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALE_LSK } from '@app/config/localstorage-keys.const';

import en from './en.json';
import fr from './fr.json';
import { DEFAULT_NAMESPACE, LOCALE_KEYS } from './locale';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: DEFAULT_NAMESPACE,
    resources: {
      [LOCALE_KEYS.en]: {
        translation: en,
      },
      [LOCALE_KEYS.fr]: {
        translation: fr,
      },
    },
    fallbackLng: [LOCALE_KEYS.fr, LOCALE_KEYS.en],
    interpolation: {
      escapeValue: false, // react already safes from xss
      defaultVariables: { errorCode: '0003' },
    },
    detection: {
      lookupLocalStorage: LOCALE_LSK,
      caches: [],
    },
  });

function padTo2(value: number): string {
  return value.toLocaleString(i18n.language, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

i18n.services.formatter?.add('time', (seconds: number) => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = Math.floor(seconds % 60);
  return `${padTo2(hour)}:${padTo2(minute)}:${padTo2(second)}`;
});

i18n.services.formatter?.add('time-short', (seconds: number) => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = Math.floor((seconds % 3600) % 60);

  const hourString = `${hour}h`;
  const minuteString = minute > 0 ? `${minute}m` : '';
  const secondString = second > 0 ? `${second}s` : '';

  if (hour > 0) {
    return `${hourString} ${minuteString}`;
  }

  return `${minuteString} ${secondString}`;
});

export default i18n;
