import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Spinner } from '@app/shared/spinner/spinner';
import { OrderModel } from '@app/core/model/order.model';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { InvoiceHeader } from '@app/shared/invoice/invoice-header/invoice-header';
import { InvoiceDetails } from '@app/shared/invoice/invoice-details/invoice-details';
import { InvoiceFooter } from '@app/shared/invoice/invoice-footer/invoice.footer';
import { Button } from '@app/shared/button/button';
import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { InvoiceTariff } from '@app/shared/invoice/invoice-tariff/invoice-tariff';

interface ChargeSummaryViewProps {
  autoSendInvoiceByMail?: boolean;
  isEmailAddressVerified?: boolean;
  orderOrInvoice?: OrderModel | InvoiceModel;
}

export const ChargeSummaryView = (
  props: ChargeSummaryViewProps
): JSX.Element => {
  const {
    orderOrInvoice,
    autoSendInvoiceByMail = false,
    isEmailAddressVerified = false,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout>
      {orderOrInvoice ? (
        <>
          <PageLayout.Body>
            <InvoiceHeader
              pseudo={orderOrInvoice.customer.pseudo}
              totalCost={orderOrInvoice.cost.totalTTC}
              startedAt={orderOrInvoice.startedAt}
              chargePointName={orderOrInvoice.chargePointName}
              duration={orderOrInvoice.duration}
              proforma={orderOrInvoice.proforma}
            />
            <InvoiceDetails
              cost={orderOrInvoice.cost}
              energyDelivered={orderOrInvoice.energyDelivered}
            />
            <InvoiceTariff
              orderId={orderOrInvoice.orderId}
              {...orderOrInvoice.pricing}
            />
          </PageLayout.Body>

          <PageLayout.Footer>
            <InvoiceFooter
              proforma={orderOrInvoice.proforma}
              invoiceId={orderOrInvoice.invoiceId}
              pseudo={orderOrInvoice.customer.pseudo}
              createdAt={orderOrInvoice.createdAt}
              autoSendInvoiceByMail={autoSendInvoiceByMail}
              isEmailAddressVerified={isEmailAddressVerified}
            />
            <Button
              variant="outlined"
              label={t('charge-summary.dashboard-button')}
              onClick={() => navigate(DASHBOARD_PATH)}
            />
          </PageLayout.Footer>
        </>
      ) : (
        <PageLayout.Body>
          <Spinner />
        </PageLayout.Body>
      )}
    </PageLayout>
  );
};
