import { useTranslation } from 'react-i18next';

import { H2 } from '@app/shared/typography/heading/heading';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Overlay } from '@app/shared/overlay/overlay';
import { Spinner } from '@app/shared/spinner/spinner';
import { Fade } from '@app/shared/fade/fade';
import { UserModel } from '@app/core/model/user.model';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { Text } from '@app/shared/typography/text/text';

import { ChargeInProgressCard } from './component/charge-in-progress-card/charge-in-progress-card';
import { ChargeInProgressInvoiceCard } from './component/charge-in-progress-invoice-card/charge-in-progress-invoice-card';
import { LastInvoiceCard } from './component/last-invoice-card/last-invoice-card';
import { LastChargesCard } from './component/last-charges-card/last-charges-card';
import { NewsCard } from './component/news-card/news-card';
import { Welcome } from './component/welcome/welcome';

import css from './dashboard.module.scss';
import { RechargeCard } from './component/recharge-card/recharge-card';

interface DashboardViewProps {
  isCharging: boolean;
  chargeStartedAt?: Date;
  totalTTC?: number;
  chargePointName?: string;
  lastInvoice: InvoiceModel | null;
  consumptions?: number[];
  user?: UserModel;
  loading: boolean;
  onNavigateToInvoiceSummary: (invoiceId: string) => void;
  onNavigateToInvoiceDashboard: () => void;
  onNavigateToCharge: () => void;
}

export const DashboardView = ({
  isCharging,
  chargeStartedAt,
  totalTTC,
  chargePointName,
  lastInvoice,
  consumptions = [],
  user,
  loading = false,
  onNavigateToInvoiceSummary,
  onNavigateToInvoiceDashboard,
  onNavigateToCharge,
}: DashboardViewProps): JSX.Element => {
  const { t } = useTranslation();

  const handleClickOnRechargeCard = () => {
    if (isCharging) {
      return onNavigateToCharge();
    }

    if (lastInvoice?.invoiceId && !lastInvoice?.proforma) {
      return onNavigateToInvoiceSummary(lastInvoice.invoiceId);
    }

    return onNavigateToInvoiceDashboard();
  };

  return (
    <>
      <PageLayout className={css.page}>
        <PageLayout.UserAvatar />

        <PageLayout.Header>
          <Welcome name={user?.firstName} />
        </PageLayout.Header>

        <PageLayout.Body className={css.page}>
          <div>
            {user?.pseudo && (
              <Text color="p1" bold className={css.pseudo}>
                {t('dashboard.customer-id', { customerId: user?.pseudo })}
              </Text>
            )}
            <RechargeCard
              chargePointName={chargePointName}
              startedAt={isCharging ? chargeStartedAt : lastInvoice?.startedAt}
              isCharging={isCharging}
              onClick={handleClickOnRechargeCard}
            />
          </div>

          <H2>{t('dashboard.service-section')}</H2>

          <div className={css.serviceCards}>
            {isCharging && chargeStartedAt ? (
              <>
                <ChargeInProgressCard
                  className={css.serviceItem}
                  startedAt={chargeStartedAt}
                  location={chargePointName}
                  estimatedPrice={totalTTC}
                />

                <ChargeInProgressInvoiceCard className={css.serviceItem} />
              </>
            ) : (
              <>
                <LastInvoiceCard
                  className={css.serviceItem}
                  invoice={lastInvoice}
                  onClick={handleClickOnRechargeCard}
                />
                <LastChargesCard
                  className={css.serviceItem}
                  consumptions={consumptions}
                  onClick={onNavigateToInvoiceDashboard}
                />
              </>
            )}
          </div>

          <NewsCard className={css.newsCard} />
        </PageLayout.Body>

        <PageLayout.AppbarFooter />
      </PageLayout>

      <Fade show={loading}>
        <Overlay blur>
          <Spinner size="md" translationKey="shared.spinner.user" />
        </Overlay>
      </Fade>
    </>
  );
};
