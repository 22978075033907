import { useTranslation } from 'react-i18next';

import { H2 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import CameraErrorSvg from '@app/asset/camera-error.svg';

import css from './camera-error.module.scss';

export const CameraError = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <H2>{t('scan.error.title')}</H2>
      <CameraErrorSvg className={css.img} />
      <Text className={css.text}>{t('scan.error.description')}</Text>
      <Text className={css.text}>{t('scan.error.fallback')}</Text>
    </>
  );
};
