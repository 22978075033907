import { HTMLProps } from 'react';
import { SpeedChart } from '@app/feature/charging/component/charge-chart/speed-chart';
import { EnergyDeliveredChart } from '@app/feature/charging/component/charge-chart/energy-delivered-chart';

import { Point } from './model/point';

import 'chartist/dist/index.css';
import './chartist.style.scss';

interface ChargeChartViewProps extends HTMLProps<HTMLDivElement> {
  speedCharge: Point[];
  totalKWDelivered: Point[];
}

export const ChargeChartView = ({
  speedCharge,
  totalKWDelivered,
  ...divProps
}: ChargeChartViewProps): JSX.Element => {
  return (
    <div {...divProps}>
      <EnergyDeliveredChart points={totalKWDelivered} />
      <SpeedChart points={speedCharge} />
    </div>
  );
};
