import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { WithUserProps } from '@app/router/guard/with-user.guard';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';

import { AccountView } from './account.view';

export const AccountController = (props: WithUserProps): JSX.Element => {
  const { user, isWebauthnEnabled } = props;
  const { disconnect } = useAuthentication();
  const { isChargeStarted } = useCharge();

  return (
    <AccountView
      firstName={user.firstName}
      lastName={user.lastName}
      email={user.emailAddress}
      isWebauthnEnabled={isWebauthnEnabled}
      isEmailVerified={user.isEmailAddressVerified}
      pseudo={user.pseudo}
      onLogout={disconnect}
      isChargeStarted={isChargeStarted}
    />
  );
};
