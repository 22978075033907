import classNames from 'classnames';
import { H1, HeadingProps } from '@app/shared/typography/heading/heading';

import css from './step-title.module.scss';

interface StepTitleProps extends HeadingProps {
  step: number;
}

export const StepTitle = (props: StepTitleProps): JSX.Element => {
  const { step, className, children, ...headingProps } = props;

  return (
    <H1 className={classNames(className, css.title)} {...headingProps}>
      <span className={css.step}>{step}</span>
      {children}
    </H1>
  );
};
