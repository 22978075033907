import { toInt } from 'radash';
import { Logger } from '../logger/logger';

const PRODUCTION_ENV = 'production';

interface ThemeIdModel {
  theme1: string;
  theme2: string;
}

class ConfigurationService {
  private static instance: ConfigurationService;

  private constructor() {
    const configuration = {
      isProduction: this.isProduction(),
      api: this.getApi(),
      ws: this.getWebsocket(),
      defaultCpoId: this.getDefaultCpoId(),
      wsDefaultTimeout: this.getWsDefaultTimeout(),
      isPrivateAccessEnabled: this.isPrivateAccessEnabled(),
      version: this.getVersion(),
      logLevel: Logger.getLogLevel(),
      supportPhoneNumber: this.getSupportPhoneNumber(),
      themeId: this.getThemeId(),
    };
    Logger.debug('configuration loaded', configuration);
  }

  static getInstance(): ConfigurationService {
    if (!ConfigurationService.instance) {
      ConfigurationService.instance = new ConfigurationService();
    }

    return ConfigurationService.instance;
  }

  getApi(): string {
    return process.env.DELMONICOS_API ?? '';
  }

  getWebsocket(): string {
    return process.env.DELMONICOS_WS ?? '';
  }

  getDefaultCpoId(): string {
    return process.env.DELMONICOS_DEFAULT_CPO_ID ?? '';
  }

  getWsDefaultTimeout(): number {
    return toInt(process.env.DELMONICOS_WS_DEFAULT_TIMEOUT, 20);
  }

  isPrivateAccessEnabled(): boolean {
    return process.env.DELMONICOS_ENABLE_PRIVATE_ACCESS === 'true';
  }

  isProduction(): boolean {
    return process.env.NODE_ENV === PRODUCTION_ENV;
  }

  getVersion(): string {
    return process.env.VERSION ?? '';
  }

  getSupportPhoneNumber(): string {
    if (!process.env.DELMONICOS_SUPPORT_PHONE_NUMBER) {
      return '+33 6 00 00 00 00';
    }
    return process.env.DELMONICOS_SUPPORT_PHONE_NUMBER;
  }

  getThemeId(): ThemeIdModel {
    return {
      theme1: process.env.DELMONICOS_THEME_ID_UEM ?? '',
      theme2: process.env.DELMONICOS_THEME_ID_FORD ?? '',
    };
  }
}

export const configurationService = ConfigurationService.getInstance();
