import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Counter } from '@app/shared/counter/counter';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { FACEBOOK_URL } from '@app/config/external-url';
import { Text } from '@app/shared/typography/text/text';
import { Link } from '@app/shared/typography/link/link';
import { OuterLink } from '@app/shared/outer-link/outer-link';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Icon } from '@app/shared/icon/icon';

import css from './charge-overview.module.scss';

interface ChargeOverviewProps {
  duration?: number;
  estimatedPrice?: number;
  energyDelivered?: number;
  onGoToDashboard: () => void;
  onGoToChargeSummary: () => void;
}

export const ChargeOverview = ({
  duration = 0,
  estimatedPrice = 0,
  energyDelivered = 0,
  onGoToDashboard,
  onGoToChargeSummary,
}: ChargeOverviewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout colored className={css.page}>
      <div>
        <Link onClick={onGoToDashboard} startIcon={<Icon name="home" />}>
          {t('charge-overview.go-to-dashboard-button')}
        </Link>
      </div>

      <PageLayout.Header className={css.header}>
        <H1 color="light">{t('charge-overview.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <div className={css.data}>
          <div className={css.car} />

          {duration > 0 && (
            <div className={css.counterContainer}>
              <Text color="light">{t('charge-overview.time.label')}</Text>
              <Counter
                className={css.tag}
                icon="clock-fast-forward"
                value={t('charge-overview.time.value', {
                  duration,
                })}
              />
            </div>
          )}

          <div className={css.counterList}>
            {estimatedPrice > 0 && (
              <div className={css.counterContainer}>
                <Text color="light">{t('charge-overview.cost.label')}</Text>
                <Counter
                  className={css.tag}
                  icon="euro"
                  value={t('charge-overview.cost.value', {
                    amount: estimatedPrice,
                  })}
                />
              </div>
            )}

            {energyDelivered > 0 && (
              <div className={css.counterContainer}>
                <Text color="light">{t('charge-overview.energy.label')}</Text>
                <Counter
                  className={css.tag}
                  icon="light"
                  value={t('charge-overview.energy.value', {
                    amount: energyDelivered,
                  })}
                />
              </div>
            )}
          </div>
        </div>

        <Text className={css.hint} color="light" fontSize="xs">
          {t('charge-overview.provisional-information-1')}
          <br />
          {t('charge-overview.provisional-information-2')}
          <br />
          <LearnMoreLink
            questionId="data-given-as-an-indication-during-charging"
            color="light"
          />
        </Text>
      </PageLayout.Body>

      <PageLayout.DrawerFooter>
        <Button
          label={t('charge-overview.go-to-invoice-button')}
          onClick={onGoToChargeSummary}
        />
        <OuterLink url={FACEBOOK_URL} title={t('charge-overview.external-url')}>
          {t('charge-overview.join-us')}
        </OuterLink>
      </PageLayout.DrawerFooter>
    </PageLayout>
  );
};
