import { useCallback, useState } from 'react';

import { ApiError } from '@app/core/error/api-error';
import { JwtResponseModel } from '@app/core/model/jwt-response.model';
import {
  EmailOtpProcessStartRequestModel,
  EmailOtpProcessVerificationRequestModel,
} from '@app/core/model/email-otp-process.model';

import { authenticationService } from '@app/core/service/authentication.service';
import { ErrorCodeEnum } from '@app/config/error-config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useRemainingLockTime } from '@app/core/helper/use-remaining-lock-time';
import { configurationService } from '@app/core/configuration/configuration.service';
import { showDefaultErrorMessage } from '@app/core/error/show-error-message';

interface EmailAuthenticationHookProps {
  emailAddress?: string;
  onStartError: (error: ApiError) => void;
  onSuccess: (jwt: string, email: string) => void;
}

interface EmailAuthenticationHookReturn {
  remainingLockTimeInMin: number;
  onStart: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onVerify: (payload: EmailOtpProcessVerificationRequestModel) => Promise<void>;
}

export const useEmailAuthentication = (
  props: EmailAuthenticationHookProps
): EmailAuthenticationHookReturn => {
  const {
    emailAddress: emailAddressProps = '',
    onStartError,
    onSuccess,
  } = props;
  const [emailAddress, setEmailAddress] = useState(emailAddressProps);

  const { t } = useTranslation();

  const { remainingLockTimeInMin, setRemainingLockTimeInSec } =
    useRemainingLockTime();

  const toastAccountLocked = useCallback(
    (error: ApiError) => {
      toast.error(
        `${t(
          error.remainingLockTimeInSec > 0
            ? 'errors.authentication-too-many-attempts'
            : 'errors.authentication-locked',
          {
            count: Math.ceil(error.remainingLockTimeInSec / 60),
            phoneNumber: configurationService.getSupportPhoneNumber(),
          }
        )} [Code: ${error.code}]`,
        {
          toastId: 'authentication-too-many-attempts',
        }
      );

      setRemainingLockTimeInSec(error.remainingLockTimeInSec);
    },
    [t, setRemainingLockTimeInSec]
  );

  const handleStart = useCallback(
    async (payload: EmailOtpProcessStartRequestModel) => {
      try {
        setEmailAddress(payload.emailAddress);

        const [startPromise] = authenticationService.startEmail(payload);
        await startPromise;
      } catch (error) {
        if (!(error instanceof ApiError)) {
          showDefaultErrorMessage();
          return;
        }

        if (error.isIntercepted) {
          return;
        }

        if (error.code === ErrorCodeEnum.ACCOUNT_LOCKED) {
          toastAccountLocked(error);
          throw error;
        }

        onStartError(error);
        throw error;
      }
    },
    [onStartError, toastAccountLocked]
  );

  const handleVerify = useCallback(
    async (payload: EmailOtpProcessVerificationRequestModel) => {
      const [verifyPromise] = authenticationService.verifyEmail({
        ...payload,
        emailAddress,
      });

      try {
        const jwtResponse: JwtResponseModel = await verifyPromise;
        onSuccess(jwtResponse.jwt, emailAddress);
      } catch (error) {
        if (!(error instanceof ApiError)) {
          showDefaultErrorMessage();
          return;
        }

        if (error.isIntercepted) {
          return;
        }

        if (error.code === ErrorCodeEnum.ACCOUNT_LOCKED) {
          toastAccountLocked(error);
          return;
        }

        // Throw the error for EmailOTPController
        throw error;
      }
    },
    [emailAddress, onSuccess, toastAccountLocked]
  );

  return {
    onStart: handleStart,
    onVerify: handleVerify,
    remainingLockTimeInMin,
  };
};
