import { HTMLProps } from 'react';
import { ItemDropdownMenu } from '@app/shared/dropdown-menu/item-dropdown-menu';
import { useTypedTranslation } from '@app/core/helper/use-typed-translation';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { IconItemMenu } from '@app/shared/item-menu/icon-item-menu';

import css from './dropdown-menu.module.scss';
import { Fade } from '../fade/fade';
import { Overlay } from '../overlay/overlay';
import { Portal } from '../portal/portal';

export interface DropdownMenuProps extends HTMLProps<HTMLDivElement> {
  open: boolean;
  items?: ItemDropdownMenu[];
  onClose?: () => void;
}

export const DropdownMenu = ({
  open,
  items,
  onClose = () => {},
}: DropdownMenuProps): JSX.Element => {
  const { t } = useTypedTranslation();

  return (
    <Portal wrapperId="portal-modal">
      <Fade show={open}>
        <Overlay variant="colored" onClick={onClose}>
          <div className={css.container}>
            <ListItemMenu>
              {items?.map((item) => (
                <IconItemMenu
                  key={`dropdown-menu-${item.label}`}
                  label={t(item.label)}
                  icon={item.icon}
                  onClick={item.action}
                  className={css.itemMenu}
                />
              ))}
            </ListItemMenu>
          </div>
        </Overlay>
      </Fade>
    </Portal>
  );
};
