import classNames from 'classnames';
import { useCallback } from 'react';

import { Icon } from '../icon/icon';
import { Spinner } from '../spinner/spinner';
import { Text } from '../typography/text/text';

import css from './item-menu.module.scss';

export interface ItemMenuProps {
  disabled?: boolean;
  loading?: boolean;
  left?: JSX.Element;
  right?: JSX.Element;
  label: string;
  caption?: string | null;
  bold?: boolean;
  onClick?: () => void;
  className?: string;
}

export const ItemMenu = ({
  left,
  right,
  disabled = false,
  loading = false,
  label,
  caption,
  bold = false,
  onClick = () => {},
  className,
}: ItemMenuProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <button
      className={classNames(className, css.container, disabled && css.disabled)}
      onClick={handleClick}>
      {left}

      <div className={classNames(css.labelContainer, caption && css.oneLine)}>
        <Text bold={bold} className={css.labelOverflow}>
          {label}
        </Text>

        {caption && (
          <Text fontSize="xs" color="secondary" className={css.labelOverflow}>
            {caption}
          </Text>
        )}
      </div>

      <div className={css.rightContainer}>
        {loading ? (
          <Spinner size="sm" />
        ) : (
          right ?? (
            <Icon
              name="chevron-right"
              color={disabled ? 'inherit' : 'primary'}
            />
          )
        )}
      </div>
    </button>
  );
};
