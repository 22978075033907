export interface UserPreferenceModel {
  autoSendPaymentTicketByMail: boolean;
  autoSendInvoiceByMail: boolean;
  enablePseudoCheck: boolean;
  autoSendChargeEndByMail: boolean;
}

export type PatchUserPreferenceModel = Partial<UserPreferenceModel>;
export type UserPreferenceKey = keyof UserPreferenceModel;

export const DefaultUserPreference: UserPreferenceModel = {
  autoSendPaymentTicketByMail: false,
  autoSendInvoiceByMail: false,
  enablePseudoCheck: true,
  autoSendChargeEndByMail: false,
};
