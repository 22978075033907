import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Text } from '@app/shared/typography/text/text';

interface ChargePointFooterProps {
  isAvailable: boolean;
  onClick: () => void;
}

export function ChargePointFooter(props: ChargePointFooterProps): JSX.Element {
  const { isAvailable, onClick } = props;

  const { t } = useTranslation();

  return (
    <>
      {!isAvailable && (
        <Text color="error">
          {t('charge-point.footer.unavailable')}{' '}
          <LearnMoreLink questionId="charge-point-unavailable" />
        </Text>
      )}

      <Button
        disabled={!isAvailable}
        label={t('charge-point.footer.go-to-quote-button')}
        onClick={onClick}
      />
    </>
  );
}
