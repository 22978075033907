import { useTranslation } from 'react-i18next';

import CameraError from '@app/asset/camera-error.svg';

import { Button } from '../button/button';
import { Dialog, DialogProps } from '../dialog/dialog';
import { H1 } from '../typography/heading/heading';
import { Text } from '../typography/text/text';
import { DialogLayout } from '../layout/dialog-layout/dialog-layout';

import css from './webauthn-error-dialog.module.scss';

export interface WebauthnErrorDialogProps extends DialogProps {
  pending?: boolean;
  onRetry: () => void;
}

export const WebauthnErrorDialog = ({
  pending = false,
  onRetry,
  onClose,
  ...dialogProps
}: WebauthnErrorDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogLayout>
        <DialogLayout.Body>
          <CameraError className={css.img} />

          <H1>{t('shared.webauthn-error-dialog.title')}</H1>
          <Text>{t('shared.webauthn-error-dialog.info')}</Text>
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button
            label={t('shared.webauthn-error-dialog.cancel-button')}
            variant="outlined"
            onClick={onClose}
          />
          <Button
            loading={pending}
            label={t('shared.webauthn-error-dialog.retry-button')}
            onClick={onRetry}
          />
        </DialogLayout.Footer>
      </DialogLayout>
    </Dialog>
  );
};
