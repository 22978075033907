import { useState } from 'react';
import { FaqModel } from '@app/core/model/faq.model';
import { Collapsible } from '@app/shared/collapsible/collapsible';
import { H3 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';

import { FaqListItem } from '../faq-list-item/faq-list-item';
import css from './faq-section.module.scss';

type FaqSectionProps = {
  title: string;
  items: FaqModel[];
  onSelect: (itemId: string) => void;
};

export const FaqSection = (props: FaqSectionProps): JSX.Element => {
  const { title, items, onSelect } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className={css.title}
        onClick={() => setIsOpen((value) => !value)}>
        <H3>{title}</H3>

        <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
      <Collapsible isOpen={isOpen}>
        <FaqListItem items={items} onSelect={onSelect} />
      </Collapsible>
    </div>
  );
};
