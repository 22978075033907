import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { Logger } from '@app/core/logger/logger';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { chargeService } from '@app/core/service/charge.service';
import { Button } from '@app/shared/button/button';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import { ChargingEventModel } from '@app/core/model/charging-event.model';

export const StopChargeButton = (): JSX.Element => {
  const { chargeState, onEvent, transactionId } = useCharge();
  const { t } = useTranslation();

  const handleStop = useCallback(async () => {
    onEvent({
      type: ChargeSessionEnum.STOP_CHARGE_CPO_ACCEPTED,
      payload: new ChargingEventModel({
        transactionId,
      }),
    });

    try {
      const [promise] = chargeService.stop();
      await promise;
    } catch (error) {
      Logger.error('Stop charge error : ', error);
      onEvent({
        type: ChargeSessionEnum.STOP_CHARGE_CPO_REFUSED,
        payload: new ChargingEventModel({
          transactionId,
        }),
      });
    }
  }, [onEvent, transactionId]);

  return (
    <Button
      loading={chargeState === ChargeStateEnum.STOP_CHARGE_REQUESTED}
      timeout="charge"
      label={
        chargeState === ChargeStateEnum.RETRY_STOP_CHARGE
          ? t('charging.stop-button.retry')
          : t('charging.stop-button.default')
      }
      onClick={handleStop}
    />
  );
};
