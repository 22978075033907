import React, { HTMLProps, ReactElement, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import css from './link.module.scss';

export interface LinkProps extends Omit<HTMLProps<HTMLButtonElement>, 'type'> {
  disabled?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  onClick?: () => void;
}

export const Link = ({
  disabled = false,
  role = 'link',
  startIcon,
  endIcon,
  className,
  children,
  onClick = () => {},
  ...buttonProps
}: LinkProps): JSX.Element => {
  const handleClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  const styledStartIcon = useMemo(
    () =>
      startIcon
        ? React.cloneElement(startIcon, {
            className: `${startIcon.props.className} ${css.startIcon}`,
          })
        : null,
    [startIcon]
  );

  const styledEndIcon = useMemo(
    () =>
      endIcon
        ? React.cloneElement(endIcon, {
            className: `${endIcon.props.className} ${css.endIcon}`,
          })
        : null,
    [endIcon]
  );

  return (
    <button
      type="button"
      className={classNames(css.link, className)}
      onClick={handleClick}
      role={role}
      {...buttonProps}>
      {styledStartIcon}
      {children}
      {styledEndIcon}
    </button>
  );
};
