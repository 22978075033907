import { useTranslation } from 'react-i18next';

import Biometric from '@app/asset/biometric.svg';
import { Button } from '@app/shared/button/button';
import { Stepper } from '@app/shared/stepper/stepper';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';
import { Link } from '@app/shared/typography/link/link';
import { BackLink } from '@app/shared/back-link/back-link';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';

import css from './webauthn-registration.module.scss';

interface WebauthnRegistrationViewProps {
  pending: boolean;
  onStart: () => Promise<void>;
  onUseEmail: () => void;
  onBack?: () => void;
}

export const WebauthnRegistrationView = ({
  pending,
  onStart,
  onUseEmail,
  onBack = () => {},
}: WebauthnRegistrationViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <BackLink onClick={onBack} />

      <DrawerLayout.Header>
        <H1>{t('registration.webauthn.title')}</H1>
        <Text>{t('registration.webauthn.information')}</Text>
      </DrawerLayout.Header>

      <DrawerLayout.Body className={css.body}>
        <Biometric onClick={onStart} />
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <Stepper total={2} current={2} />
        <Button
          type="submit"
          onClick={onStart}
          loading={pending}
          timeout="webauthn"
          label={t('registration.webauthn.submit-button')}
        />
        {!pending && (
          <Link onClick={onUseEmail}>
            {t('registration.webauthn.use-email')}
          </Link>
        )}
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
