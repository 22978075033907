import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Card } from '@app/shared/card/card';
import { Text } from '@app/shared/typography/text/text';
import { Spinner } from '@app/shared/spinner/spinner';
import { Toggle } from '@app/shared/toggle/toggle';
import { UserPreferenceKey } from '@app/core/model/user-preference.model';
import { Link } from '@app/shared/typography/link/link';
import { MANAGE_AUTHENTICATION_PATH } from '@app/config/app-paths.const';

import css from './user-preference-card.module.scss';

interface UserPreferenceCardProps {
  requireEmailVerified?: boolean;
  name?: UserPreferenceKey;
  pending?: boolean;
  isActive: boolean;
  label: string;
  caption?: string;
  onChange: (value: boolean) => void;
}

export const UserPreferenceCard = (
  props: UserPreferenceCardProps
): JSX.Element => {
  const {
    requireEmailVerified = false,
    name = 'all',
    pending,
    isActive,
    label,
    caption,
    onChange,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (value: boolean) => {
    if (!requireEmailVerified) {
      onChange(value);
    }
  };

  return (
    <Card className={classNames(css.card)}>
      <div className={css.label}>
        <Text bold>{label}</Text>
        {caption && <Text>{caption}</Text>}

        {requireEmailVerified && (
          <Link onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}>
            {t('user-preference.email-required')}
          </Link>
        )}
      </div>

      {pending ? (
        <Spinner />
      ) : (
        <Toggle
          isDisabled={requireEmailVerified}
          state={isActive && !requireEmailVerified}
          id={name}
          onChange={handleChange}
        />
      )}
    </Card>
  );
};
