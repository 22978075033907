import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ErrorCodeEnum } from '@app/config/error-config';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { getErrorCode } from '@app/core/error/api-error';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { chargeService } from '@app/core/service/charge.service';
import { Button } from '@app/shared/button/button';
import { ChargeSessionEnum } from '@app/core/model/enum/charge-session.enum';
import { ChargingEventModel } from '@app/core/model/charging-event.model';

export const StartChargeButton = (): JSX.Element => {
  const { chargeState, onEvent, transactionId } = useCharge();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleStart = useCallback(async () => {
    onEvent({
      type: ChargeSessionEnum.START_CHARGE_CPO_ACCEPTED,
      payload: new ChargingEventModel({
        transactionId,
      }),
    });

    try {
      const [promise] = chargeService.start();
      await promise;
    } catch (error) {
      const errorCode = getErrorCode(error);
      // This should never happen. But if it does, refresh the page to display the CheckUserConsentController
      if (
        errorCode === ErrorCodeEnum.LATEST_VERSION_OF_TERMS_OF_USE_NOT_VALIDATED
      ) {
        navigate(0);
      }

      onEvent({
        type: ChargeSessionEnum.START_CHARGE_CPO_REFUSED,
        payload: new ChargingEventModel({
          transactionId,
        }),
      });
    }
  }, [onEvent, navigate, transactionId]);

  return (
    <Button
      loading={
        chargeState === ChargeStateEnum.START_CHARGE_REQUESTED ||
        chargeState === ChargeStateEnum.SESSION_STARTED
      }
      timeout="charge"
      label={
        chargeState === ChargeStateEnum.RETRY_START_CHARGE
          ? t('charging.start-button.retry')
          : t('charging.start-button.default')
      }
      onClick={handleStart}
    />
  );
};
