import { TariffElementModel } from '@app/core/model/tariff.model';
import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { Text } from '@app/shared/typography/text/text';

import { PriceSquare } from '../price-square/price-square';
import css from './tariff-detail.module.scss';
import { PriceCircle } from '../price-circle/price-circle';
import { useRestrictionText } from '../restriction/use-restriction-text';

type Props = {
  tariffElementList: TariffElementModel[];
  onClick?: () => void;
};

export function TariffDetail(props: Props): JSX.Element {
  const { tariffElementList, onClick = () => {} } = props;
  const getRestrictionText = useRestrictionText();

  return (
    <div className={css.tariffElementList}>
      {tariffElementList.map((tariffElement, index): JSX.Element => {
        const pricing = tariffElement.priceComponents;

        const key = `${pricing.perKwhTTC}-${pricing.perTimeChargingHourTTC}-${pricing.flatTTC}`;
        const maxKwhRestrictionText = getRestrictionText({
          maxKwh: tariffElement?.restrictions?.maxKwh,
        });

        return (
          <div className={css.tariffElementItem} key={key}>
            {index === 0 ? (
              <PriceCircle
                className={css.priceCircle}
                pricePerKwh={pricing.perKwhTTC}
                pricePerMin={pricing.perTimeChargingHourTTC}
                flatTTC={pricing.flatTTC}
                onClick={onClick}
              />
            ) : (
              <PriceSquare
                price={pricing.perKwhTTC}
                unit={ChargePointPriceUnitEnum.PER_KWH}
                isExtra
              />
            )}
            {maxKwhRestrictionText && <Text>{maxKwhRestrictionText}</Text>}
          </div>
        );
      })}
    </div>
  );
}
