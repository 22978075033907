import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { RenamePaymentMethodController } from '@app/feature/account/view/user-payment-method/rename-payment-method-form/rename-payment-method.controller';
import { Icon } from '@app/shared/icon/icon';
import { Text, TextProps } from '@app/shared/typography/text/text';

import css from './rename-link.module.scss';

interface RenameTextProps extends TextProps {
  iPaymentSourceId: string;
  name?: string;
  last4: string;
  onRename: (newName: string) => void;
}

export const RenameText = (props: RenameTextProps): JSX.Element => {
  const {
    iPaymentSourceId,
    name = '',
    last4,
    onRename,
    className,
    ...textProps
  } = props;
  const { t } = useTranslation();

  const [showRenameForm, setShowRenameForm] = useState(false);

  const handleShowRenameForm = () => setShowRenameForm(true);
  const handleHideRenameForm = () => setShowRenameForm(false);

  const handleRename = (newName: string) => {
    handleHideRenameForm();
    onRename(newName);
  };

  return showRenameForm ? (
    <RenamePaymentMethodController
      onRename={handleRename}
      onCancel={handleHideRenameForm}
      iPaymentSourceId={iPaymentSourceId}
      name={name}
    />
  ) : (
    <Text
      fontSize="large"
      onClick={handleShowRenameForm}
      className={classNames(css.container, className)}
      {...textProps}>
      <Icon name="rename" className={css.icon} color="primary" />
      <span className={css.nameContainer}>
        {name ||
          t('shared.credit-card-information.last4', {
            last4,
          })}
      </span>
    </Text>
  );
};
