import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Card } from '@app/shared/card/card';
import { H1 } from '@app/shared/typography/heading/heading';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import css from './invoice-header.module.scss';

interface InvoiceHeaderProps {
  pseudo: string;
  totalCost?: number;
  startedAt?: Date;
  chargePointName: string;
  duration?: number;
  proforma?: boolean;
}

export function InvoiceHeader({
  pseudo,
  totalCost = 0,
  startedAt,
  chargePointName,
  duration = 0,
  proforma = false,
}: InvoiceHeaderProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card border="secondary" radius="half" className={css.orderCard}>
      <div className={css.title}>
        <H1>
          {t(
            proforma
              ? 'shared.invoice.header.title-proforma'
              : 'shared.invoice.header.title'
          )}
        </H1>

        <span
          className={classNames(
            css.titleIconContainer,
            proforma && css.titleIconProforma
          )}>
          <Icon
            name={proforma ? 'credit-card-refresh' : 'credit-card-check'}
            className={classNames(
              css.titleIcon,
              proforma && css.titleIconProformaIcon
            )}
          />
        </span>
      </div>

      <div>
        <Text bold>{t('shared.invoice.header.customer-id')}</Text>
        <Text>{pseudo}</Text>
      </div>

      <div className={css.itemLine}>
        <div className={classNames(css.item, css.itemLarge)}>
          <Icon name="marker-pin" color="primary" />
          <Text bold>{chargePointName}</Text>
        </div>

        {startedAt && (
          <div className={css.item}>
            <Icon name="calendar" color="primary" />
            <Text bold>
              {t('shared.invoice.header.started-at', { startedAt })}
            </Text>
          </div>
        )}
      </div>

      <div className={css.itemLine}>
        {duration > 0 && (
          <div className={classNames(css.item, css.itemLarge)}>
            <Icon name="clock-fast-forward" color="primary" />
            <Text bold>
              {t('shared.invoice.header.duration', { duration })}
            </Text>
          </div>
        )}

        {totalCost > 0 && (
          <div className={css.item}>
            <Icon name="euro" color="primary" />
            <Text bold>
              {t('shared.invoice.header.total-cost', { totalCost })}
            </Text>
          </div>
        )}
      </div>
    </Card>
  );
}
