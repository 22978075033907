import { useLoadUserPaymentMethod } from '@app/feature/account/view/user-payment-method/use-load-user-payment-method';
import { UserPaymentMethodView } from '@app/feature/account/view/user-payment-method/user-payment-method.view';
import { useRedirectUrlResponse } from '@app/core/helper/use-redirect-url-response';
import { CheckoutFrameController } from '@app/feature/payment/view/checkout-frame/checkout-frame.controller';
import { Drawer } from '@app/shared/drawer/drawer';
import { useDrawer } from '@app/shared/drawer/use-drawer';

export const UserPaymentMethodController = (): JSX.Element => {
  const { isError, loading, listUserPaymentMethod, onRefresh } =
    useLoadUserPaymentMethod();

  const { openDrawer, drawerState, closeDrawer } = useDrawer();

  const handleCreatePayment = useRedirectUrlResponse(() => {
    onRefresh();
    closeDrawer();
  });

  const handleCheckoutFrameError = () => {
    setTimeout(closeDrawer, 1000); // Prevent drawer from disappearing too quickly
  };

  return (
    <>
      <UserPaymentMethodView
        listPaymentMethod={listUserPaymentMethod}
        isError={isError}
        loading={loading}
        onRefresh={onRefresh}
        onCreate={openDrawer}
      />
      <Drawer state={drawerState} onClose={closeDrawer}>
        <CheckoutFrameController
          onSubmit={handleCreatePayment}
          onBack={closeDrawer}
          forceSave
          withPrecapture={false}
          onError={handleCheckoutFrameError}
        />
      </Drawer>
    </>
  );
};
