import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Carousel } from '@app/shared/carousel/carousel';
import { H3 } from '@app/shared/typography/heading/heading';
import { Dialog } from '@app/shared/dialog/dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { TARIFICATION_PATH } from '@app/config/app-paths.const';
import { Text } from '@app/shared/typography/text/text';
import { Link } from '@app/shared/typography/link/link';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { ChargeEstimationForOneHourModel } from '@app/core/model/charge-point.model';
import { Icon } from '@app/shared/icon/icon';

import { ChargeEstimationCard } from './charge-estimation-card/charge-estimation-card';
import { EstimateYourChargeCard } from '../estimate-your-charge-card/estimate-your-charge-card';

import css from './charge-estimation-section.module.scss';

interface ChargeEstimationSectionProps {
  maxPower: number;
  estimations: ChargeEstimationForOneHourModel[];
}

export function ChargeEstimationSection(
  props: ChargeEstimationSectionProps
): JSX.Element | null {
  const { t } = useTranslation();
  const { maxPower, estimations } = props;
  const navigate = useNavigate();

  const { openDialog, isDialogOpen, closeDialog } = useDialog();

  const [showEstimation, setShowEstimation] = useState(false);
  const handleToggleEstimation = useCallback(() => {
    setShowEstimation((value) => !value);
  }, []);

  const showableEstimations = useMemo(
    () => estimations.filter((estimation) => estimation.power <= maxPower),
    [estimations, maxPower]
  );

  if (showableEstimations.length === 0) {
    return null;
  }

  return (
    <div className={css.section}>
      <Link
        onClick={handleToggleEstimation}
        endIcon={<Icon name={`chevron-${showEstimation ? 'up' : 'down'}`} />}>
        {showEstimation
          ? t('charge-point.charge-estimation-section.hide-estimation-link')
          : t('charge-point.charge-estimation-section.show-estimation-link')}
      </Link>

      {showEstimation && (
        <>
          <H3 color="primary">
            {t('charge-point.charge-estimation-section.title')}
          </H3>

          <Carousel onClick={openDialog}>
            {showableEstimations.map(
              (estimation: ChargeEstimationForOneHourModel) => (
                <ChargeEstimationCard key={estimation.power} {...estimation} />
              )
            )}
          </Carousel>

          <EstimateYourChargeCard />
        </>
      )}

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogLayout>
          <DialogLayout.Body className={css.dialogBody}>
            <Text>
              <ul>
                <li>{t('charge-point.charge-estimation-section.dialog.p1')}</li>
                <li>
                  {t('charge-point.charge-estimation-section.dialog.p2')}{' '}
                  <Link onClick={() => navigate(TARIFICATION_PATH)} color="p1">
                    {t(
                      'charge-point.charge-estimation-section.dialog.learn-more-link'
                    )}
                  </Link>
                </li>
                <li>{t('charge-point.charge-estimation-section.dialog.p3')}</li>
              </ul>
            </Text>
          </DialogLayout.Body>
        </DialogLayout>
      </Dialog>
    </div>
  );
}
