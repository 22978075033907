import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CallSupportLink } from '@app/shared/call-support/call-support-link';
import { Card } from '@app/shared/card/card';
import { H2 } from '@app/shared/typography/heading/heading';
import { Link } from '@app/shared/typography/link/link';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { Icon } from '@app/shared/icon/icon';

import css from './email-already-taken.module.scss';

interface EmailAlreadyTakenViewProps {
  emailAddress: string;
  onGoToAuthentication: () => void;
  onGoToRegistration: () => void;
}

export const EmailAlreadyTakenView = (
  props: EmailAlreadyTakenViewProps
): JSX.Element => {
  const { emailAddress, onGoToAuthentication, onGoToRegistration } = props;
  const { t } = useTranslation();

  return (
    <DrawerLayout>
      <DrawerLayout.Body>
        <Card
          background="paper"
          border="tertiary"
          className={classNames(css.item, css.card)}
          radius="half">
          <H2>{t('registration.email-already-taken.title')}</H2>

          <Text className={css.justify}>
            {t('registration.email-already-taken.caption.start')}
          </Text>

          <Text bold className={css.email}>
            {emailAddress}
          </Text>

          <Text>{t('registration.email-already-taken.caption.end')}</Text>
        </Card>

        <div className={css.item}>
          <Text fontSize="large">
            {t('registration.email-already-taken.authenticate.title')}
          </Text>
          <Link
            onClick={onGoToAuthentication}
            endIcon={<Icon name="arrow-right" />}>
            {t('registration.email-already-taken.authenticate.link')}
          </Link>
        </div>

        <div className={css.item}>
          <Text fontSize="large">
            {t('registration.email-already-taken.register.title')}
          </Text>
          <Link
            onClick={onGoToRegistration}
            endIcon={<Icon name="arrow-right" />}>
            {t('registration.email-already-taken.register.link')}
          </Link>
        </div>
      </DrawerLayout.Body>

      <DrawerLayout.Footer>
        <Text fontSize="large">
          {t('registration.email-already-taken.support')}
        </Text>
        <CallSupportLink />
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
};
