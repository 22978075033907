import { Drawer, DrawerProps } from '@app/shared/drawer/drawer';
import { EmailOTPProcessController } from '@app/feature/email-otp-process/email-otp-process.controller';
import { useMemo } from 'react';
import { TranslationPaths } from '@app/core/helper/use-typed-translation';

import { useValidateOrUpdateEmail } from '../hook/use-validate-or-update-email';

interface ValidateOrUpdateEmailDrawerProps extends DrawerProps {
  emailAddress?: string;
  onSuccess: () => void;
  isUpdating?: boolean;
  translationKey?: TranslationPaths;
}

export const ValidateOrUpdateEmailDrawer = (
  props: ValidateOrUpdateEmailDrawerProps
): JSX.Element => {
  const {
    emailAddress,
    isUpdating,
    onSuccess,
    onClose = () => {},
    translationKey: translationKeyProps,
    ...drawerProps
  } = props;

  const { onStart, onVerify } = useValidateOrUpdateEmail({
    emailAddress,
    onSuccess,
  });

  const translationKey = useMemo(() => {
    if (translationKeyProps) {
      return translationKeyProps;
    }

    return isUpdating
      ? 'manage-data.update-email'
      : 'manage-authentication-method.email';
  }, [translationKeyProps, isUpdating]);

  return (
    <Drawer {...drawerProps} onClose={onClose}>
      <EmailOTPProcessController
        emailAddress={emailAddress}
        translationKey={translationKey}
        onRequestOTP={onStart}
        onRequestAgainOTP={onStart}
        onValidateOTP={onVerify}
        onCancel={onClose}
      />
    </Drawer>
  );
};
