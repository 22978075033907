import { Drawer, DrawerStateEnum } from '@app/shared/drawer/drawer';
import { VerifyEmailOtpProcessController } from '@app/feature/email-otp-process/verify-email-otp-process/verify-email-otp-process.controller';
import { EmailOtpProcessVerificationRequestModel } from '@app/core/model/email-otp-process.model';
import { confirmIdentityService } from '@app/core/service/confirm-identity.service';

interface VerifyIdentityWithEmailControllerProps {
  emailAddress: string;
  identityVerificationKey: string;
  onSuccess: (token: string) => void;
  onCancel: () => void;
  onRetry: () => Promise<void>;
}

export const VerifyIdentityWithEmailController = (
  props: VerifyIdentityWithEmailControllerProps
): JSX.Element => {
  const {
    emailAddress,
    identityVerificationKey,
    onCancel,
    onSuccess,
    onRetry,
  } = props;

  const handleVerifyWithEmail = async (
    payload: EmailOtpProcessVerificationRequestModel
  ) => {
    if (!identityVerificationKey) {
      throw new Error('No identity verification key provided.');
    }

    const { token } = await confirmIdentityService.verifyIdentityVerification({
      emailChallenge: payload.challenge,
      identityVerificationKey,
    });

    onSuccess(token);
  };

  return (
    <Drawer wrapperId="confirm-identity-portal" state={DrawerStateEnum.OPEN}>
      <VerifyEmailOtpProcessController
        payload={{ emailAddress }}
        onValidateOTP={handleVerifyWithEmail}
        onCancel={onCancel}
        onRequestAgainOTP={onRetry}
      />
    </Drawer>
  );
};
