import { useTranslation } from 'react-i18next';

import { TranslationLeaves } from '@app/core/helper/use-typed-translation';

import { Button } from '../button/button';
import { Text } from '../typography/text/text';
import { H3 } from '../typography/heading/heading';

import css from './error-fragment.module.scss';

interface ErrorFragmentProps {
  titleTranslationKey?: TranslationLeaves;
  captionTranslationKey?: TranslationLeaves;
  onRetry: () => void;
}

export const ErrorFragment = (props: ErrorFragmentProps): JSX.Element => {
  const {
    titleTranslationKey = 'shared.error-fragment.title',
    captionTranslationKey = 'shared.error-fragment.caption',
    onRetry,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <H3>{t(titleTranslationKey)}</H3>
      <Text>{t(captionTranslationKey)}</Text>

      <Button
        label={t('shared.error-fragment.retry-button')}
        onClick={onRetry}
      />
    </div>
  );
};
