import { useState, useCallback, useEffect } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { INVOICE_SUMMARY_PATH } from '@app/config/app-paths.const';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import {
  PaginatedResponseHookReturn,
  usePaginatedResponse,
} from '@app/core/pagination/use-paginated-response';
import { useOffsetLimitPagination } from '@app/core/pagination/use-offset-limit-pagination';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { invoicingService } from '@app/core/service/invoicing.service';
import { InvoiceRouterStateModel } from '@app/core/model/invoice-router-state.model';

import { InvoiceDashboardView } from './invoice-dashboard.view';

export const InvoiceDashboardController = (): JSX.Element => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [isFirstLoadingDone, setIsFirstLoadingDone] = useState(false);
  const [proformaOrderList, setProformaOrderList] = useState<InvoiceModel[]>(
    []
  );
  const [isPendingProforma, setIsPendingProforma] = useState(true);

  const pagination = useOffsetLimitPagination();

  const {
    onRequestWithPagination,
    hasMore,
  }: PaginatedResponseHookReturn<InvoiceModel> =
    usePaginatedResponse<InvoiceModel>({
      onRequestData: invoicingService.getInvoicePerPage,
      incrementPage: pagination.incrementPage,
    });

  const handleInfiniteLoad = useCallback(async () => {
    try {
      const response = await onRequestWithPagination(
        pagination.offset,
        pagination.limit
      );
      setIsFirstLoadingDone(true);

      setInvoices((currentInvoices) => {
        const nextInvoiceList = [...currentInvoices];

        response.forEach((item: InvoiceModel) => {
          const alreadyExist = currentInvoices.find(
            (invoice) => invoice.invoiceId === item.invoiceId
          );

          if (!alreadyExist) {
            nextInvoiceList.push(item);
          }
        });

        return nextInvoiceList;
      });
    } catch (error) {
      setIsFirstLoadingDone(true);

      catchApiError(error, (apiError: ApiError) => {
        showErrorMessage('list-invoice', apiError.code);
      });
    }
  }, [onRequestWithPagination, pagination.offset, pagination.limit]);

  const handleSelect = useCallback(
    (invoice: InvoiceModel) => {
      const invoiceState: InvoiceRouterStateModel = { invoice };
      const { invoiceId } = invoice;

      navigate(
        generatePath(INVOICE_SUMMARY_PATH, {
          invoiceId,
        }),
        {
          state: invoiceState,
        }
      );
    },
    [navigate]
  );

  // Load proforma
  useEffect(() => {
    setIsPendingProforma(true);
    const [promise, abortPromise] = invoicingService.getProforma();

    promise
      .then(setProformaOrderList)
      .catch((err) => {
        catchApiError(err, () => {});
      })
      .finally(() => {
        setIsPendingProforma(false);
      });

    return () => {
      abortPromise.abort();
    };
  }, []);

  return (
    <InvoiceDashboardView
      onLoad={handleInfiniteLoad}
      pending={isFirstLoadingDone || isPendingProforma}
      hasMore={hasMore}
      invoiceList={invoices}
      proformaList={proformaOrderList}
      onSelect={handleSelect}
    />
  );
};
