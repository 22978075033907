import { useTranslation } from 'react-i18next';

import { Dialog, DialogProps } from '@app/shared/dialog/dialog';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { Link } from '@app/shared/typography/link/link';
import { CallSupportButton } from '@app/shared/call-support/call-support-button';
import { configurationService } from '@app/core/configuration/configuration.service';

import css from './recover-with-support-dialog.module.scss';

const DELMONICOS_SUPPORT_PHONE_NUMBER =
  configurationService.getSupportPhoneNumber();

interface RecoverWithSupportDialogProps extends DialogProps {
  onNavigateToRegistration: () => void;
}

export const RecoverWithSupportDialog = (
  props: RecoverWithSupportDialogProps
): JSX.Element => {
  const { onNavigateToRegistration, ...dialogProps } = props;

  const { t } = useTranslation();

  return (
    <Dialog {...dialogProps}>
      <DialogLayout>
        <DialogLayout.Body className={css.body}>
          <H1>{t('recover-account.support.title')}</H1>

          <div className={css.section}>
            <Text>
              {t('recover-account.support.call-support.caption')}{' '}
              <Text bold>
                {t('recover-account.support.call-support.caption-bold')}
              </Text>
            </Text>
            <CallSupportButton
              className={css.supportLink}
              label={t('recover-account.support.call-support.button', {
                phoneNumber: DELMONICOS_SUPPORT_PHONE_NUMBER,
              })}
            />
          </div>

          <div className={css.section}>
            <Text>{t('recover-account.support.register.caption')}</Text>
            <Link onClick={onNavigateToRegistration} color="p1">
              {t('recover-account.support.register.link')}
            </Link>
          </div>
        </DialogLayout.Body>
      </DialogLayout>
    </Dialog>
  );
};
