import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { EmailInput } from '@app/shared/text-input/email-input';
import { Fade } from '@app/shared/fade/fade';
import { Stepper } from '@app/shared/stepper/stepper';
import { TextInput } from '@app/shared/text-input/text-input';
import { Text } from '@app/shared/typography/text/text';
import { H1 } from '@app/shared/typography/heading/heading';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { Spinner } from '@app/shared/spinner/spinner';
import { Overlay } from '@app/shared/overlay/overlay';
import { LegalText } from '@app/core/model/legal-text';
import { BackLink } from '@app/shared/back-link/back-link';
import { ConsentUpdateRequestModel } from '@app/core/model/consent.model';
import { RegistrationRequestModel } from '@app/core/model/registration.model';
import {
  FIRST_NAME_MAX_LENGTH,
  FIRST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
} from '@app/config/form-validation-rules.const';

import { registrationFormValidationRules } from './registration-form-validation-rules.const';
import css from './registration-form.module.scss';
import { MandatoryConsentCheckbox } from './component/mandatory-consent-checkbox/mandatory-consent-checkbox';
import { OptionalConsentCheckbox } from './component/optional-consent-checkbox/optional-consent-checkbox';
import { RegistrationForm } from './registration.form';

interface RegistrationFormViewProps {
  mandatoryLegalTexts: LegalText[];
  optionalLegalTexts?: LegalText[];
  defaultValues?: RegistrationRequestModel;
  loading?: boolean;
  onSubmit: (data: RegistrationRequestModel) => void;
  onViewLegalText: (text: LegalText) => void;
  onBack?: () => void;
}

export const RegistrationFormView = ({
  mandatoryLegalTexts,
  optionalLegalTexts = [],
  defaultValues,
  loading = false,
  onSubmit,
  onViewLegalText,
  onBack = () => {},
}: RegistrationFormViewProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<RegistrationForm>({
    mode: 'onChange',
    defaultValues: { ...defaultValues, legalText: false },
  });

  const [optionalConsents, setOptionalConsents] = useState<
    ConsentUpdateRequestModel[]
  >([]);
  const { t } = useTranslation();

  useEffect(() => {
    setOptionalConsents(
      optionalLegalTexts.map((legalText: LegalText) => ({
        id: legalText.id,
        isConsentGiven: false,
      }))
    );
  }, [optionalLegalTexts]);

  const handleChangeOnOptionalConsent = useCallback((consentId: string) => {
    setOptionalConsents((currentOptionalConsents) => {
      return currentOptionalConsents.map((consent) => {
        if (consent.id === consentId) {
          return {
            id: consent.id,
            isConsentGiven: !consent.isConsentGiven,
          };
        }
        return consent;
      });
    });
  }, []);

  const handleRegistrationSubmit = (data: RegistrationForm) => {
    const mandatoryConsents: ConsentUpdateRequestModel[] =
      mandatoryLegalTexts.map((text: LegalText) => ({
        id: text.id,
        isConsentGiven: data.legalText,
      }));

    onSubmit({
      consents: mandatoryConsents.concat(optionalConsents),
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      emailAddress: data.emailAddress.trim().toLowerCase(),
    });
  };

  return (
    <form
      className={css.formContainer}
      onSubmit={handleSubmit(handleRegistrationSubmit)}>
      <DrawerLayout>
        <BackLink onClick={onBack} />

        <DrawerLayout.Header className={css.header}>
          <H1>{t('registration.registration-form.title')}</H1>
          <Text>{t('registration.registration-form.subTitle')}</Text>
        </DrawerLayout.Header>

        <DrawerLayout.Body>
          <TextInput
            autoFocus
            autoComplete="given-name"
            label={t('registration.registration-form.first-name')}
            {...register(
              'firstName',
              registrationFormValidationRules.firstName
            )}
            isTouched={touchedFields?.firstName}
            enterKeyHint="next"
            errorMessage={
              errors?.firstName?.type === 'maxLength'
                ? t(
                    'registration.registration-form.errors.first-name.max-length',
                    { maxLength: FIRST_NAME_MAX_LENGTH }
                  )
                : errors?.firstName?.type === 'minLength'
                ? t(
                    'registration.registration-form.errors.first-name.min-length',
                    { minLength: FIRST_NAME_MIN_LENGTH }
                  )
                : errors?.firstName?.type === 'pattern'
                ? t('registration.registration-form.errors.first-name.pattern')
                : errors?.firstName?.type === 'required'
                ? t('registration.registration-form.errors.first-name.required')
                : ''
            }
          />
          <TextInput
            autoComplete="family-name"
            label={t('registration.registration-form.last-name')}
            {...register('lastName', registrationFormValidationRules.lastName)}
            isTouched={touchedFields?.lastName}
            enterKeyHint="next"
            errorMessage={
              errors?.lastName?.type === 'maxLength'
                ? t(
                    'registration.registration-form.errors.last-name.max-length',
                    { maxLength: LAST_NAME_MAX_LENGTH }
                  )
                : errors?.lastName?.type === 'minLength'
                ? t(
                    'registration.registration-form.errors.last-name.min-length',
                    { minLength: LAST_NAME_MIN_LENGTH }
                  )
                : errors?.lastName?.type === 'pattern'
                ? t('registration.registration-form.errors.last-name.pattern')
                : errors?.lastName?.type === 'required'
                ? t('registration.registration-form.errors.last-name.required')
                : ''
            }
          />
          <EmailInput
            {...register(
              'emailAddress',
              registrationFormValidationRules.emailAddress
            )}
            isTouched={touchedFields?.emailAddress}
            error={errors?.emailAddress}
          />

          <MandatoryConsentCheckbox
            {...register(
              'legalText',
              registrationFormValidationRules.legalText
            )}
            onClick={onViewLegalText}
            legalTexts={mandatoryLegalTexts}
            id="legal-text"
            isTouched={touchedFields?.legalText}
            errorMessage={
              errors?.legalText?.type === 'required'
                ? t('registration.registration-form.errors.legal-text.required')
                : ''
            }
          />

          {optionalLegalTexts.map((legalText) => (
            <OptionalConsentCheckbox
              key={legalText.title}
              label={legalText.title}
              onChange={() => handleChangeOnOptionalConsent(legalText.id)}
              onOpenLegalText={() => onViewLegalText(legalText)}
            />
          ))}
        </DrawerLayout.Body>

        <DrawerLayout.Footer>
          <Stepper total={2} current={1} />
          <Button
            type="submit"
            visuallyDisabled={!isValid}
            label={t('registration.registration-form.submit')}
          />
        </DrawerLayout.Footer>
      </DrawerLayout>

      <Fade show={loading}>
        <Overlay blur>
          <Spinner size="md" translationKey="shared.spinner.legal-text" />
        </Overlay>
      </Fade>
    </form>
  );
};
