import { ChargePointPriceUnitEnum } from '@app/core/model/enum/charge-point-price-unit.enum';
import { useTranslation } from 'react-i18next';
import { Text } from '@app/shared/typography/text/text';

import css from './price-square.module.scss';
import { usePriceUnit } from '../price-circle/use-price-unit';

type PriceSquareProps = {
  price?: number;
  unit?: ChargePointPriceUnitEnum;
  isExtra?: boolean;
};

export function PriceSquare(props: PriceSquareProps): JSX.Element {
  const { price = 0, unit, isExtra = false } = props;

  const { t } = useTranslation();
  const unitLabel = usePriceUnit(unit);

  return (
    <div className={css.square}>
      <Text bold className={css.price}>
        {t('tariff.price', { value: price })} {unitLabel}
      </Text>

      {isExtra && <Text className={css.caption}>{t('tariff.extra')}</Text>}
    </div>
  );
}
