import { H1 } from '@app/shared/typography/heading/heading';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { useTypedTranslation } from '@app/core/helper/use-typed-translation';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { DeleteAccountController } from '@app/feature/account/view/user-security/view/delete-account/delete-account.controller';
import {
  ACCOUNT_PATH,
  LANGUAGE_PATH,
  MANAGE_AUTHENTICATION_PATH,
  MANAGE_PERSONAL_INFORMATION_PATH,
} from '@app/config/app-paths.const';
import { useNavigate } from 'react-router-dom';
import { NotificationItem } from '../../component/notification-item/notification-item';

interface UserSecurityViewProps {
  emailAddress: string;
  isChargeStarted: boolean;
  onUpdateEmail: () => void;
}

export function UserSecurityView({
  emailAddress,
  isChargeStarted = false,
  onUpdateEmail,
}: UserSecurityViewProps): JSX.Element {
  const { t } = useTypedTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout>
      <BackLink onClick={() => navigate(ACCOUNT_PATH)} />

      <PageLayout.Header>
        <H1>{t('account.security-label')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        <ListItemMenu>
          {isChargeStarted && (
            <NotificationItem>
              {t('account.notification-item.charge-is-started-toast')}
            </NotificationItem>
          )}

          <ItemMenu
            bold
            label={t('user-security.language')}
            onClick={() => navigate(LANGUAGE_PATH)}
          />

          <ItemMenu
            bold
            label={t('user-security.personal-information')}
            disabled={isChargeStarted}
            onClick={() => navigate(MANAGE_PERSONAL_INFORMATION_PATH)}
          />

          <ItemMenu
            bold
            label={t('user-security.authentication')}
            disabled={isChargeStarted}
            onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}
          />

          <ItemMenu
            bold
            label={t('user-security.update-email')}
            disabled={isChargeStarted}
            onClick={onUpdateEmail}
          />

          <DeleteAccountController emailAddress={emailAddress}>
            {({ onStart }) => (
              <ItemMenu
                bold
                label={t('user-security.delete-account')}
                disabled={isChargeStarted}
                onClick={onStart}
              />
            )}
          </DeleteAccountController>
        </ListItemMenu>
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
}
