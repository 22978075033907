import { useTranslation } from 'react-i18next';
import { Interpolation, LineChartOptions } from 'chartist';

import { LineChart } from '@app/feature/charging/component/charge-chart/line-chart/line-chart';
import { ChartProps } from '@app/feature/charging/component/charge-chart/model/chart-props';
import { H3 } from '@app/shared/typography/heading/heading';

import css from './charge-chart.module.scss';

function getChartOptions(numberOfPoints: number): Partial<LineChartOptions> {
  return {
    lineSmooth:
      numberOfPoints > 16 ? Interpolation.simple() : Interpolation.step(),
    showPoint: false,
    showArea: true,
  };
}

export const EnergyDeliveredChart = (props: ChartProps): JSX.Element => {
  const { points } = props;
  const { t } = useTranslation();

  return (
    <div>
      <H3 className={css.chartTitle}>{t('charging.chart.energy-delivered')}</H3>
      <LineChart points={points} options={getChartOptions(points.length)}>
        {({ chartRef }) => (
          <div className="energy-delivered-chart" ref={chartRef} />
        )}
      </LineChart>
    </div>
  );
};
