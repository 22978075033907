import { useMemo } from 'react';
import classNames from 'classnames';

import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import {
  mapHistoryToTotalKWPoint,
  mapTotalEnergyDeliveredPointToSpeedPoint,
} from '@app/feature/charging/component/charge-chart/charge-chart-utils';

import { Point } from './model/point';
import { ChargeChartView } from './charge-chart.view';
import { FallbackChargeChart } from './fallback-charge-chart.view';

interface ChargeChartControllerProps {
  className?: string;
}

export const ChargeChartController = (
  props: ChargeChartControllerProps
): JSX.Element | null => {
  const { className } = props;
  const { history } = useCharge();

  const totalKWDeliveredPoint: Point[] = useMemo(
    () => mapHistoryToTotalKWPoint(history),
    [history]
  );

  const speedChargePoint: Point[] = useMemo(
    () => mapTotalEnergyDeliveredPointToSpeedPoint(totalKWDeliveredPoint),
    [totalKWDeliveredPoint]
  );

  // Wait to have at least 2 points to display the chart
  if (history.length <= 2) {
    return <FallbackChargeChart />;
  }

  return (
    <ChargeChartView
      className={classNames(className)}
      speedCharge={speedChargePoint}
      totalKWDelivered={totalKWDeliveredPoint}
    />
  );
};
