import { AbortablePromise } from '@app/core/helper/abort-promise';
import { httpClientService } from '@app/core/client/http/http-client.service';
import { GET_CHARGE_POINT_API } from '@app/config/api-routes.const';
import {
  ChargePointModel,
  ChargePointResponseModel,
} from '@app/core/model/charge-point.model';
import { PaginatedResponseModel } from '@app/core/model/paginated-response.model';
import { themeConfig } from '@app/config/theme.config';
import { applyTheme, removeTheme } from '@app/shared/styles/apply-theme';
import { Logger } from '../logger/logger';

class ChargePointService {
  async getChargePoint(
    delmoChargePointId: string | null
  ): Promise<ChargePointResponseModel | null> {
    if (!delmoChargePointId) {
      return null;
    }

    const [promise] = httpClientService.get<ChargePointModel>(
      GET_CHARGE_POINT_API,
      delmoChargePointId
    );

    promise.then((chargePoint) => {
      if (!chargePoint.themeId || !(chargePoint?.themeId in themeConfig)) {
        removeTheme();
        return;
      }

      themeConfig[chargePoint.themeId]()
        .then((css) => {
          if (!('theme' in css.default)) {
            removeTheme();
            return;
          }

          applyTheme(css.default.theme);
        })
        .catch((error) => {
          Logger.error(
            `Could not load themeId ${chargePoint.themeId}. Reason:`,
            error
          );
          removeTheme();
        });
    });

    return promise;
  }

  getChargePointList(
    qrCodeTinyId: string
  ): AbortablePromise<ChargePointModel[]> {
    const [promise, abortPromise] = httpClientService.run<
      PaginatedResponseModel<ChargePointModel>
    >(GET_CHARGE_POINT_API, {
      method: 'GET',
      params: {
        qrCodeTinyId,
      },
    });

    return [
      promise.then(
        (response: PaginatedResponseModel<ChargePointModel>) => response.docs
      ),
      abortPromise,
    ];
  }
}

export const chargePointService = new ChargePointService();
