import { HTMLProps } from 'react';
import classNames from 'classnames';

import CarModel from '@app/asset/car-model.svg';
import Ellipse1 from '@app/asset/ellipse1.svg';
import Ellipse2 from '@app/asset/ellipse2.svg';
import Ellipse3 from '@app/asset/ellipse3.svg';
import Ellipse4 from '@app/asset/ellipse4.svg';

import css from './charging-car-anim.module.scss';

interface ChargingCarAnimProps extends HTMLProps<HTMLDivElement> {
  animate?: boolean;
}

export const ChargingCarAnim = ({
  animate = false,
  className,
}: ChargingCarAnimProps): JSX.Element => {
  return (
    <div className={classNames(css.chargingCar, className)}>
      <Ellipse1
        className={classNames(
          css.animated,
          css.ellipse1,
          !animate && css.pause
        )}
      />
      {animate && (
        <>
          <Ellipse2
            className={classNames(
              css.animated,
              css.ellipse2,
              !animate && css.pause
            )}
          />
          <Ellipse3
            className={classNames(
              css.animated,
              css.ellipse3,
              !animate && css.pause
            )}
          />
          <Ellipse4
            className={classNames(
              css.animated,
              css.ellipse4,
              !animate && css.pause
            )}
          />
        </>
      )}

      <CarModel className={css.carModel} />
    </div>
  );
};
