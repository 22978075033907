import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TARIFICATION_PATH } from '@app/config/app-paths.const';
import { Card, CardProps } from '@app/shared/card/card';
import { Icon } from '@app/shared/icon/icon';
import { Text } from '@app/shared/typography/text/text';

import css from './estimate-your-charge-card.module.scss';

export function EstimateYourChargeCard({
  className,
  ...cardProps
}: CardProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToChargeEstimationCostDetails = useCallback(() => {
    navigate(TARIFICATION_PATH);
  }, [navigate]);

  return (
    <button onClick={handleNavigateToChargeEstimationCostDetails}>
      <Card
        background="colored"
        className={classNames(css.card, className)}
        {...cardProps}>
        <Icon name="delmonicos" className={css.icon} />
        <Text bold className={css.label}>
          {t('charge-point.estimate-your-charge-card')}
        </Text>
      </Card>
    </button>
  );
}
