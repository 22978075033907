import { useEffect, useState } from 'react';

import { getDuration } from '@app/core/helper/get-duration';
import { LiveDurationView } from './live-duration.view';

interface LiveDurationControllerProps {
  startedAt: Date;
  isStopped?: boolean;
}

export const LiveDurationController = (
  props: LiveDurationControllerProps
): JSX.Element => {
  const { startedAt, isStopped } = props;

  const [duration, setDuration] = useState(getDuration(startedAt));

  useEffect(() => {
    if (isStopped) {
      return () => {};
    }

    const interval = setInterval(() => {
      setDuration(getDuration(startedAt));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isStopped, startedAt]);

  return <LiveDurationView duration={duration} />;
};
