import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@app/shared/avatar/avatar';
import { Button } from '@app/shared/button/button';
import { Card } from '@app/shared/card/card';
import { H1 } from '@app/shared/typography/heading/heading';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { Text } from '@app/shared/typography/text/text';
import {
  USER_PAYMENT_METHOD_PATH,
  MANAGE_AUTHENTICATION_PATH,
  SECURITY_PATH,
  HELP_PATH,
  USER_PREFERENCE_PATH,
  LEGAL_TEXT_PATH,
  USER_SUBSCRIPTION_PATH,
} from '@app/config/app-paths.const';

import { NotificationItem } from './component/notification-item/notification-item';
import css from './account.module.scss';

interface AccountViewProps {
  firstName: string;
  lastName: string;
  email: string;
  pseudo: string;
  isWebauthnEnabled?: boolean;
  isEmailVerified?: boolean;
  isChargeStarted?: boolean;
  onLogout: () => void;
}

export function AccountView({
  firstName,
  lastName,
  email,
  pseudo,
  isWebauthnEnabled = false,
  isEmailVerified = false,
  isChargeStarted = false,
  onLogout,
}: AccountViewProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout className={css.layout}>
      <PageLayout.Header className={css.header}>
        <Card className={css.card} radius="half" border="tertiary">
          <div className={css.user}>
            <Avatar firstName={firstName} lastName={lastName} />
            <div className={css.userInfo}>
              <H1 className={css.username}>{`${firstName} ${lastName}`}</H1>
              <Text>{email}</Text>
            </div>
          </div>
          <Text bold>{t('account.pseudo', { pseudo })}</Text>
        </Card>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <ListItemMenu>
          {isChargeStarted && (
            <NotificationItem>
              {t('account.notification-item.charge-is-started-toast')}
            </NotificationItem>
          )}

          {!isEmailVerified && (
            <NotificationItem
              isDisabled={isChargeStarted}
              onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}>
              {t('account.notification-item.verify-email')}
            </NotificationItem>
          )}

          {!isWebauthnEnabled && (
            <NotificationItem
              isDisabled={isChargeStarted}
              onClick={() => navigate(MANAGE_AUTHENTICATION_PATH)}>
              {t('account.notification-item.enable-webauthn')}
            </NotificationItem>
          )}

          <ItemMenu
            bold
            label={t('account.security-label')}
            onClick={() => navigate(SECURITY_PATH)}
          />

          <ItemMenu
            bold
            label={t('account.user-preference-label')}
            onClick={() => navigate(USER_PREFERENCE_PATH)}
          />

          <ItemMenu
            bold
            label={t('account.payment-method-label')}
            disabled={isChargeStarted}
            onClick={() => navigate(USER_PAYMENT_METHOD_PATH)}
          />

          <ItemMenu
            bold
            label={t('account.subscription-label')}
            disabled={isChargeStarted}
            onClick={() => navigate(USER_SUBSCRIPTION_PATH)}
          />

          <ItemMenu
            bold
            label={t('account.help-label')}
            onClick={() => navigate(HELP_PATH)}
          />

          <ItemMenu
            bold
            label={t('account.legal-text-label')}
            onClick={() => navigate(LEGAL_TEXT_PATH)}
          />
        </ListItemMenu>

        <Button
          icon="logout"
          label={t('account.logout-button')}
          onClick={onLogout}
        />
      </PageLayout.Body>
      <PageLayout.AppbarFooter />
    </PageLayout>
  );
}
