import { useCallback } from 'react';
import classNames from 'classnames';

import { DASHBOARD_PATH } from '@app/config/app-paths.const';
import { Logo } from '@app/shared/logo/logo';
import {
  AvatarDropdownMenuHeader,
  AvatarDropdownMenuHeaderProps,
} from '@app/shared/avatar-dropdown-menu-header/avatar-dropdown-menu-header';
import { Appbar } from '@app/shared/appbar/appbar';
import { UseMeasureRef, useMeasure } from '@app/core/helper/use-measure';

import {
  BaseBodyProps,
  BaseFooterProps,
  BaseHeaderProps,
  BaseLayout,
  BaseLayoutProps,
} from '../base-layout/base-layout';

import css from './page-layout.module.scss';

export interface PageLayoutProps extends BaseLayoutProps {
  colored?: boolean;
}

const PageLayout = ({
  colored,
  className,
  children,
  ...baseLayoutProps
}: PageLayoutProps): JSX.Element => {
  const handleGoToDashboard = useCallback(() => {
    // refresh and redirect
    window.location.replace(DASHBOARD_PATH);
  }, []);

  return (
    <BaseLayout
      className={classNames(
        css.layout,
        colored && css.layoutColored,
        className
      )}
      {...baseLayoutProps}>
      <button className={css.logo} onClick={handleGoToDashboard}>
        <Logo light={colored} />
      </button>

      {children}
    </BaseLayout>
  );
};

const PageHeader = (baseHeaderProps: BaseHeaderProps): JSX.Element => (
  <BaseLayout.Header {...baseHeaderProps} />
);

interface PageBodyProps extends BaseBodyProps {
  withTexture?: boolean;
}

const PageBody = ({
  withTexture = false,
  className,
  ...baseBodyProps
}: PageBodyProps): JSX.Element => (
  <BaseLayout.Body
    className={classNames(
      css.body,
      withTexture && css.bodyWithTexture,
      className
    )}
    {...baseBodyProps}
  />
);

interface DynamicHeightFooterProps {
  children: (ref: UseMeasureRef<HTMLDivElement>) => JSX.Element;
}

const DynamicHeightFooter = (props: DynamicHeightFooterProps): JSX.Element => {
  const { children } = props;

  const [ref, { y, bottom }] = useMeasure<HTMLDivElement>();

  return (
    <div style={{ height: y + bottom }} className={css.dynamicHeightFooter}>
      {children(ref)}
    </div>
  );
};

const PageFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <DynamicHeightFooter>
    {(ref) => (
      <BaseLayout.Footer
        {...baseFooterProps}
        className={classNames(css.footer, css.footerPage, className)}
        ref={ref}
      />
    )}
  </DynamicHeightFooter>
);

const AppbarFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <DynamicHeightFooter>
    {(ref) => (
      <BaseLayout.Footer
        {...baseFooterProps}
        className={classNames(css.footer, css.appbar, className)}
        ref={ref}>
        <Appbar />
      </BaseLayout.Footer>
    )}
  </DynamicHeightFooter>
);

const DrawerFooter = ({
  className,
  ...baseFooterProps
}: BaseFooterProps): JSX.Element => (
  <DynamicHeightFooter>
    {(ref) => (
      <BaseLayout.Footer
        {...baseFooterProps}
        className={classNames(className, css.footer, css.drawerFooter)}
        ref={ref}
      />
    )}
  </DynamicHeightFooter>
);

const UserAvatar = ({
  className,
  ...avatarDropdownMenuHeaderProps
}: AvatarDropdownMenuHeaderProps): JSX.Element => (
  <AvatarDropdownMenuHeader
    className={classNames(css.avatar, className)}
    {...avatarDropdownMenuHeaderProps}
  />
);

PageLayout.Header = PageHeader;
PageLayout.Body = PageBody;
PageLayout.Footer = PageFooter;
PageLayout.AppbarFooter = AppbarFooter;
PageLayout.UserAvatar = UserAvatar;
PageLayout.DrawerFooter = DrawerFooter;

export { PageLayout };
