import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@app/shared/typography/link/link';

const DEMO_CARD_NUMBER = '4485040371536584';
const DEMO_EXP_DATE = '02/24';
const DEMO_CVV = '435';

interface CopyProps {
  text: string;
}

const Copy = (props: CopyProps): JSX.Element => {
  const { t } = useTranslation();
  const { text } = props;

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  return (
    <Link onClick={handleCopy}>
      {text} - {t('copy')}
    </Link>
  );
};

export const CopyCardNumber = (): JSX.Element => {
  return <Copy text={DEMO_CARD_NUMBER} />;
};

export const CopyExpirationDate = (): JSX.Element => {
  return <Copy text={DEMO_EXP_DATE} />;
};

export const CopyCVV = (): JSX.Element => {
  return <Copy text={DEMO_CVV} />;
};
