import { RefObject, useCallback, useEffect, useState } from 'react';
import { throttle } from 'radash';

const SCROLL_EVENT_NAME = 'scroll';

interface CarouselScrollHookReturn {
  scrollMax: number;
  scrollPosition: number;
}

type CarouselScrollHookProps = RefObject<HTMLDivElement>;

export const useCarouselScroll = (
  carouselRef: CarouselScrollHookProps
): CarouselScrollHookReturn => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollMax, setScrollMax] = useState(0);

  useEffect(() => {
    if (!carouselRef.current) {
      return;
    }

    const target = carouselRef.current;
    setScrollMax(target.scrollWidth - target.clientWidth);
  }, [carouselRef]);

  const handleScroll = useCallback((event: Event) => {
    const target = event.target as HTMLElement;
    setScrollPosition(target.scrollLeft);
  }, []);

  useEffect(() => {
    if (!carouselRef.current) {
      return () => {};
    }

    const target = carouselRef.current;

    const handleScrollWithThrottle = throttle({ interval: 100 }, handleScroll);

    target.addEventListener(SCROLL_EVENT_NAME, handleScrollWithThrottle);

    return () => {
      target.removeEventListener(SCROLL_EVENT_NAME, handleScrollWithThrottle);
    };
  }, [carouselRef, handleScroll]);

  return {
    scrollPosition,
    scrollMax,
  };
};
