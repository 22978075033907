import { Step } from './step/step';

interface StepperProps {
  total: number;
  current: number;
}
export function Stepper({ total, current }: StepperProps): JSX.Element {
  return (
    <div>
      {[...Array(total).keys()].map((value) => (
        <Step key={value} active={value < current} />
      ))}
    </div>
  );
}
