import { Button } from '@app/shared/button/button';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';
import { TextInput } from '@app/shared/text-input/text-input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  RenameAuthenticatorForm,
  useRenameAuthenticatorFormValidation,
} from './use-rename-authenticator-form-validation';

interface RenameAuthenticatorViewProps {
  defaultName: string;
  onSubmit: (name: string) => void;
}

export const RenameAuthenticatorView = (
  props: RenameAuthenticatorViewProps
): JSX.Element => {
  const { defaultName, onSubmit } = props;
  const { t } = useTranslation();

  const formValidationRules = useRenameAuthenticatorFormValidation();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<RenameAuthenticatorForm>({
    mode: 'onChange',
    defaultValues: { name: defaultName },
  });

  const handleTrimThenSubmit = (payload: RenameAuthenticatorForm): void => {
    onSubmit(payload.name.trim());
  };

  return (
    <form onSubmit={handleSubmit(handleTrimThenSubmit)}>
      <DialogLayout>
        <DialogLayout.Body>
          <TextInput
            label={t(
              'manage-authentication-method.webauthn.rename-authenticator.name.label'
            )}
            {...register('name', formValidationRules.name)}
            isTouched={touchedFields?.name}
            errorMessage={errors?.name?.message}
          />
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button
            type="submit"
            label={t(
              'manage-authentication-method.webauthn.rename-authenticator.submit-button'
            )}
            visuallyDisabled={!isValid}
          />
        </DialogLayout.Footer>
      </DialogLayout>
    </form>
  );
};
