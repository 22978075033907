import { PriceCircle } from '../price-circle/price-circle';
import css from './charge-point-pricing.module.scss';

type ChargePointPricingProps = {
  pricePerKwh?: number;
  pricePerMin?: number;
  onClick: () => void;
};

export function ChargePointPricing(
  props: ChargePointPricingProps
): JSX.Element | null {
  const { pricePerKwh = 0, pricePerMin = 0, onClick } = props;

  if (pricePerKwh <= 0 && pricePerMin <= 0) {
    return null;
  }

  return (
    <PriceCircle
      pricePerKwh={pricePerKwh}
      pricePerMin={pricePerMin}
      className={css.price}
      onClick={onClick}
    />
  );
}
