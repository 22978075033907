import { Text, TextProps } from '@app/shared/typography/text/text';
import { useTranslation } from 'react-i18next';

import css from './charge-chart.module.scss';

export const FallbackChargeChart = (props: TextProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Text className={css.fallback} {...props}>
      {t('charging.chart.fallback')}
    </Text>
  );
};
