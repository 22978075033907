import { ReactNode, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import css from './collapsible.module.scss';

type CollapsibleProps = {
  isOpen: boolean;
  children: ReactNode;
};

export const Collapsible = (props: CollapsibleProps): JSX.Element => {
  const { isOpen, children } = props;
  const collapseRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const getWrapperSize = () =>
    wrapperRef.current ? wrapperRef.current.clientHeight : 0;

  const collapsedSize = 0;

  useLayoutEffect(() => {
    if (collapseRef?.current) {
      collapseRef.current.style.maxHeight = `${
        isOpen ? getWrapperSize() : collapsedSize
      }px`;
    }
  }, [isOpen]);

  return (
    <div ref={collapseRef} className={classNames(css.collapsible)}>
      <div ref={wrapperRef}>{children}</div>
    </div>
  );
};
