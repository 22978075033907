import classNames from 'classnames';
import { HTMLProps } from 'react';
import './font/style.css';
import css from './icon.module.scss';

export type IconName =
  | 'refresh'
  | 'clock'
  | 'price'
  | 'power-ac'
  | 'power-dc'
  | 'power-chademo'
  | 'power-type-2-combo'
  | 'power-type-2'
  | 'power-type-1-combo'
  | 'power-type-1'
  | 'power-default'
  | 'power-type-e'
  | 'help-circle'
  | 'credit-card-check'
  | 'credit-card-refresh'
  | 'phone'
  | 'car-profile'
  | 'wire'
  | 'charge-point'
  | 'rename'
  | 'paper-clip'
  | 'reverse-left'
  | 'star-outlined'
  | 'copy'
  | 'settings'
  | 'external-link'
  | 'check'
  | 'edit'
  | 'logout'
  | 'cross'
  | 'chevron-right'
  | 'arrow-left'
  | 'arrow-right'
  | 'warning'
  | 'distance'
  | 'light'
  | 'delmonicos'
  | 'file'
  | 'user-circle'
  | 'credit-card-shield'
  | 'clock-fast-forward'
  | 'clock-refresh'
  | 'euro'
  | 'calendar'
  | 'marker-pin'
  | 'scan'
  | 'home'
  | 'trash'
  | 'information'
  | 'question-circle'
  | 'chevron-up'
  | 'chevron-down';

export interface IconProps extends HTMLProps<HTMLSpanElement> {
  name: IconName;
  color?: 'primary' | 'secondary' | 'tertiary' | 'inherit' | 'light';
  badge?: boolean;
}

export const Icon = ({
  name,
  badge = false,
  color = 'inherit',
  className,
  ...props
}: IconProps): JSX.Element => {
  return (
    <span
      className={classNames(
        `icon-${name}`,
        css.icon,
        color === 'primary' && css.p1,
        color === 'secondary' && css.s1,
        color === 'tertiary' && css.t1,
        color === 'light' && css.light,
        badge && css.badge,
        className
      )}
      {...props}
    />
  );
};
