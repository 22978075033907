import { ChargePointSpecificationModel } from '@app/core/model/charge-point.model';
import { TariffModel } from '@app/core/model/tariff.model';
import { LOCALE_KEYS } from '@app/core/locale/locale';
import { Text } from '@app/shared/typography/text/text';

import i18n from '@app/core/locale/i18n';
import { ChargePointSpecification } from '../charge-point-specification/charge-point-specification';
import { ChargePointPricing } from '../../../tariff/component/charge-point-pricing/charge-point-pricing';
import css from './charge-point-detail.module.scss';
import { FeeWarning } from '../fee-warning/fee-warning';
import { TariffDetail } from '../../../tariff/component/tariff-detail/tariff-detail';
import { HourlyTariffTitle } from '../../../tariff/component/hourly-tariff-title/hourly-tariff-title';

interface ChargePointDetailProps {
  isTariffComplex: boolean;
  tariff?: TariffModel | null;
  specification?: ChargePointSpecificationModel;
  onClick: () => void;
}

export const ChargePointDetail = (
  props: ChargePointDetailProps
): JSX.Element => {
  const { isTariffComplex, tariff, specification, onClick } = props;

  const tariffCaption: string | null =
    tariff?.tariffAltText?.find(
      (item) => item.language === i18n.resolvedLanguage
    )?.text ??
    tariff?.tariffAltText?.find((item) => item.language === LOCALE_KEYS.fr)
      ?.text ??
    null;

  const tariffElements = tariff?.elements ?? [];

  const firstPricingElement = tariff?.elements?.at(0)?.priceComponents;
  return (
    <>
      <HourlyTariffTitle
        startTime={tariffElements.at(0)?.restrictions?.startTime}
        endTime={tariffElements.at(0)?.restrictions?.endTime}
      />

      <div className={css.informations}>
        <div className={css.priceContainer}>
          {isTariffComplex ? (
            <TariffDetail tariffElementList={tariffElements} />
          ) : (
            <ChargePointPricing
              pricePerKwh={firstPricingElement?.perKwhTTC}
              pricePerMin={
                (firstPricingElement?.perTimeChargingHourTTC ?? 0) / 60
              }
              onClick={onClick}
            />
          )}
        </div>

        <ChargePointSpecification {...specification} />
      </div>

      {!isTariffComplex && (firstPricingElement?.flatTTC ?? 0) > 0 && (
        <FeeWarning fee={firstPricingElement?.flatTTC ?? 0} onClick={onClick} />
      )}

      {isTariffComplex && <Text color="secondary">{tariffCaption}</Text>}
    </>
  );
};
