import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { Icon } from '@app/shared/icon/icon';
import { DialogLayout } from '@app/shared/layout/dialog-layout/dialog-layout';

import css from './edit-payment.module.scss';

interface EditPaymentViewProps {
  onContinue: () => void;
  onCancel: () => void;
}

export const EditPaymentView = (props: EditPaymentViewProps): JSX.Element => {
  const { onContinue, onCancel } = props;
  const { t } = useTranslation();

  return (
    <DialogLayout>
      <DialogLayout.Body>
        <Icon color="primary" name="warning" className={css.dialogIcon} />
        <H1>{t('quote.edit-payment-dialog.title')}</H1>

        <Text>
          {t('quote.edit-payment-dialog.warning')}{' '}
          <LearnMoreLink questionId="precapture" />
        </Text>
        <Text>{t('quote.edit-payment-dialog.are-you-sure')}</Text>
      </DialogLayout.Body>

      <DialogLayout.Footer>
        <Button
          label={t('quote.edit-payment-dialog.cancel')}
          variant="outlined"
          onClick={onCancel}
        />
        <Button
          label={t('quote.edit-payment-dialog.continue')}
          onClick={onContinue}
        />
      </DialogLayout.Footer>
    </DialogLayout>
  );
};
