import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Text } from '@app/shared/typography/text/text';

import css from './recharge-card.module.scss';

interface IsChargingBodyProps {
  chargePointName?: string;
  className?: string;
}

export const IsChargingBody = (props: IsChargingBodyProps): JSX.Element => {
  const { chargePointName, className } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(css.isChargingBody, className)}>
      <Text bold fontSize="large">
        {t('dashboard.recharge-card.is-charging.title')}
      </Text>
      <Text fontSize="large">{chargePointName}</Text>
    </div>
  );
};
