import classNames from 'classnames';

import { Card } from '@app/shared/card/card';
import PowerSocket from '@app/asset/power-socket.svg';
import PowerSocketWire from '@app/asset/power-socket-wire.svg';
import { Icon } from '@app/shared/icon/icon';

import css from './recharge-card.module.scss';
import { IsChargingBody } from './is-charging-body';
import { LastChargeBody } from './last-charge-body';

interface RechargeCardProps {
  chargePointName?: string;
  startedAt?: Date;
  isCharging: boolean;
  onClick: () => void;
}

export function RechargeCard(props: RechargeCardProps): JSX.Element {
  const { chargePointName, startedAt, isCharging, onClick } = props;

  return (
    <button onClick={onClick} className={css.button}>
      <Card
        background={isCharging ? 'paper' : 'colored'}
        radius="full"
        className={classNames(css.card, isCharging && css.cardIsCharging)}>
        <div className={css.socketWireContainer}>
          {isCharging ? (
            <PowerSocketWire className={css.socketWire} />
          ) : (
            <PowerSocket />
          )}
        </div>

        {isCharging ? (
          <IsChargingBody
            chargePointName={chargePointName}
            className={css.body}
          />
        ) : (
          <LastChargeBody
            startedAt={startedAt}
            chargePointName={chargePointName}
            className={css.body}
          />
        )}

        {(isCharging || (startedAt && chargePointName)) && (
          <Icon name="chevron-right" />
        )}
      </Card>
    </button>
  );
}
