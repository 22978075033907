import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Text } from '@app/shared/typography/text/text';

interface InChargeFooterProps {
  onClick: () => void;
}

export function InChargeFooter(props: InChargeFooterProps): JSX.Element {
  const { onClick } = props;

  const { t } = useTranslation();

  return (
    <>
      <Text>{t('charge-point.footer.is-charge-started-caption')}</Text>
      <Button
        variant="outlined"
        label={t('charge-point.footer.go-to-charge-button')}
        onClick={onClick}
      />
    </>
  );
}
