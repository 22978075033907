import { useCallback } from 'react';

import { useDialog } from '@app/shared/dialog/use-dialog';
import { WebauthnErrorDialog } from '@app/shared/webauthn-error-dialog/webauthn-error-dialog';
import { ErrorCodeEnum, HttpStatusEnum } from '@app/config/error-config';
import { ApiError } from '@app/core/error/api-error';
import { showErrorMessage } from '@app/core/error/show-error-message';

import { WebauthnAuthenticationView } from './webauthn-authentication.view';
import { AuthenticationMethodProps } from '../../authentication-method.props';
import { useWebauthnAuthentication } from './use-webauthn-authentication';

interface WebauthnAuthenticationControllerProps
  extends AuthenticationMethodProps {
  pseudo: string;
  onSwitchToEmailAuthentication: () => void;
  onBack?: () => void;
}

export const WebauthnAuthenticationController = ({
  pseudo,
  onAuthentication,
  onSwitchToEmailAuthentication,
  onBack = () => {},
}: WebauthnAuthenticationControllerProps): JSX.Element => {
  const {
    isDialogOpen: isErrorDialogOpen,
    openDialog: openErrorDialog,
    closeDialog: closeErrorDialog,
  } = useDialog();

  const handleError = useCallback(
    (error: ApiError) => {
      if (error.status === HttpStatusEnum.NOT_FOUND) {
        return showErrorMessage(
          'account-not-found',
          ErrorCodeEnum.ACCOUNT_NOT_FOUND
        );
      }

      return openErrorDialog();
    },
    [openErrorDialog]
  );

  const { pending, onStartWebauthn, remainingLockTimeInMin } =
    useWebauthnAuthentication({
      onError: handleError,
      onSuccess: onAuthentication,
    });

  const handleStart = useCallback(() => {
    return onStartWebauthn(pseudo);
  }, [pseudo, onStartWebauthn]);

  const handleRetry = useCallback(async () => {
    closeErrorDialog();
    handleStart();
  }, [closeErrorDialog, handleStart]);

  return (
    <>
      <WebauthnAuthenticationView
        remainingLockTimeInMin={remainingLockTimeInMin}
        pending={pending}
        onBack={onBack}
        onStart={handleStart}
        onUseEmail={onSwitchToEmailAuthentication}
      />
      <WebauthnErrorDialog
        onRetry={handleRetry}
        onClose={closeErrorDialog}
        open={isErrorDialogOpen}
      />
    </>
  );
};
