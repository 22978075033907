import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SECURITY_PATH } from '@app/config/app-paths.const';
import { WithUserProps } from '@app/router/guard/with-user.guard';

import { ManageAuthenticationMethodView } from './manage-authentication-method.view';

export const ManageAuthenticationMethodController = (
  props: WithUserProps
): JSX.Element => {
  const navigate = useNavigate();

  const { user, refresh: refreshUser } = props;

  const handleBack = useCallback(() => {
    navigate(SECURITY_PATH);
  }, [navigate]);

  return (
    <ManageAuthenticationMethodView
      webauthnAuthenticators={user.authenticators.webauthn}
      isEmailVerified={!!user.isEmailAddressVerified}
      emailAddress={user.emailAddress}
      onRefresh={refreshUser}
      onBack={handleBack}
    />
  );
};
