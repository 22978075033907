import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from '@app/shared/typography/link/link';
import { Icon } from '@app/shared/icon/icon';

import css from './fee-warning.module.scss';

interface FeeWarningProps extends Omit<LinkProps, 'children'> {
  fee: number;
  onClick: () => void;
}

export const FeeWarning = (props: FeeWarningProps): JSX.Element => {
  const { fee, onClick } = props;
  const { t } = useTranslation();

  return (
    <Link
      onClick={onClick}
      endIcon={<Icon name="question-circle" className={css.icon} />}>
      {t('charge-point.fee-warning', {
        fee,
      })}
    </Link>
  );
};
