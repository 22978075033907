import { TariffModel } from '@app/core/model/tariff.model';
import { Drawer, DrawerProps } from '@app/shared/drawer/drawer';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';
import { Icon } from '@app/shared/icon/icon';
import { H1 } from '@app/shared/typography/heading/heading';
import { useTranslation } from 'react-i18next';
import { TariffDetailDrawerBody } from './tariff-detail-drawer-body';

import css from './tariff-detail-drawer.module.scss';

type Props = DrawerProps & {
  tariff: TariffModel;
};

export function TariffDetailDrawer(props: Props): JSX.Element {
  const { t } = useTranslation();
  const { tariff, onClose, ...drawerProps } = props;

  return (
    <Drawer {...drawerProps} onClose={onClose}>
      <DrawerLayout>
        <Icon name="cross" className={css.closeIcon} onClick={onClose} />

        <DrawerLayout.Header>
          <H1>{t('tariff.drawer.title')}</H1>
        </DrawerLayout.Header>

        <TariffDetailDrawerBody tariffElements={tariff.elements} />

        <DrawerLayout.Body />
      </DrawerLayout>
    </Drawer>
  );
}
