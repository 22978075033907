import classNames from 'classnames';
import css from './start-charging-carousel.module.scss';
import type { StepComponent } from '../step/step.props';
import {
  StartChargingCarouselConfig,
  StartChargingCarouselTypeEnum,
} from './start-charging-carousel.config';

interface StartChargingCarouselProps {
  configuration?: StartChargingCarouselTypeEnum;
}

export const StartChargingCarousel = (
  props: StartChargingCarouselProps
): JSX.Element => {
  const { configuration = StartChargingCarouselTypeEnum.ac } = props;

  const stepComponents: StepComponent[] =
    StartChargingCarouselConfig[configuration];

  return (
    <>
      <div
        className={classNames(
          css.carousel,
          css[`carousel${stepComponents.length}`],
          css.animate
        )}>
        {stepComponents.map((Component, index) => (
          <Component step={index + 1} key={Component.name} />
        ))}
      </div>

      <div
        className={classNames(
          css.indicator,
          css[`indicator${stepComponents.length}`],
          css.animate
        )}>
        {stepComponents.map((Component) => (
          <div key={Component.name} />
        ))}
      </div>
    </>
  );
};
