import { useCallback, useEffect, useMemo, useState } from 'react';

import { JWT_LSK } from '@app/config/localstorage-keys.const';
import { Fade } from '@app/shared/fade/fade';
import {
  getFromLocalStorage,
  removeInLocalStorage,
  setInLocalStorage,
} from '@app/core/storage/local-storage';
import { WebsocketClientService } from '@app/core/client/websocket/websocket-client.service';
import { AsyncProviderProps } from '@app/core/context-providers/providers.props';
import { JwtModel } from '@app/core/model/jwt.model';
import { TIMEOUT } from '@app/config/timeout.const';
import { AUTHENTICATE_WST } from '@app/config/api-routes.const';

import {
  AuthenticationContext,
  IAuthenticationContext,
} from './authentication-context';

export function AuthenticationContextProvider({
  onReady,
  children,
}: AsyncProviderProps): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAuthenticationReady, setIsAuthenticationReady] = useState(false);

  const disconnect = useCallback((): void => {
    removeInLocalStorage(JWT_LSK);
    setIsAuthenticated(false);
    WebsocketClientService.getInstance().reset();
  }, []);

  const authenticateWs = useCallback((jwt: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const rejectAfterXSeconds = setTimeout(() => {
        reject(new Error('Websocket authentication timeout'));
      }, TIMEOUT.default * 1000);

      WebsocketClientService.getInstance().emit(
        AUTHENTICATE_WST,
        { jwt },
        (response: { success: boolean }) => {
          clearTimeout(rejectAfterXSeconds);

          return response.success
            ? resolve()
            : reject(new Error('Websocket authentication response failed'));
        }
      );
    });
  }, []);

  const authenticate = useCallback(
    (token: string): void => {
      setInLocalStorage(JWT_LSK, token);
      authenticateWs(token).catch(disconnect);

      setIsAuthenticated(true);
    },
    [disconnect, authenticateWs]
  );

  /*
  That function blocks rendering until it resolves. For UX concerns, make sure to return asap.
  */
  useEffect(() => {
    const recoveredToken: string | null = getFromLocalStorage(JWT_LSK);

    if (recoveredToken) {
      const jwt = new JwtModel(recoveredToken);

      if (!jwt.isExpired()) {
        setIsAuthenticated(true);
      }
    }

    onReady();
    setIsAuthenticationReady(true);
  }, [onReady]);

  useEffect(() => {
    WebsocketClientService.getInstance().onConnect(() => {
      const recoveredToken = getFromLocalStorage(JWT_LSK);

      if (recoveredToken) {
        authenticate(recoveredToken);
      }
    });
  }, [authenticate]);

  const value: IAuthenticationContext = useMemo<IAuthenticationContext>(
    () => ({
      isAuthenticated,
      authenticate,
      disconnect,
    }),
    [isAuthenticated, authenticate, disconnect]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      <Fade show={isAuthenticationReady}>{children}</Fade>
    </AuthenticationContext.Provider>
  );
}
