import { useTranslation } from 'react-i18next';

import CameraError from '@app/asset/camera-error.svg';

import { Button } from '../button/button';
import { Dialog, DialogProps } from '../dialog/dialog';
import { H1 } from '../typography/heading/heading';
import { Text } from '../typography/text/text';
import { DialogLayout } from '../layout/dialog-layout/dialog-layout';

import css from './account-not-found-error-dialog.module.scss';

export interface AccountNotFoundDialogErrorProps extends DialogProps {
  useEmail?: boolean;
  onCancel: () => void;
}

export const AccountNotFoundErrorDialog = ({
  useEmail = false,
  onCancel,
  onClose,
  ...dialogProps
}: AccountNotFoundDialogErrorProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogLayout>
        <DialogLayout.Body>
          <CameraError className={css.img} />

          <H1>{t('shared.account-not-found-error-dialog.title')}</H1>
          <Text>
            {t(
              `shared.account-not-found-error-dialog.info-${
                useEmail ? 'email' : 'webauthn'
              }`
            )}
          </Text>
        </DialogLayout.Body>

        <DialogLayout.Footer>
          <Button
            label={t('shared.account-not-found-error-dialog.continue-button')}
            onClick={onCancel}
          />
        </DialogLayout.Footer>
      </DialogLayout>
    </Dialog>
  );
};
