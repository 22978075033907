import { useTranslation } from 'react-i18next';

import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { WebauthnAuthenticatorModel } from '@app/core/model/authenticator.model';

import { ManageWebauthnAuthenticationController } from './manage-webauthn-authentication/manage-webauthn-authentication.controller';
import { ManageEmailAuthenticationView } from './manage-email-authentication/manage-email-authentication.view';

import css from './manage-authentication-method.module.scss';

interface ManageAuthenticationMethodViewProps {
  webauthnAuthenticators?: WebauthnAuthenticatorModel[];
  isEmailVerified: boolean;
  emailAddress?: string;
  onBack: () => void;
  onRefresh: () => void;
}

export const ManageAuthenticationMethodView = (
  props: ManageAuthenticationMethodViewProps
): JSX.Element => {
  const {
    webauthnAuthenticators = [],
    emailAddress = '',
    isEmailVerified,
    onBack,
    onRefresh,
  } = props;
  const { t } = useTranslation();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />

      <PageLayout.Header>
        <H1>{t('manage-authentication-method.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <ManageWebauthnAuthenticationController
          authenticators={webauthnAuthenticators}
        />
        <ManageEmailAuthenticationView
          emailAddress={emailAddress}
          isEmailVerified={isEmailVerified}
          onRefresh={onRefresh}
        />
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
