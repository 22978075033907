import { Spinner } from '@app/shared/spinner/spinner';
import { useTypedTranslation } from '@app/core/helper/use-typed-translation';
import { ItemMenu } from '@app/shared/item-menu/item-menu';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import { ClearStorageController } from '@app/feature/legal-text/component/clear-storage-dialog/clear-storage.controller';
import { Text } from '@app/shared/typography/text/text';

import css from './legal-text.module.scss';

import { LegalText } from '../../core/model/legal-text';
import { DownloadPersonalInformationController } from './component/download-personal-information/download-personal-information.controller';

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

interface LegalTextViewProps {
  version?: string;
  pending: boolean;
  legalTextList: LegalText[];
  pseudo?: string;
  onSelect: (legalText: LegalText) => void;
  onBack: () => void;
}

export const LegalTextView = (props: LegalTextViewProps): JSX.Element => {
  const { version, pending, legalTextList, pseudo, onSelect, onBack } = props;

  const { t } = useTypedTranslation();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />

      <PageLayout.Header>
        <H1>{t('legal-text.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        {pending && <Spinner />}

        <ListItemMenu className={css.listContainer}>
          {legalTextList.map((legalText) => (
            <ItemMenu
              key={legalText.id}
              label={capitalizeFirstLetter(legalText.title)}
              onClick={() => onSelect(legalText)}
            />
          ))}
        </ListItemMenu>

        <div className={css.footerLink}>
          {pseudo && <DownloadPersonalInformationController pseudo={pseudo} />}

          <ClearStorageController />

          {version && (
            <Text fontSize="xs">{t('legal-text.version', { version })}</Text>
          )}
        </div>
      </PageLayout.Body>

      {pseudo && <PageLayout.AppbarFooter />}
    </PageLayout>
  );
};
