import { useCallback, useMemo, useState } from 'react';
import { EMAIL_LSK, PSEUDO_LSK } from '@app/config/localstorage-keys.const';
import { getFromLocalStorage } from '@app/core/storage/local-storage';

import { AuthenticationController } from '../authentication/authentication.controller';
import { RegistrationController } from '../registration/registration.controller';
import { RecoverAccountController } from '../recover-account/recover-account.controller';
import { RegisterOrRecover } from './component/register-or-recover';

enum RegisterOrRecoverEnum {
  REGISTER_OR_RECOVER,
  REGISTER,
  RECOVER,
}

interface HomeDrawerProps {
  onCancel: () => void;
}

export const HomeDrawer = (props: HomeDrawerProps): JSX.Element => {
  const { onCancel } = props;

  const [registerOrRecoverState, setRegisterOrRecoverState] = useState(
    RegisterOrRecoverEnum.REGISTER_OR_RECOVER
  );

  const pseudo = useMemo(() => getFromLocalStorage(PSEUDO_LSK), []);
  const [emailAddress, setEmailAddress] = useState(
    getFromLocalStorage(EMAIL_LSK) ?? ''
  );

  const handleGoToRegisterOrRecover = useCallback(() => {
    setRegisterOrRecoverState(RegisterOrRecoverEnum.REGISTER_OR_RECOVER);
  }, []);

  const handleGoToRegister = useCallback(() => {
    setRegisterOrRecoverState(RegisterOrRecoverEnum.REGISTER);
  }, []);

  const handleGoToRecover = useCallback((email?: string) => {
    if (email) {
      setEmailAddress(email);
    }

    setRegisterOrRecoverState(RegisterOrRecoverEnum.RECOVER);
  }, []);

  if (pseudo !== null && emailAddress !== null) {
    return (
      <AuthenticationController
        emailAddress={emailAddress}
        pseudo={pseudo}
        onBack={onCancel}
      />
    );
  }

  switch (registerOrRecoverState) {
    case RegisterOrRecoverEnum.REGISTER:
      return (
        <RegistrationController
          onOpenAuthentication={handleGoToRecover}
          onBack={handleGoToRegisterOrRecover}
        />
      );

    case RegisterOrRecoverEnum.RECOVER:
      return (
        <RecoverAccountController
          emailAddress={emailAddress}
          onNavigateToRegistration={handleGoToRegister}
          onBack={handleGoToRegisterOrRecover}
        />
      );

    default:
      return (
        <RegisterOrRecover
          onCancel={onCancel}
          onRecover={handleGoToRecover}
          onRegister={handleGoToRegister}
        />
      );
  }
};
