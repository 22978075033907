import { useTranslation } from 'react-i18next';

import { Counter } from '@app/shared/counter/counter';

import css from './live-duration.module.scss';

interface LiveDurationViewProps {
  duration: number;
}

export const LiveDurationView = ({
  duration,
}: LiveDurationViewProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={css.container}>
      <Counter
        className={css.tag}
        icon="clock-refresh"
        value={t('charging.live-duration', {
          duration,
        })}
      />
    </div>
  );
};
