import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@app/shared/button/button';
import { Icon } from '@app/shared/icon/icon';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { TextInput } from '@app/shared/text-input/text-input';
import { UserModel } from '@app/core/model/user.model';
import {
  ManagePersonalInformationForm,
  useManagePersonalInformationFormValidation,
} from '@app/feature/account/view/user-security/view/manage-personal-information/use-manage-personal-information-form-validation';

import css from './manage-personal-information.module.scss';

interface ManagePersonalInformationViewProps {
  user?: UserModel;
  pending: boolean;
  onSubmit: (data: ManagePersonalInformationForm) => void;
  onBack: () => void;
}
export function ManagePersonalInformationView({
  user,
  pending = false,
  onSubmit = () => {},
  onBack = () => {},
}: ManagePersonalInformationViewProps): JSX.Element {
  const { t } = useTranslation();

  const formValidationRules = useManagePersonalInformationFormValidation();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<ManagePersonalInformationForm>({
    mode: 'onChange',
    defaultValues: user,
  });

  const handleTrimThenSubmit = (data: ManagePersonalInformationForm) => {
    onSubmit({
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
    });
  };

  return (
    <form onSubmit={handleSubmit(handleTrimThenSubmit)} className={css.form}>
      <PageLayout>
        <BackLink onClick={onBack} />

        <PageLayout.Header>
          <H1>{t('personal-information.title')}</H1>
        </PageLayout.Header>

        <PageLayout.Body>
          <TextInput
            label={t('personal-information.text-input-firstname')}
            {...register('firstName', formValidationRules.firstName)}
            isTouched={touchedFields?.firstName}
            errorMessage={errors?.firstName?.message}
            adornment={
              <Icon
                name={errors?.firstName?.message ? 'cross' : 'check'}
                color="secondary"
              />
            }
          />
          <TextInput
            label={t('personal-information.text-input-lastname')}
            {...register('lastName', formValidationRules.lastName)}
            isTouched={touchedFields?.lastName}
            errorMessage={errors?.lastName?.message}
            adornment={
              <Icon
                name={errors?.lastName?.message ? 'cross' : 'check'}
                color="secondary"
              />
            }
          />
        </PageLayout.Body>

        <PageLayout.Footer>
          <Button
            type="submit"
            visuallyDisabled={!isValid}
            label={t('personal-information.save-button')}
            loading={pending}
          />
        </PageLayout.Footer>
      </PageLayout>
    </form>
  );
}
