import { useTranslation } from 'react-i18next';

import { ChargingCarAnim } from '@app/shared/charging-car-anim/charging-car-anim';
import { Text } from '@app/shared/typography/text/text';
import { LearnMoreLink } from '@app/feature/faq/component/learn-more-link/learn-more-link';
import { ChargeStateEnum } from '@app/core/model/enum/charge-state.enum';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';

import { ChargeChartController } from '../charge-chart/charge-chart.controller';
import { LastDataReceivedView } from '../last-data-received/last-data-received.view';
import { LiveDurationController } from '../live-duration/live-duration.controller';

import css from './charging.module.scss';

export const Charging = (): JSX.Element => {
  const { startedAt, updatedAt, totalKwh, totalTTC, chargeState } = useCharge();
  const { t } = useTranslation();

  return (
    <>
      <ChargingCarAnim animate />
      {startedAt && (
        <LiveDurationController
          startedAt={startedAt}
          isStopped={chargeState === ChargeStateEnum.STOP_CHARGE_REQUESTED}
        />
      )}

      <LastDataReceivedView
        lastDataReceived={updatedAt}
        energyDelivered={totalKwh}
        estimatedPrice={totalTTC}
      />

      <ChargeChartController className={css.chart} />

      <Text className={css.hint}>
        {t('charging.provisional-information')}
        <LearnMoreLink questionId="data-given-as-an-indication-during-charging" />
      </Text>
    </>
  );
};
