import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { INVOICE_DASHBOARD_PATH } from '@app/config/app-paths.const';
import { WithUserProps } from '@app/router/guard/with-user.guard';

import { OrderModel } from '@app/core/model/order.model';
import { orderService } from '@app/core/service/order.service';
import { catchApiError } from '@app/core/error/catch-api-error';
import { HttpStatusEnum } from '@app/config/error-config';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { AbortablePromise } from '@app/core/helper/abort-promise';
import { invoicingService } from '@app/core/service/invoicing.service';
import { InvoiceModel } from '@app/core/model/invoice.model';

import { ChargeSummaryView } from './charge-summary.view';

export const ChargeSummaryController = (props: WithUserProps): JSX.Element => {
  const { user } = props;
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderModel>();
  const [invoice, setInvoice] = useState<InvoiceModel>();

  const handleLoadLatestOrder =
    useCallback((): AbortablePromise<OrderModel | null> => {
      const [orderPromise, abortOrderPromise] = orderService.getLatest();

      const managedPromise = orderPromise
        .then((data: OrderModel) => {
          setOrder(data);
          return data;
        })
        .catch((err) => {
          catchApiError(err, (error) => {
            if (error.status !== HttpStatusEnum.NOT_FOUND) {
              showErrorMessage('get-order', error.code);
              navigate(INVOICE_DASHBOARD_PATH);
            }
          });

          return null;
        });

      return [managedPromise, abortOrderPromise];
    }, [navigate]);

  const handleLoadLatestInvoice = useCallback((): AbortController => {
    const [invoicePromise, abortInvoicePromise] = invoicingService.getLatest();

    invoicePromise
      .then((data: InvoiceModel | null) => {
        if (data) {
          setInvoice(data);
          return;
        }

        navigate(INVOICE_DASHBOARD_PATH);
      })
      .catch(() => {
        navigate(INVOICE_DASHBOARD_PATH);
      });

    return abortInvoicePromise;
  }, [navigate]);

  useEffect(() => {
    const [orderPromise, abortOrder] = handleLoadLatestOrder();
    let abortInvoice: AbortController;

    orderPromise.then((response: OrderModel | null) => {
      if (!response) {
        abortInvoice = handleLoadLatestInvoice();
      }
    });

    return () => {
      abortOrder.abort();

      if (abortInvoice) {
        abortInvoice.abort();
      }
    };
  }, [handleLoadLatestOrder, handleLoadLatestInvoice]);

  return (
    <ChargeSummaryView
      isEmailAddressVerified={user.isEmailAddressVerified}
      autoSendInvoiceByMail={user?.preferences?.autoSendInvoiceByMail}
      orderOrInvoice={order ?? invoice}
    />
  );
};
