import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACCOUNT_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
} from '@app/config/app-paths.const';
import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { ItemDropdownMenu } from '@app/shared/dropdown-menu/item-dropdown-menu';
import { useUser } from '@app/core/context-providers/user-context/use-user';

import { Avatar } from '../avatar/avatar';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { useDialog } from '../dialog/use-dialog';

export interface AvatarDropdownMenuHeaderProps {
  className?: string;
}

export const AvatarDropdownMenuHeader = (
  props: AvatarDropdownMenuHeaderProps
): JSX.Element | null => {
  const navigate = useNavigate();
  const { disconnect } = useAuthentication();
  const { user } = useUser();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const handleNavigate = useCallback(
    (path: string) => {
      closeDialog();
      navigate(path);
    },
    [closeDialog, navigate]
  );

  const handleDisconnect = useCallback(() => {
    disconnect();
    navigate(HOME_PATH);
  }, [disconnect, navigate]);

  const itemsDropdownMenu: ItemDropdownMenu[] = useMemo(
    () => [
      {
        label: 'dashboard.items-dropdown-menu.dashboard',
        action: () => handleNavigate(DASHBOARD_PATH),
        icon: 'user-circle',
      },
      {
        label: 'dashboard.items-dropdown-menu.setting',
        action: () => handleNavigate(ACCOUNT_PATH),
        icon: 'settings',
      },
      {
        label: 'dashboard.items-dropdown-menu.logout',
        action: handleDisconnect,
        icon: 'logout',
      },
    ],
    [handleDisconnect, handleNavigate]
  );

  return user ? (
    <>
      <Avatar
        {...props}
        firstName={user.firstName}
        lastName={user.lastName}
        onClick={openDialog}
      />
      <DropdownMenu
        open={isDialogOpen}
        items={itemsDropdownMenu}
        onClose={closeDialog}
      />
    </>
  ) : null;
};
