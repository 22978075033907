import { useTranslation } from 'react-i18next';

import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';
import { ListItemMenu } from '@app/shared/list-item-menu/list-item-menu';
import {
  PatchUserPreferenceModel,
  UserPreferenceKey,
  UserPreferenceModel,
} from '@app/core/model/user-preference.model';

import css from './user-preference.module.scss';
import { UserPreferenceCard } from './component/user-preference-card/user-preference.card';

interface UserPreferenceViewProps {
  isEmailAddressVerified?: boolean;
  pending?: PatchUserPreferenceModel;
  isAllActive: boolean;
  onChangeAll: (value: boolean) => void;
  userPreference?: UserPreferenceModel;
  onChange: (key: UserPreferenceKey, value: boolean) => void;
  onBack: () => void;
}

export function UserPreferenceView({
  isEmailAddressVerified = false,
  pending,
  isAllActive,
  onChangeAll,
  userPreference,
  onChange,
  onBack = () => {},
}: UserPreferenceViewProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />

      <PageLayout.Header>
        <H1>{t('user-preference.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body className={css.body}>
        <UserPreferenceCard
          label={t('user-preference.all.label')}
          pending={
            pending?.autoSendPaymentTicketByMail &&
            pending?.autoSendInvoiceByMail
          }
          isActive={isAllActive}
          onChange={onChangeAll}
        />

        <ListItemMenu>
          <UserPreferenceCard
            requireEmailVerified={!isEmailAddressVerified}
            label={t('user-preference.auto-send-charge-end-by-mail.label')}
            caption={t('user-preference.auto-send-charge-end-by-mail.caption')}
            isActive={userPreference?.autoSendChargeEndByMail ?? false}
            pending={pending?.autoSendChargeEndByMail}
            name="autoSendChargeEndByMail"
            onChange={(state) => onChange('autoSendChargeEndByMail', state)}
          />

          <UserPreferenceCard
            requireEmailVerified={!isEmailAddressVerified}
            label={t('user-preference.auto-send-payment-ticket-by-mail.label')}
            caption={t(
              'user-preference.auto-send-payment-ticket-by-mail.caption'
            )}
            isActive={userPreference?.autoSendPaymentTicketByMail ?? false}
            pending={pending?.autoSendPaymentTicketByMail}
            name="autoSendPaymentTicketByMail"
            onChange={(state) => onChange('autoSendPaymentTicketByMail', state)}
          />

          <UserPreferenceCard
            requireEmailVerified={!isEmailAddressVerified}
            label={t('user-preference.auto-send-invoice-by-mail.label')}
            caption={t('user-preference.auto-send-invoice-by-mail.caption')}
            isActive={userPreference?.autoSendInvoiceByMail ?? false}
            pending={pending?.autoSendInvoiceByMail}
            name="autoSendInvoiceByMail"
            onChange={(state) => onChange('autoSendInvoiceByMail', state)}
          />

          <UserPreferenceCard
            label={t('user-preference.enable-pseudo-check.label')}
            caption={t('user-preference.enable-pseudo-check.caption')}
            isActive={userPreference?.enablePseudoCheck ?? false}
            pending={pending?.enablePseudoCheck}
            name="enablePseudoCheck"
            onChange={(state) => onChange('enablePseudoCheck', state)}
          />
        </ListItemMenu>
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
}
