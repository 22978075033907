import { useCallback } from 'react';

import { Logger } from '@app/core/logger/logger';
import { showErrorMessage } from '@app/core/error/show-error-message';
import { Spinner } from '@app/shared/spinner/spinner';
import {
  DEFAULT_CHECKOUT_MESSAGE,
  ErrorCodeEnum,
} from '@app/config/error-config';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { usePaymentWidgetConfiguration } from '@app/feature/payment/use-payment-widget-configuration';
import { paymentTransactionService } from '@app/core/service/payment-transaction.service';
import { PaymentTypeTokenEnum } from '@app/core/model/enum/payment-type-token.enum';
import { RedirectUrlResponseModel } from '@app/core/model/redirect-url-response.model';
import { mapCheckoutErrorToTranslationKey } from '@app/core/helper/map-checkout-error-to-translation-key';
import { applePayService } from '@app/core/service/apple-pay.service';

import { ApplePayView } from './apple-pay.view';
import { SelectPaymentProps } from '../../select-payment.props';

export const ApplePayController = ({
  onSubmit,
}: SelectPaymentProps): JSX.Element => {
  const { configuration } = usePaymentWidgetConfiguration();

  const handleSessionValidation = useCallback(
    (appleUrl: string): Promise<unknown> => {
      const [promise] = applePayService.validateSession({ appleUrl });
      return promise;
    },
    []
  );

  const handleBegin = useCallback(async () => {
    if (!configuration) {
      return;
    }

    const applePaySession = new ApplePaySession(
      configuration.apple.version,
      configuration.apple.paymentRequest
    );

    applePaySession.begin();

    applePaySession.onvalidatemerchant = (
      event: ApplePayJS.ApplePayValidateMerchantEvent
    ): void => {
      handleSessionValidation(event.validationURL)
        .then((merchantSession: unknown) => {
          applePaySession.completeMerchantValidation(merchantSession);
        })
        .catch((error) => {
          showErrorMessage(
            DEFAULT_CHECKOUT_MESSAGE,
            ErrorCodeEnum.APPLE_SESSION_VALIDATION_FAILED
          );

          Logger.error('error on handleSessionValidation', error);
        });
    };

    applePaySession.oncancel = () => {
      showErrorMessage('apple-user-cancel', ErrorCodeEnum.APPLE_CANCEL);
    };

    applePaySession.onpaymentauthorized = (
      event: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ): void => {
      const applePaymentToken: string = JSON.stringify(
        event.payment.token.paymentData
      );

      const [getITransationIdPromise] =
        paymentTransactionService.createTransactionFromProvider({
          data: applePaymentToken,
          dataType: PaymentTypeTokenEnum.APPLE,
        });

      getITransationIdPromise
        .then((preCaptureResponse: RedirectUrlResponseModel) => {
          applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
          onSubmit(preCaptureResponse);
        })
        .catch((error: unknown) => {
          applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
          Logger.error('onpaymentauthorized', error);

          catchApiError(error, (apiError: ApiError) => {
            const translationKey = mapCheckoutErrorToTranslationKey(
              apiError.code
            );
            showErrorMessage(translationKey, apiError.code);
          });
        });
    };
  }, [configuration, handleSessionValidation, onSubmit]);

  return configuration ? (
    <ApplePayView onBegin={handleBegin} cdn={configuration.apple.cdn} />
  ) : (
    <Spinner translationKey="shared.spinner.apple-pay-configuration" />
  );
};
