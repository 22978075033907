import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { ConnectorTypeEnum } from '@app/core/model/enum/connector-type.enum';
import { Text, TextProps } from '@app/shared/typography/text/text';
import { Icon } from '@app/shared/icon/icon';

import { useChargePointConnectorType } from '../connector-type/use-charge-point-connector-type';

interface ChargePointNameProps extends TextProps {
  name?: string;
  connectorType?: ConnectorTypeEnum;
}

export const ChargePointName = (props: ChargePointNameProps): JSX.Element => {
  const { name, connectorType, ...textProps } = props;

  const { t } = useTranslation();
  const connector = useChargePointConnectorType(connectorType);

  const translationKey: string = useMemo(() => {
    if (name && connectorType) {
      return 'with-name-and-connector';
    }

    if (name) {
      return 'with-name';
    }

    if (connectorType) {
      return 'with-connector';
    }

    return 'with-nothing';
  }, [name, connectorType]);

  return (
    <Text {...textProps}>
      <Icon name="charge-point" />
      {t(`shared.charge-point.name.${translationKey}`, { name, connector })}
    </Text>
  );
};
