type GroupByReturnType<T> = Record<string, T[]>;

export function groupBy<T = any>(
  array: T[],
  predicate: (value: T) => string
): GroupByReturnType<T> {
  return array.reduce((acc, value) => {
    if (!acc[predicate(value)]) {
      acc[predicate(value)] = [];
    }

    acc[predicate(value)].push(value);
    return acc;
  }, {} as GroupByReturnType<T>);
}
