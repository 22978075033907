import { HTMLProps, useRef } from 'react';
import classnames from 'classnames';

import { ScrollIndicator } from './scroll-indicator';
import css from './carousel.module.scss';
import { useCarouselScroll } from './use-carousel-scroll';

interface CarouselProps extends HTMLProps<HTMLDivElement> {}

export const Carousel = ({
  className,
  ...divProps
}: CarouselProps): JSX.Element => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { scrollPosition, scrollMax } = useCarouselScroll(carouselRef);

  return (
    <>
      <div
        className={classnames(className, css.horizontalScroll)}
        ref={carouselRef}
        {...divProps}
      />

      <div className={css.scrollIndicator}>
        {scrollMax > 0 && (
          <ScrollIndicator
            scrollPosition={scrollPosition}
            scrollMax={scrollMax}
          />
        )}
      </div>
    </>
  );
};
