import { useCallback, useEffect } from 'react';
import { FrameCardTokenizedEvent } from 'frames-react';

import { useCheckoutFrameOnToken } from '@app/feature/payment/view/checkout-frame/use-checkout-frame-on-token';
import { Spinner } from '@app/shared/spinner/spinner';

import { SelectPaymentProps } from '../../select-payment.props';
import { CheckoutFrameView } from './checkout-frame.view';
import { usePaymentWidgetConfiguration } from '../../use-payment-widget-configuration';

interface CheckoutFrameControllerProps extends SelectPaymentProps {
  onBack?: () => void;
  forceSave?: boolean;
  withPrecapture?: boolean;
  onError?: () => void;
}

export const CheckoutFrameController = ({
  forceSave = false,
  withPrecapture = true,
  onSubmit,
  onBack = () => {},
  onError = () => {},
}: CheckoutFrameControllerProps): JSX.Element => {
  const { configuration, isError: isErrorOnLoadingPaymentWidgetConfiguration } =
    usePaymentWidgetConfiguration();

  const onToken = useCheckoutFrameOnToken({ forceSave, withPrecapture });

  const handleToken = useCallback(
    async (event: FrameCardTokenizedEvent, mustSavePaymentMethod: boolean) => {
      const response = await onToken(event, mustSavePaymentMethod);
      onSubmit(response);
    },
    [onSubmit, onToken]
  );

  useEffect(() => {
    if (isErrorOnLoadingPaymentWidgetConfiguration) {
      onError();
    }
  }, [isErrorOnLoadingPaymentWidgetConfiguration, onError]);

  return configuration?.checkout ? (
    <CheckoutFrameView
      configuration={configuration.checkout}
      onToken={handleToken}
      onBack={onBack}
      forceSave={forceSave}
    />
  ) : (
    <Spinner translationKey="shared.spinner.checkout-configuration" />
  );
};
