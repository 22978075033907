import { HTMLProps } from 'react';
import classNames from 'classnames';

import css from './list-item-menu.module.scss';

export const ListItemMenu = (props: HTMLProps<HTMLDivElement>): JSX.Element => {
  const { className, ...divProps } = props;

  return <div className={classNames(className, css.container)} {...divProps} />;
};
