import { useCallback, useMemo, useState } from 'react';

import { HOME_PATH, ACCOUNT_PATH } from '@app/config/app-paths.const';
import { LegalTextDialog } from '@app/shared/legal-text-dialog/legal-text-dialog';
import { useDialog } from '@app/shared/dialog/use-dialog';
import { useNavigateWithState } from '@app/router/redirect/use-navigate-with-state';
import { useLoadLegalText } from '@app/feature/legal-text/hook/use-load-legal-text';
import { useUser } from '@app/core/context-providers/user-context/use-user';
import { configurationService } from '@app/core/configuration/configuration.service';

import { LegalTextView } from './legal-text.view';
import { LegalText } from '../../core/model/legal-text';

export const LegalTextController = (): JSX.Element => {
  const version = useMemo(() => configurationService.getVersion(), []);
  const navigate = useNavigateWithState();
  const { user } = useUser();

  const [selectedLegalText, setSelectedLegalText] = useState<LegalText>();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const handleSelectLegalText = useCallback(
    (legalText: LegalText) => {
      setSelectedLegalText(legalText);
      openDialog();
    },
    [openDialog]
  );

  const handleBack = useCallback(() => {
    navigate(user ? ACCOUNT_PATH : HOME_PATH);
  }, [navigate, user]);

  const { loading, requiredLegalText } = useLoadLegalText({
    onError: handleBack,
  });

  return (
    <>
      <LegalTextView
        version={version}
        pseudo={user?.pseudo}
        pending={loading}
        legalTextList={requiredLegalText}
        onSelect={handleSelectLegalText}
        onBack={handleBack}
      />
      <LegalTextDialog
        open={isDialogOpen}
        onClose={closeDialog}
        legalText={selectedLegalText}
      />
    </>
  );
};
