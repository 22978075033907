import { useTimeout } from '@app/core/helper/use-timeout';
import { useMemo, useState } from 'react';

interface RemainingLockTimeHookReturn {
  remainingLockTimeInMin: number;
  setRemainingLockTimeInSec: (value: number) => void;
}

export function useRemainingLockTime(): RemainingLockTimeHookReturn {
  const [remainingLockTimeInSec, setRemainingLockTimeInSec] = useState(0);
  const remainingLockTimeInMin = useMemo(
    () => Math.ceil(remainingLockTimeInSec / 60),
    [remainingLockTimeInSec]
  );

  useTimeout(() => setRemainingLockTimeInSec(0), remainingLockTimeInSec * 1000);

  return {
    remainingLockTimeInMin,
    setRemainingLockTimeInSec,
  };
}
