import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SECURITY_PATH } from '@app/config/app-paths.const';
import { LOCALE_LSK } from '@app/config/localstorage-keys.const';
import { showErrorMessage } from '@app/core/error/show-error-message';
import {
  getFromLocalStorage,
  removeInLocalStorage,
  setInLocalStorage,
} from '@app/core/storage/local-storage';
import { Logger } from '@app/core/logger/logger';
import { ErrorCodeEnum } from '@app/config/error-config';

import {
  AvailableLanguageEnum,
  ManageLanguageView,
} from './manage-language.view';

const CHANGE_LANGUAGE_TOAST_ID = 'change-language';

export const ManageLanguageController = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const currentLanguage = i18n.language as AvailableLanguageEnum;
  const lskLocale = getFromLocalStorage(LOCALE_LSK);
  const [selectedLocale, setSelectedLocale] = useState<AvailableLanguageEnum>(
    lskLocale ? currentLanguage : AvailableLanguageEnum.BROWSER
  );

  const [pending, setPending] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSelectedLocale(event.target.value as AvailableLanguageEnum);

  const handleBack = useCallback(() => {
    navigate(SECURITY_PATH);
  }, [navigate]);

  const handleSubmit = async () => {
    setPending(true);

    if (selectedLocale === AvailableLanguageEnum.BROWSER) {
      removeInLocalStorage(LOCALE_LSK);
    } else {
      setInLocalStorage(LOCALE_LSK, selectedLocale);
    }

    try {
      await i18n.changeLanguage();
      toast.success(t('manage-language.success'), {
        toastId: CHANGE_LANGUAGE_TOAST_ID,
      });
    } catch (error) {
      Logger.error(error);
      showErrorMessage('switch-language', ErrorCodeEnum.SWITCH_LANGUAGE);
    }

    setPending(false);
  };

  return (
    <ManageLanguageView
      pending={pending}
      locale={selectedLocale}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onBack={handleBack}
    />
  );
};
