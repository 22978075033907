import { useNavigate } from 'react-router-dom';

import {
  CHARGE_SUMMARY_PATH,
  DASHBOARD_PATH,
} from '@app/config/app-paths.const';
import { useCharge } from '@app/core/context-providers/charge-context/use-charge';
import { useOrder } from '@app/core/context-providers/order-context/use-order';
import { ChargeOverview } from '@app/feature/charge-overview/charge-overview.view';
import { ChargeOverviewError } from '@app/feature/charge-overview/charge-overview-error.view';

export const ChargeOverviewController = (): JSX.Element => {
  const {
    duration,
    totalKwh,
    totalTTC,
    isError: isChargeError,
    refresh: refreshCharge,
  } = useCharge();
  const { refresh: refreshOrder } = useOrder();
  const navigate = useNavigate();

  const handleGoDashboard = () => {
    refreshCharge();
    refreshOrder();
    navigate(DASHBOARD_PATH);
  };

  const handleGoToChargeSummary = () => {
    refreshCharge();
    refreshOrder();
    navigate(CHARGE_SUMMARY_PATH);
  };

  return isChargeError ? (
    <ChargeOverviewError onGoToDashboard={handleGoDashboard} />
  ) : (
    <ChargeOverview
      duration={duration}
      energyDelivered={totalKwh}
      estimatedPrice={totalTTC}
      onGoToDashboard={handleGoDashboard}
      onGoToChargeSummary={handleGoToChargeSummary}
    />
  );
};
