import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Card } from '@app/shared/card/card';
import { Divider } from '@app/shared/divider/divider';
import { H3 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { CostModel } from '@app/core/model/cost.model';

import css from './invoice-details.module.scss';

interface InvoiceDetailsProps {
  cost: CostModel;
  energyDelivered?: number;
}

export function InvoiceDetails(props: InvoiceDetailsProps): JSX.Element {
  const { cost, energyDelivered } = props;
  const { t } = useTranslation();

  const { totalHT = 0, totalTTC = 0, totalVAT = 0, vatRate } = cost;

  return (
    <div className={css.container}>
      <H3>{t('shared.invoice.details.title')}</H3>

      <Card background="paper" className={css.card}>
        {energyDelivered && (
          <div className={css.item}>
            <Text fontSize="large">
              {t('shared.invoice.details.energy-delivered.label')}
            </Text>
            <Text fontSize="large" bold>
              {t('shared.invoice.details.energy-delivered.value', {
                energyDelivered,
              })}
            </Text>
          </div>
        )}

        {totalHT > 0 && totalVAT > 0 && (
          <>
            <Divider />

            <div className={css.itemContainer}>
              <div className={css.item}>
                <Text fontSize="large">
                  {t('shared.invoice.details.total-ht.label')}
                </Text>
                <Text fontSize="large" bold>
                  {t('shared.invoice.details.total-ht.value', {
                    totalHT,
                  })}
                </Text>
              </div>

              <div className={css.item}>
                <Text fontSize="large">
                  {t('shared.invoice.details.total-vat.label', {
                    vatRate,
                  })}
                </Text>
                <Text fontSize="large" bold>
                  {t('shared.invoice.details.total-vat.value', {
                    totalVAT,
                  })}
                </Text>
              </div>
            </div>
          </>
        )}

        <Divider />

        {totalTTC > 0 ? (
          <div className={classNames(css.item, css.total)}>
            <Text fontSize="large">
              {t('shared.invoice.details.total-ttc.label')}
            </Text>
            <Text className={css.totalValue} bold>
              {t('shared.invoice.details.total-ttc.value', {
                totalTTC,
              })}
            </Text>
          </div>
        ) : (
          <div className={classNames(css.item)}>
            <Text>{t('shared.invoice.details.total-ttc.unavailable')}</Text>
          </div>
        )}
      </Card>
    </div>
  );
}
