import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TranslationPaths } from '@app/core/helper/use-typed-translation';
import { BackLink } from '@app/shared/back-link/back-link';
import { Button } from '@app/shared/button/button';
import { EmailInput } from '@app/shared/text-input/email-input';
import { H1 } from '@app/shared/typography/heading/heading';
import { Text } from '@app/shared/typography/text/text';
import { DrawerLayout } from '@app/shared/layout/drawer-layout/drawer-layout';

import { StartEmailOtpFormValidationRules } from '../email-otp-form-validation-rules.const';
import { EmailOtpProcessStartRequestModel } from '../../../core/model/email-otp-process.model';

import { buildStartEmailOtpTranslations } from './build-start-email-otp-translation';
import css from './start-email-otp-process.module.scss';

const DEFAULT_TRANSLATION_KEY: TranslationPaths = 'email-otp';

interface StartEmailOtpProcessViewProps {
  remainingLockTimeInMin?: number;
  className?: string;
  children?: JSX.Element;
  emailAddress?: string;
  autoFocus?: boolean;
  pending: boolean;
  isReadonly: boolean;
  translationKey?: TranslationPaths;
  onSubmit: (payload: EmailOtpProcessStartRequestModel) => Promise<void>;
  onBack: () => void;
}

export const StartEmailOtpProcessView = ({
  className,
  remainingLockTimeInMin = 0,
  emailAddress = '',
  autoFocus = false,
  pending = false,
  translationKey,
  onSubmit,
  onBack,
}: StartEmailOtpProcessViewProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
  } = useForm<EmailOtpProcessStartRequestModel>({
    defaultValues: { emailAddress },
    mode: 'onChange',
  });

  const translations = useMemo(
    () =>
      buildStartEmailOtpTranslations(translationKey ?? DEFAULT_TRANSLATION_KEY),
    [translationKey]
  );

  const defaultTranslations = useMemo(
    () => buildStartEmailOtpTranslations(DEFAULT_TRANSLATION_KEY),
    []
  );

  const handleStartEmailOtpProcessSubmit = (
    data: EmailOtpProcessStartRequestModel
  ) => {
    onSubmit({
      emailAddress: data.emailAddress.trim().toLowerCase(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit(handleStartEmailOtpProcessSubmit)}
      className={classNames(css.formContainer, className)}>
      <DrawerLayout>
        <BackLink onClick={onBack} />

        <DrawerLayout.Header>
          <H1>{t([translations.title, defaultTranslations.title])}</H1>
          <Text>{t([translations.caption, defaultTranslations.caption])}</Text>
        </DrawerLayout.Header>

        <DrawerLayout.Body>
          <EmailInput
            autoFocus={autoFocus}
            {...register(
              'emailAddress',
              StartEmailOtpFormValidationRules.emailAddress
            )}
            label={t([translations.inputLabel, defaultTranslations.inputLabel])}
            isTouched={touchedFields?.emailAddress}
            errorMessage={
              errors?.emailAddress?.type === 'pattern'
                ? t('shared.errors.email.pattern')
                : errors?.emailAddress?.type === 'required'
                ? t('shared.errors.email.required')
                : ''
            }
          />
        </DrawerLayout.Body>

        <DrawerLayout.Footer>
          <Button
            type="submit"
            visuallyDisabled={!isValid || remainingLockTimeInMin > 0}
            label={t(
              [translations.submitButton, defaultTranslations.submitButton],
              { count: remainingLockTimeInMin }
            )}
            loading={pending}
          />
        </DrawerLayout.Footer>
      </DrawerLayout>
    </form>
  );
};
