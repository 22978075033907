import { useTranslation } from 'react-i18next';

import { useAuthentication } from '@app/core/context-providers/authentication-context/use-authentication';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { H1 } from '@app/shared/typography/heading/heading';

import { CameraError } from './component/qr-code-scanner/camera-error';
import { QrCodeScanner } from './component/qr-code-scanner/qr-code-scanner';
import { ListChargePoint } from './component/list-charge-point/list-charge-point';

interface ScanViewProps {
  isCameraError: boolean;
  onCameraError: () => void;
  onScan: (text: string) => void;
  onBack?: () => void;
}

export const ScanView = (props: ScanViewProps): JSX.Element => {
  const { t } = useTranslation();

  const { isCameraError, onCameraError, onScan, onBack = () => {} } = props;

  const { isAuthenticated } = useAuthentication();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />

      <PageLayout.Header>
        <H1>{t('scan.title')}</H1>
      </PageLayout.Header>

      <PageLayout.Body>
        {isCameraError ? (
          <CameraError />
        ) : (
          <QrCodeScanner onScan={onScan} onCameraError={onCameraError} />
        )}

        <ListChargePoint />
      </PageLayout.Body>
      {isAuthenticated && <PageLayout.AppbarFooter />}
    </PageLayout>
  );
};
