import { useTranslation } from 'react-i18next';

import { InvoiceHeader } from '@app/shared/invoice/invoice-header/invoice-header';
import { InvoiceDetails } from '@app/shared/invoice/invoice-details/invoice-details';
import { InvoiceFooter } from '@app/shared/invoice/invoice-footer/invoice.footer';
import { PageLayout } from '@app/shared/layout/page-layout/page-layout';
import { BackLink } from '@app/shared/back-link/back-link';
import { Spinner } from '@app/shared/spinner/spinner';
import { Link } from '@app/shared/typography/link/link';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { InvoiceTariff } from '@app/shared/invoice/invoice-tariff/invoice-tariff';

import css from './invoice-summary.module.scss';

interface InvoiceSummaryViewProps {
  isEmailAddressVerified?: boolean;
  autoSendInvoiceByMail?: boolean;
  invoice?: InvoiceModel;
  onBack: () => void;
}

export const InvoiceSummaryView = (
  props: InvoiceSummaryViewProps
): JSX.Element => {
  const {
    isEmailAddressVerified = false,
    autoSendInvoiceByMail = false,
    onBack,
    invoice,
  } = props;
  const { t } = useTranslation();

  return (
    <PageLayout>
      <BackLink onClick={onBack} />

      <PageLayout.Body>
        {invoice ? (
          <>
            <InvoiceHeader
              pseudo={invoice.customer.pseudo}
              totalCost={invoice.cost.totalTTC}
              startedAt={invoice.startedAt}
              chargePointName={invoice.chargePointName}
              duration={invoice.duration}
              proforma={invoice.proforma}
            />
            <InvoiceDetails
              cost={invoice.cost}
              energyDelivered={invoice.energyDelivered}
            />
            <InvoiceTariff orderId={invoice.orderId} {...invoice.pricing} />

            <InvoiceFooter
              proforma={invoice.proforma}
              invoiceId={invoice.invoiceId}
              createdAt={invoice.createdAt}
              autoSendInvoiceByMail={autoSendInvoiceByMail}
              isEmailAddressVerified={isEmailAddressVerified}
              pseudo={invoice.customer.pseudo}
            />

            <Link className={css.backToInvoice} onClick={onBack}>
              {t('invoicing.back-to-list-invoice')}
            </Link>
          </>
        ) : (
          <Spinner translationKey="shared.spinner.invoice" />
        )}
      </PageLayout.Body>

      <PageLayout.AppbarFooter />
    </PageLayout>
  );
};
