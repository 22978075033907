import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { showErrorMessage } from '@app/core/error/show-error-message';
import { INVOICE_DASHBOARD_PATH } from '@app/config/app-paths.const';
import { ApiError } from '@app/core/error/api-error';
import { catchApiError } from '@app/core/error/catch-api-error';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { InvoiceRouterStateModel } from '@app/core/model/invoice-router-state.model';
import { invoicingService } from '@app/core/service/invoicing.service';
import { WithUserProps } from '@app/router/guard/with-user.guard';

import { InvoiceSummaryView } from './invoice-summary.view';

export const InvoiceSummaryController = (props: WithUserProps): JSX.Element => {
  const { invoiceId } = useParams();
  const { user } = props;

  const { state: routerState } = useLocation() as {
    state: InvoiceRouterStateModel;
  };

  const [invoice, setInvoice] = useState<InvoiceModel>();

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(INVOICE_DASHBOARD_PATH);
  }, [navigate]);

  useEffect(() => {
    if (!invoiceId) {
      return handleBack();
    }

    if (routerState?.invoice) {
      setInvoice(routerState.invoice);
      return () => {};
    }

    const [getInvoicePromise, abortInvoice] =
      invoicingService.getInvoiceById(invoiceId);

    getInvoicePromise.then(setInvoice).catch((error) => {
      catchApiError(error, (apiError: ApiError) => {
        showErrorMessage('get-invoice', apiError.code);
        handleBack();
      });
    });

    return () => {
      abortInvoice.abort();
    };
  }, [invoiceId, routerState?.invoice, handleBack]);

  return (
    <InvoiceSummaryView
      invoice={invoice}
      onBack={handleBack}
      isEmailAddressVerified={user.isEmailAddressVerified}
      autoSendInvoiceByMail={user?.preferences?.autoSendInvoiceByMail}
    />
  );
};
