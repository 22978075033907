import classNames from 'classnames';
import css from './step.module.scss';

interface StepProps {
  active: boolean;
}

export const Step = ({ active }: StepProps): JSX.Element => (
  <div className={classNames(css.step, active && css.active)} />
);
