import { useTranslation } from 'react-i18next';

import { Card } from '@app/shared/card/card';
import { InvoiceModel } from '@app/core/model/invoice.model';
import { H3 } from '@app/shared/typography/heading/heading';
import { TariffModel } from '@app/core/model/tariff.model';
import {
  isTariffElementComplex,
  isTariffEmpty,
} from '@app/core/helper/ocpi-utils';
import { TariffList } from '@app/feature/tariff/component/tariff-list/tariff-list';
import { Logger } from '@app/core/logger/logger';

import css from './invoice-tariff.module.scss';
import { InvoicePricing } from './invoice-pricing';

type Props = InvoiceModel['pricing'] & {
  orderId: string;
};

export function InvoiceTariff(props: Props): JSX.Element | null {
  const { tariff: tariffModel, orderId, ...simplePricing } = props;

  const { t } = useTranslation();

  const tariff: TariffModel = tariffModel ?? {
    // backwards compatibility with pricing
    elements: [{ priceComponents: simplePricing }],
  };
  const isTariffComplex = isTariffElementComplex(tariff.elements);

  if (isTariffEmpty(tariff)) {
    Logger.warn(`[invoice-tariff]: Tariff is empty for orderId: ${orderId}`);
    return null;
  }

  return (
    <div className={css.container}>
      <H3>{t('shared.invoice.tariff.title')}</H3>

      <Card background="paper" className={css.card}>
        {isTariffComplex ? (
          <TariffList tariff={tariff} />
        ) : (
          <InvoicePricing {...simplePricing} />
        )}
      </Card>
    </div>
  );
}
